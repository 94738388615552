import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { toast } from "react-toastify";
import { FaCheck, FaTimes } from 'react-icons/fa';
import ChildModal from "./childModal";
import rdvService from "services/rdvService";
import Utils from "services/Utils";
import GenericAlertDialog from "./GenericAlertDialog";
import "react-datepicker/dist/react-datepicker.css";

const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  fontWeight: "500",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validateEmail = (email) => emailRegex.test(email);

const ChildSection = ({ data, accessToken, refresh, t }) => {
  const {
    isOpen: isChildrenModalOpen,
    onOpen: onChildrenModalOpen,
    onClose: onChildrenModalClose,
  } = useDisclosure();

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const initialChildrenRef = useRef();
  const finalChildrenRef = useRef();

  const [childrenForm, setChildrenForm] = useState({
    name: "",
    date_of_birth: "",
    email: "",
    gender: "",
    adopted: "No",
  });
  const [addingChildren, setAddingChildren] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentEditableChild, setCurrentEditableChild] = useState(null);

  const [alertTitle, setAlertTitle] = useState("");
  const [alertBody, setAlertBody] = useState("");
  const [alertConfirmCallback, setAlertConfirmCallback] = useState(() => () => {});

  const handleAddChild = useCallback(async (childrenForm) => {
    try {
      await rdvService.addChild({
        pk: data.id,
        child: childrenForm,
        accessToken: accessToken,
      });
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("messages.childAdded")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
      refresh();
    } catch (error) {
      console.error("Failed to add child:", error);
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.failedToAddChild")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  }, [data?.id, accessToken, refresh, t]);

  const handleEditChild = useCallback(async (childrenForm) => {
    try {
      await rdvService.updateChildInfo({
        rdvId: data.id,
        childId: currentEditableChild.id,
        obj: childrenForm,
        accessToken,
      });
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("messages.changesSaved")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
      refresh();
    } catch (error) {
      console.error("Failed to update child:", error);
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.failedToUpdateChild")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  }, [data?.id, currentEditableChild, accessToken, refresh, t]);

  const validateChildForm = (form) => {
    const newErrors = {};
    if (!form.name.trim()) newErrors.name = t('errors.nameRequired');
    if (!form.date_of_birth.trim()) newErrors.date_of_birth = t('errors.dateOfBirthRequired');
    if (!form.gender.trim()) newErrors.gender = t('errors.genderRequired');
    if (form.email.trim() && !validateEmail(form.email)) newErrors.email = t('errors.invalidEmailFormat');
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRemoveChild = useCallback((child) => {
    setAlertTitle(t('messages.removeChildConfirmation', { name: child.name }));
    setAlertBody(t('messages.removeChildWarning', { name: child.name }));
    setAlertConfirmCallback(() => async () => {
      const childrenIds = data.children.map((child) => child.id);
      const updatedChildren = childrenIds.filter((id) => id !== child.id);

      try {
        await rdvService.update({
          pk: data.id,
          accessToken: accessToken,
          obj: {
            children: updatedChildren,
          },
        });

        toast.success(
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <FaCheck style={{ marginRight: '8px' }} />
            {t('messages.changesSaved')}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "#FF6666",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            icon: false
          }
        );
        refresh();
      } catch (error) {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <FaTimes style={{ marginRight: '8px', color: 'white' }} />
            {t('messages.failedToRemoveChild')}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "red",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            progressBar: false,
            icon: false
          }
        );
        console.error("Failed to remove child:", error);
      } finally {
      }
      onAlertClose();
    });
    onAlertOpen();
  }, [data?.id, accessToken, refresh, t, setAlertTitle, setAlertBody, setAlertConfirmCallback, onAlertClose, onAlertOpen]);

  const openModal = useCallback((child = null) => {
    setCurrentEditableChild(child);
    setChildrenForm({
      name: child?.name ?? "",
      date_of_birth: child?.date_of_birth ?? "",
      email: child?.email ?? "",
      gender: child?.gender ?? "",
      adopted: child?.adopted ? "Yes" : "No",
    });
    onChildrenModalOpen();
  }, [onChildrenModalOpen]);

  if (!data) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" m={3}>
      <Flex justify="space-between" p={4} borderBottomWidth="1px">
        <Text fontSize="2xl" fontWeight="semibold">
          {t("admin__appointments_table__children")}
        </Text>
        <Button
          fontSize="16px"
          fontWeight="500"
          marginLeft={3}
          marginY="auto"
          color="#FF6666"
          bg="none"
          onClick={() => openModal()}
          isDisabled={data.is_closed}
        >
          {t("admin__appointments_table__add_child")}
        </Button>
      </Flex>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th sx={tableHeaderStyles}>{t("admin__appointments_table__name")}</Th>
              <Th sx={tableHeaderStyles}>{t("admin__appointments_table__dob")}</Th>
              <Th sx={tableHeaderStyles}>{t("admin__appointments_table__gender")}</Th>
              <Th sx={tableHeaderStyles}>{t("admin__appointments_table__adopted")}</Th>
              <Th sx={tableHeaderStyles}>{t("admin__appointments_table__email")}</Th>
              <Th sx={tableHeaderStyles}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.children.length === 0 ? (
              <Tr>
                <Td colSpan={6} textAlign="center">
                  <Text fontSize="18px" color="gray.500">
                    {t("no_child_added")}
                  </Text>
                </Td>
              </Tr>
            ) : (
              data.children.map((child) => (
                <Tr key={child.id}>
                  <Td sx={tableContentStyles}>{child.name ?? "N/A"}</Td>
                  <Td sx={tableContentStyles}>{child.date_of_birth ? Utils.intoDDMMYYYY(child.date_of_birth).replaceAll('/', '-') : "N/A"}</Td>
                  <Td sx={tableContentStyles}>{child.gender ?? "N/A"}</Td>
                  <Td sx={tableContentStyles}>{child.adopted ? "Yes" : "No"}</Td>
                  <Td sx={tableContentStyles}>{child.email ?? "N/A"}</Td>
                  <Td>
                    <IconButton
                      aria-label="Edit child"
                      icon={<EditIcon />}
                      size="sm"
                      mr={2}
                      onClick={() => openModal(child)}
                    />
                    <IconButton
                      aria-label="Remove child"
                      icon={<DeleteIcon />}
                      size="sm"
                      colorScheme="red"
                      onClick={() => handleRemoveChild(child)}
                    />
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <ChildModal
        isOpen={isChildrenModalOpen}
        onClose={onChildrenModalClose}
        initialRef={initialChildrenRef}
        finalRef={finalChildrenRef}
        addChild={handleAddChild}
        editChild={handleEditChild}
        child={currentEditableChild}
        childrenForm={childrenForm}
        setChildrenForm={setChildrenForm}
        addingChildren={addingChildren}
        validateChildForm={validateChildForm}
        errors={errors}
      />
      <GenericAlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        title={alertTitle}
        body={alertBody}
        confirm={alertConfirmCallback}
      />
    </Box>
  );
};

export default ChildSection;
