import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const CancelReasonModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleConfirm = () => {
    setIsSubmitted(true);
    if (reason.trim()) {
      onConfirm(reason);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("cancel_reason")}</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={isSubmitted && !reason.trim()}>
            <FormLabel display="flex">
              {t("provide_cancel_reason")}
              <Text ml={1} color="red">*</Text>
            </FormLabel>
            <Textarea
              value={reason}
              onChange={(e) => setReason(e.target.value.trimStart())} // Trim leading spaces as user types
            />
            <FormErrorMessage>{t("reason_cancel_required")}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleConfirm}>
            {t("Confirm")}
          </Button>
          <Button onClick={onClose}>{t("close")}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelReasonModal;
