import React, { useState } from "react";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    List,
    ListIcon,
    ListItem,
    useColorModeValue,
} from "@chakra-ui/react";
import authService from "services/authService"; // Adjust the import path as needed
import { useHistory } from "react-router-dom";
import AuthLayout from "layouts/auth";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "components/menu/LanguageSelector";
import { MdCheckCircle } from "react-icons/md";

function ResetPassword() {
    const { t } = useTranslation();
    const [username, setUsername] = useState("");
    const [emailValid, setEmailValid] = useState(""); // Updated field
    const [message, setMessage] = useState();
    const [step, setStep] = useState(1); // 1 for email input, 2 for password reset
    const [error, setError] = useState("");
    const [isEmailSend, setIsEmailSend] = useState(false);
    const [isEmailSendAgain, setIsEmailSendAgain] = useState(false);
    const history = useHistory();

    const handleEmailChange = (e) => setUsername(e.target.value);

    // const validateEmail = (email) => {
    //     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     return re.test(String(email).toLowerCase());
    // };

    const handlePasswordResetRequest = async () => {
        setIsEmailSend(true);
        // if (!validateEmail(email)) {
        //     setEmailValid(`${t("email_valid_error")}`);
        //     setIsEmailSend(false);
        //     return;
        // }
        try {
            await authService.passwordReset(username);
            setEmailValid("");
            setError(""); // Clear any existing errors
            setTimeout(() => {
                setIsEmailSend(false);
                setStep(2); // Move to the next step for password reset confirmation
            }, 300);
        } catch (error) {
            setEmailValid("");
            setError(`${t("email_fail_send")}`);
            setIsEmailSend(false);
        }
    };

    const handlePasswordResetRequestAgain = async () => {
        setIsEmailSendAgain(true)
        handlePasswordResetRequest();

        setTimeout(() => {
            setIsEmailSendAgain(false)
        }, 3000);
    }

    const textColor = useColorModeValue("navy.700", "white");

    return (
        <AuthLayout>
            <DefaultAuth illustrationBackground={illustration} image={illustration}>
                <Flex
                    w="100%"
                    alignItems="start"
                    justifyContent="center"
                    mb={{ base: "30px", md: "60px" }}
                    px={{ base: "25px", md: "0px" }}
                    mt={{ base: "15px", md: "10vh" }}
                    flexDirection="column"
                >
                    <Flex
                        alignItems="start"
                        justifyContent="start"
                        mb={{ base: "28px", md: "60px" }}
                        px={{ base: "20px", md: "0px" }}
                        mt={{ base: "30px", md: "14vh" }}
                    // flexDirection="column"
                    >
                        <LanguageSelector />
                    </Flex>
                    <Box me="auto">
                        <Heading
                            color={"#252525"}
                            fontSize="48px"
                            mb="8px"
                            fontWeight={"500"}
                        >
                            {t("reset_password")}
                        </Heading>
                        <Text
                            mb="24px"
                            ms="4px"
                            color="#565656"
                            fontWeight="400"
                            fontSize="26px"
                        >
                            {t("auth__signin__description")}
                        </Text>
                        <List spacing={1} mb="30px">
                            <ListItem>
                                <Text
                                    ms="4px"
                                    fontSize="16px"
                                    fontWeight="300"
                                    color={textColor}
                                    mb="8px">
                                    <ListIcon as={MdCheckCircle} color='#FF6666' />

                                    {t("reset_password_note_one")}
                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text
                                    ms="4px"
                                    fontSize="16px"
                                    fontWeight="300"
                                    color={textColor}
                                    mb="8px">
                                    <ListIcon as={MdCheckCircle} color='#FF6666' />
                                    {t("reset_password_note_two")}

                                </Text>
                            </ListItem>
                            <ListItem>
                                <Text
                                    ms="4px"
                                    fontSize="16px"
                                    fontWeight="300"
                                    color={textColor}
                                    mb="8px">
                                    <ListIcon as={MdCheckCircle} color='#FF6666' />
                                    {t("reset_password_note_three")}

                                </Text>
                            </ListItem>
                        </List>
                    </Box>
                    <Flex
                        zIndex="2"
                        direction="column"
                        w={{ base: "100%", md: "420px" }}
                        maxW="100%"
                        background="transparent"
                        borderRadius="15px"
                        mx={{ base: "auto", lg: "unset" }}
                        me="auto"
                        mb={{ base: "20px", md: "auto" }}
                    >
                        {step === 1 && (
                            <FormControl>
                                <FormLabel
                                    display="flex"
                                    ms="4px"
                                    fontSize="16px"
                                    fontWeight="300"
                                    color={textColor}
                                    mb="8px"
                                >
                                    {t("common__username")}
                                    <Text color={"#EE0000"} ml={"1px"}>
                                        *
                                    </Text>
                                </FormLabel>
                                <Input
                                    isRequired={true}
                                    variant="auth"
                                    fontSize="sm"
                                    ms={{ base: "0px", md: "0px" }}
                                    type="text"
                                    value={username}
                                    placeholder={t("common__username")}
                                    mb="24px"
                                    fontWeight="500"
                                    size="lg"
                                    onChange={handleEmailChange}
                                    borderRadius="4px"
                                    bg={'gray.200'}
                                    isDisabled={step === 2}
                                />
                                {emailValid && <Text color="red.500" mb="24px">{emailValid}</Text>}
                                <Button
                                    color={"white"}
                                    fontSize="20px"
                                    variant="brand"
                                    fontWeight="500"
                                    w="100%"
                                    h="50"
                                    py={"7"}
                                    mb="24px"
                                    onClick={handlePasswordResetRequest}
                                    bgColor={"#FF6666"}
                                    borderRadius={0}
                                    _hover={{ bg: "#FF8080" }}
                                    isDisabled={!username}
                                    isLoading={isEmailSend}
                                >
                                    {t("send_reset_link")}
                                </Button>
                                {error && <Text color="red.500" mb="24px">{error}</Text>}
                                <Button
                                    variant="link"
                                    color="#FF6666"
                                    fontSize="14px"
                                    onClick={() => history.push("/sign-in")}
                                >
                                    {t("back_to_sign_in")}
                                </Button>
                            </FormControl>
                        )}
                        {step === 2 && (
                            <Flex
                                // zIndex="2"
                                direction="column"
                                w={{ base: "100%", md: "420px" }}
                                maxW="100%"
                                background="transparent"
                                borderRadius="15px"
                                mx={{ base: "auto", lg: "unset" }}
                                me="auto"
                                mb={{ base: "20px", md: "auto" }}
                            >
                                <Text
                                    ms="4px"
                                    fontSize="18px"
                                    fontWeight="300"
                                    color={textColor}
                                    mb="8px">
                                    {t("password_reset_link_sent")}
                                </Text>
                                <FormControl>
                                    <Button
                                        color={"white"}
                                        fontSize="18px"
                                        variant="brand"
                                        fontWeight="500"
                                        w="100%"
                                        h="50"
                                        py={"7"}
                                        mb="24px"
                                        mt="10px"
                                        onClick={handlePasswordResetRequestAgain}
                                        bgColor={"#FF6666"}
                                        borderRadius={0}
                                        _hover={{ bg: "#FF8080" }}
                                        isDisabled={isEmailSendAgain}
                                        isLoading={isEmailSendAgain}
                                    >
                                        {t("resend_link")}
                                    </Button>
                                    <Button
                                        variant="link"
                                        color="#FF6666"
                                        fontSize="14px"
                                        onClick={() => history.push("/sign-in")}
                                    >
                                        {t("back_to_sign_in")}
                                    </Button>
                                </FormControl>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </DefaultAuth>
        </AuthLayout>
    );
}

export default ResetPassword;