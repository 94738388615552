import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Flex, Spinner, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from '@chakra-ui/react';
import BrandHeader from "components/menu/BrandHeader";
import collaboratorService from "services/collaboratorService";
import { setSelectedMediator, clearMediator } from "../../../actions/mediatorActions";
import { ToastContainer, toast } from "react-toastify";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import NewProfile from "./profile/NewProfile";
import EditProfile from "./profile/EditProfile";
import Availability from "./profile/Availability";
import AvailabilityTest from "./profile/AvailabilityTest";
import Utils from "services/Utils";

const MediatorSettingsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const mediator = useSelector((state) => state.mediator.selectedMediator);
  const [isNew, setIsNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const timeZoneInfo = Utils.getLocalTimeZoneInfo(); // Call the function once

  const GetTimeZone = `${timeZoneInfo.timeZone} - ${timeZoneInfo.abbreviation}`; // Create the string


  useEffect(() => {
    if (id) {
      setIsNew(false);
      const savedMediator = localStorage.getItem('selectedServiceProvider');
      if (savedMediator) {
        console.log('found a saved med');
        dispatch(setSelectedMediator(JSON.parse(savedMediator)));
        setLoading(false);
      } else if (id) {
        console.log('no saved med but thre is id');
        collaboratorService
          .getMediatorById(id, accessToken)
          .then((med) => {
            dispatch(setSelectedMediator(med.data));
            localStorage.setItem('selectedServiceProvider', JSON.stringify(med.data));
            setLoading(false);
          })
          .catch(() => {
            console.log('this med does not exsist');
            setLoading(false);
            history.push(`/admin/service-providers/`);
          });
      } else {
        // console.log('no saved med but thre and no id');
        history.push(`/admin/service-providers/`);
      }
    } else {
      setIsNew(true);
      dispatch(clearMediator());
    }
  }, [id, dispatch]);





  const handleSubmit = (formData) => {
    setLoading(true);

    if (isNew) {
      console.log("new", isNew, formData)
      // collaboratorService
      //   .createMediator(formData, accessToken)
      //   .then((med) => {
      //     console.log("create med response", med)
      //     // dispatch(setSelectedMediator(med));
      //     // setTimeout(() => {
      //     //   history.push(`/admin/service-provider/${med.id}`);
      //     //   setLoading(false);
      //     // }, 2000);
      //   })
      //   .catch((error) => {
      //     console.log("***********************************************************************************************")
      //     console.log("createMediator error", error.response?.data?.email)
      //     console.log("***********************************************************************************************")
      //     showToastError();
      //     // setLoading(false); // Ensure loading is turned off after an error
      //   });
      setLoading(false);
    } else {
      // collaboratorService
      //   .updateMediator(id, formData, accessToken)
      //   .then((med) => {
      //     dispatch(setSelectedMediator(med));
      //     setTimeout(() => {
      //       history.push(`/admin/service-provider/${id}`);
      //       setLoading(false);
      //     }, 2000);
      //   })
      //   .catch(() => {
      //     showToastError();
      //   });
      setLoading(false);
    }
  };


  const showToastError = () => {
    toast.error(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FaTimes style={{ marginRight: '8px', color: 'white' }} />
        {t("mediationTab.mediator_add_failed")}
      </div>,
      {
        position: "bottom-center",
        style: {
          backgroundColor: "red",
          color: "white",
          width: 'auto',
          minWidth: '400px'
        },
        progressBar: false,
        icon: false
      }
    );
  };

  return (
    <div>
      <BrandHeader />

      {loading && (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" color="#FF6666" />
        </Flex>
      )}

      {!loading && (
        isNew ? (
          <NewProfile onSubmit={handleSubmit} />
        ) : (
          <Tabs isLazy>
            <TabList position='relative'>
              <Tab
                _selected={{
                  color: '#E64E64', // Text color for selected tab
                  borderBottom: '2px solid #E64E64', // Bottom border color for selected tab
                }}
                _focus={{ boxShadow: 'none' }}
              >
                {t("mediationTab.mediator_details")}
              </Tab>
              <Tab
                _selected={{
                  color: '#E64E64', // Text color for selected tab
                  borderBottom: '2px solid #E64E64', // Bottom border color for selected tab
                }}
                _focus={{ boxShadow: 'none' }}
              >
                {t("mediationTab.mediator_availability")}
              </Tab>
              <Text
                position='absolute'
                right='1rem'
                top='0.5rem'
                color='gray.500'
              > {GetTimeZone}</Text>
            </TabList>

            <TabPanels>
              <TabPanel>
                <EditProfile mediator={mediator} onSubmit={handleSubmit} />
              </TabPanel>
              <TabPanel>
                <AvailabilityTest mediator={mediator} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default MediatorSettingsPage;
