import axiosInstance from '../axiosConfig';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary`;

const calendarService = {

    freeTimeSlots: async (locationId, servieProviderId, serviceId, limit) => {
      // console.log(`form claender service ${locationId}`)
        try {
          const response = await axiosInstance.get(`${API_URL}/availability-seed/`, {
            params:{limit: limit,  location: locationId, type: serviceId, service_provider: servieProviderId}
          });
          return {
            data: response.data,
          };
        } catch (error) {
          throw new Error("Calendar failed. Please check your credentials.");
        }
    },

    fetchSessions: async (filters) => {
      try {
          // Make an API request with the provided filters as query parameters
          const response = await axiosInstance.get(`${API_URL}/session/`, {
              params: {
                  ...filters, // Spread the filters object to pass all query parameters
              },
          });
          // console.log(response);
          return {
              data: response.data,
          };
      } catch (error) {
          throw new Error("Calendar failed. Please check your credentials.");
      }
  }
  
}

export default calendarService;
