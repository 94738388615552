import React, { useState, useEffect } from 'react';
import {
  Box, SimpleGrid, FormControl, FormLabel, Input, Textarea,
  Button, Flex, Text
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const EditLocationForm = ({ initialData, onSubmit, onClose }) => {
  const { t } = useTranslation();

  // Initialize form data with the initial data provided
  const [formData, setFormData] = useState({
    name: initialData.name || '',
    address: initialData.address || '',
    department: initialData.department || '',
    city: initialData.city || '',
    country: initialData.country || '',
    description: initialData.description || '',
    latitude: initialData.latitude || '',
    longitude: initialData.longitude || ''
  });

  // State to manage validation errors
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    setIsFormValid(Object.keys(validateForm()).length === 0);
  }, [formData]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '', // Clear the error when the user starts typing
    });
  };

  // Handle blur event to validate fields
  const handleBlur = (e) => {
    const { name } = e.target;
    const validationErrors = validateForm();
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validationErrors[name] || '',
    }));
    // console.log('Validation Errors:', validationErrors); // Log errors
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = t("locationTab.name_error"); // Error message for empty name
    }

    if (!formData.address) {
      newErrors.address = t("locationTab.address_error"); // Error message for empty address
    }

    if (!formData.department) {
      newErrors.department = t("locationTab.department_code_error"); // Error message for empty department
    } else if (!/^\d{2}$/.test(formData.department)) {
      newErrors.department = t("locationTab.department_code_format_error"); // Error message for invalid department format
    }

    // Latitude Validation: must be a number between -90 and 90
    if (formData.latitude && (isNaN(formData.latitude) || formData.latitude < -90 || formData.latitude > 90)) {
      newErrors.latitude = t("locationTab.latitude_error");
    }

    // Longitude Validation: must be a number between -180 and 180
    if (formData.longitude && (isNaN(formData.longitude) || formData.longitude < -180 || formData.longitude > 180)) {
      newErrors.longitude = t("locationTab.longitude_error");
    }

    return newErrors;
  };


  // Handle form submission
  const handleSubmit = () => {
    // const validationErrors = validateForm();
    // console.log("validationErrors", validationErrors)
    // if (Object.keys(validationErrors).length === 0) {
    //   onSubmit(formData);
    // } else {
    //   setErrors(validationErrors);
    //   console.log('Submit Errors:', validationErrors); // Log errors
    // }
    // Validate the fields before submission
    const validationErrors = validateForm();

    // Check for empty fields in the specified fields
    const requiredFields = ['name', 'address', 'department', 'latitude', 'longitude'];
    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      // If there are empty fields, update the errors state accordingly
      const newErrors = {};
      emptyFields.forEach((field) => {
        newErrors[field] = t("locationTab.empty_field_error"); // Adjust this key based on your translation setup
      });
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

    } else if (Object.keys(validationErrors).length === 0) {
      // If no empty fields and no validation errors, call the onSubmit function
      onSubmit(formData);
    } else {
      // If there are validation errors, set them to state
      setErrors(validationErrors);
    }
  };

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        {/* Name */}
        <FormControl isRequired isInvalid={errors.name}>
          <FormLabel>{t("locationTab.name")}</FormLabel>
          <Input
            name="name"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("locationTab.name_placeholder")}
          />
          {errors.name && <Text color="red.500">{errors.name}</Text>}
        </FormControl>

        {/* Address */}
        <FormControl isRequired isInvalid={errors.address}>
          <FormLabel>{t("locationTab.address")}</FormLabel>
          <Input
            name="address"
            value={formData.address}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("locationTab.address_placeholder")}
          />
          {errors.address && <Text color="red.500">{errors.address}</Text>}
        </FormControl>

        {/* Department Code */}
        <FormControl isRequired isInvalid={errors.department}>
          <FormLabel>{t("locationTab.department_code")}</FormLabel>
          <Input
            name="department"
            value={formData.department}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("locationTab.department_code_placeholder")}
          />
          {errors.department && <Text color="red.500">{errors.department}</Text>}
        </FormControl>

        {/* Other optional fields */}
        <FormControl>
          <FormLabel>{t("locationTab.city")}</FormLabel>
          <Input
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder={t("locationTab.enter_city")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>{t("locationTab.country")}</FormLabel>
          <Input
            name="country"
            value={formData.country}
            onChange={handleChange}
            placeholder={t("locationTab.enter_country")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>{t("locationTab.description")}</FormLabel>
          <Textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder={t("locationTab.enter_description")}
          />
        </FormControl>
        {/* Latitude */}
        <FormControl isRequired isInvalid={errors.latitude}>
          <FormLabel>{t("locationTab.latitude")}</FormLabel>
          <Input
            name="latitude"
            value={formData.latitude}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("locationTab.enter_latitude")}
          />
          {errors.latitude && <Text color="red.500">{errors.latitude}</Text>}
        </FormControl>
        {/* Longitude */}
        <FormControl isRequired isInvalid={errors.longitude}>
          <FormLabel>{t("locationTab.longitude")}</FormLabel>
          <Input
            name="longitude"
            value={formData.longitude}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("locationTab.enter_longitude")}
          />
          {errors.longitude && <Text color="red.500">{errors.longitude}</Text>}
        </FormControl>
      </SimpleGrid>

      <Flex justifyContent="flex-end" mt={6}>
        <Button
          sx={{
            bg: '#FF6666', // Default background color
            color: 'white', // Text color
            _hover: {
              bg: '#CC5252', // Darker shade for hover state (you can adjust this to your liking)
            },
            _active: {
              bg: '#B43C3C', // Even darker shade for active state
            },
            _disabled: {
              bg: 'gray.300', // Background for disabled state
              color: 'gray.500', // Text color for disabled state
            },
          }}
          onClick={handleSubmit}
          isDisabled={!isFormValid}
        >
          {t("locationTab.edit")}
        </Button>
        <Button variant="outline" ml={3} onClick={onClose}>
          {t("locationTab.cancel")}
        </Button>
      </Flex>
    </Box>
  );
};

export default EditLocationForm;
