import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Checkbox,
  Button,
  Text,
  VStack,
  HStack,
  useOutsideClick
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const ParticipantModal = ({
  isOpen,
  onClose,
  initialRef,
  finalRef,
  addParticipant,
  editParticipant,
  participant,
}) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: "",
    status: "",
    address: "",
    cp: "",
    city: "",
    phone: "",
    email: "",
    legal_aid: false,
    average_monthly_income: 0,
    regime: "AUTRE",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [nameError, setNameError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cpError, setCpError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const suggestionsRef = useRef();

  useOutsideClick({
    ref: suggestionsRef,
    handler: () => setShowSuggestions(false),
  });

  const areRequiredFieldsFilled = () => {
    return (
      (form.name?.trim() || "") !== "" &&
      form.status !== "" &&
      (form.address?.trim() || "") !== "" &&
      (form.cp?.trim() || "") !== "" &&
      (form.email?.trim() || "") !== ""
    );
  };

  useEffect(() => {
    if (participant) {
      setForm({
        name: participant.name || "",
        status: participant.status || "",
        address: participant.address || "",
        cp: participant.cp || "",
        city: participant.city || "",
        phone: participant.phone || "",
        email: participant.email || "",
        legal_aid: participant.legal_aid || false,
        average_monthly_income: participant.average_monthly_income || 0,
        regime: participant.regime || "AUTRE",
      });
    } else {
      setForm({
        name: "",
        status: "",
        address: "",
        cp: "",
        city: "",
        phone: "",
        email: "",
        legal_aid: false,
        average_monthly_income: 0,
        regime: "AUTRE",
      });
    }
  }, [participant]);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhone = (phone) => phone.trim() === "" || /^(?:\+?[1-9]\d{1,14}|\d{10})$/.test(phone);
  const validatePostalCode = (cp) => /^\d{5}$/.test(cp);

  const validateField = (field, value) => {
    let error = "";
    switch (field) {
      case 'name':
        if (!value.trim()) error = t('errors.nameRequired');
        break;
      case 'status':
        if (!value) error = t('errors.statusRequired');
        break;
      case 'address':
        if (!value.trim()) error = t('errors.addressRequired');
        break;
      case 'cp':
        if (!value.trim() || !validatePostalCode(value)) error = t('errors.invalidPostalCode');
        break;
      case 'phone':
        if (!validatePhone(value)) error = t('errors.invalidPhoneNumber');
        break;
      case 'email':
        if (!value.trim() || !validateEmail(value)) error = t('errors.invalidEmailFormat');
        break;
      default:
        break;
    }
    return error;
  };

  const handleInputChange = (field, value) => {
    setForm((prevForm) => ({ ...prevForm, [field]: value }));
    const error = validateField(field, value);
    switch (field) {
      case 'name':
        setNameError(error);
        break;
      case 'status':
        setStatusError(error);
        break;
      case 'address':
        setAddressError(error);
        break;
      case 'cp':
        setCpError(error);
        break;
      case 'phone':
        setPhoneError(error);
        break;
      case 'email':
        setEmailError(error);
        break;
      default:
        break;
    }
    if (field === 'address') {
      fetchAddressSuggestions(value);
      setShowSuggestions(true);
    }
    if (field === 'cp') {
      if (value.length === 5) {
        fetchCityFromPostalCode(value);
      } else {
        setForm((prevForm) => ({ ...prevForm, city: '' }));
      }
    }
  };

  const fetchAddressSuggestions = async (value) => {
    try {
      const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${value}&limit=5`);
      const suggestions = response.data.features.map((feature) => ({
        label: feature.properties.label,
        value: feature.properties.postcode,
      }));
      setAddressSuggestions(suggestions);
    } catch (error) {
      console.error("Error fetching address suggestions:", error);
    }
  };

  const fetchCityFromPostalCode = async (postalCode) => {
    try {
      const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=postcode=${postalCode}&limit=1`);
      if (response.data.features.length > 0) {
        const city = response.data.features[0].properties.city;
        setForm((prevForm) => ({ ...prevForm, city }));
      } else {
        setForm((prevForm) => ({ ...prevForm, city: '' }));
      }
    } catch (error) {
      console.error("Error fetching city from postal code:", error);
    }
  };

  const handleBlur = (field) => {
    if (field === 'average_monthly_income') {
      const formattedValue = parseFloat(form[field]).toFixed(0);
      setForm((prevForm) => ({ ...prevForm, [field]: formattedValue }));
    }
    const error = validateField(field, form[field]);
    switch (field) {
      case 'name':
        setNameError(error);
        break;
      case 'status':
        setStatusError(error);
        break;
      case 'address':
        setAddressError(error);
        break;
      case 'cp':
        setCpError(error);
        break;
      case 'phone':
        setPhoneError(error);
        break;
      case 'email':
        setEmailError(error);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const nameError = validateField('name', form.name || "");
    const statusError = validateField('status', form.status);
    const addressError = validateField('address', form.address || "");
    const cpError = validateField('cp', form.cp || "");
    const phoneError = validateField('phone', form.phone || "");
    const emailError = validateField('email', form.email || "");

    setNameError(nameError);
    setStatusError(statusError);
    setAddressError(addressError);
    setCpError(cpError);
    setPhoneError(phoneError);
    setEmailError(emailError);

    if (!nameError && !statusError && !addressError && !cpError && !phoneError && !emailError) {
      setIsSubmitting(true);
      try {
        if (participant) {
          await editParticipant(form);
        } else {
          await addParticipant(form);
        }
        onClose();
      } catch (error) {
        console.error("Failed to save participant:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl" fontWeight="bold" textAlign="center">
          {participant
            ? t("edit_participant_info")
            : t("admin__appointments_table__add_participant")}
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isInvalid={!!nameError}>
              <FormLabel>{t("admin__appointments_table__name")}<Text as="span" color="red.500">*</Text></FormLabel>
              <Input
                ref={initialRef}
                value={form.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
                onBlur={() => handleBlur('name')}
              />
              {nameError && <Text color="red.500" fontSize="sm">{nameError}</Text>}
            </FormControl>

            <FormControl isInvalid={!!statusError}>
              <FormLabel>
                {t("admin__appointments_table__status")}
                <Text as="span" color="red.500">*</Text>
              </FormLabel>
              <Select
                value={form.status}
                onChange={(e) => handleInputChange('status', e.target.value)}
                onBlur={() => handleBlur('status')}
              >
                <option value="" disabled>
                  {t("select_option")}
                </option>
                <option value="mother">{t("family_relation__mother")}</option>
                <option value="father">{t("family_relation__father")}</option>
                <option value="sister">{t("family_relation__sister")}</option>
                <option value="brother">{t("family_relation__brother")}</option>
                <option value="grandmother">{t("family_relation__grandmother")}</option>
                <option value="grandfather">{t("family_relation__grandfather")}</option>
                <option value="grandparent">{t("family_relation__grandparent")}</option>
                <option value="grandson">{t("family_relation__grandson")}</option>
                <option value="granddaughter">{t("family_relation__granddaughter")}</option>
                <option value="grandchild">{t("family_relation__grandchild")}</option>
                <option value="aunt">{t("family_relation__aunt")}</option>
                <option value="uncle">{t("family_relation__uncle")}</option>
                <option value="niece">{t("family_relation__niece")}</option>
                <option value="nephew">{t("family_relation__nephew")}</option>
                <option value="cousin">{t("family_relation__cousin")}</option>
                <option value="sister_in_law">{t("family_relation__sister_in_law")}</option>
                <option value="brother_in_law">{t("family_relation__brother_in_law")}</option>
                <option value="mother_in_law">{t("family_relation__mother_in_law")}</option>
                <option value="father_in_law">{t("family_relation__father_in_law")}</option>
                <option value="fiance">
                  {form.sex === 'M'
                    ? t("family_relation__fiance").split('/')[0]
                    : form.sex === 'f'
                      ? t("family_relation__fiance").split('/')[1]
                      : t("family_relation__fiance")
                  }
                </option>

                <option value="friend">{t("family_relation__friend")}</option>
              </Select>
              {statusError && <Text color="red.500" fontSize="sm">{statusError}</Text>}
            </FormControl>

            <FormControl isInvalid={!!addressError}>
              <FormLabel>{t("admin__appointments_table__address")}<Text as="span" color="red.500">*</Text></FormLabel>
              <div style={{ position: 'relative' }}>
                <Input
                  value={form.address}
                  onChange={(e) => handleInputChange('address', e.target.value)}
                  onFocus={() => setShowSuggestions(true)}
                  onBlur={() => setShowSuggestions(false)}
                />
                {/* {showSuggestions && addressSuggestions.length > 0 && (
                  <div ref={suggestionsRef} style={{ position: 'absolute', top: '100%', left: 0, right: 0, zIndex: 1, backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '4px' }}>
                    {addressSuggestions.map((suggestion, index) => (
                      <div
                      key={index}
                      onClick={() => handleInputChange('address', suggestion.label)}
                      style={{ padding: '8px', cursor: 'pointer' }}
                      >
                        {suggestion.label}
                      </div>
                    ))}
                  </div>
                )} */}
              </div>
              {addressError && <Text color="red.500" fontSize="sm">{addressError}</Text>}
            </FormControl>

            <FormControl isInvalid={!!cpError}>
              <FormLabel>{t("admin__appointments_table__postal_code")}<Text as="span" color="red.500">*</Text></FormLabel>
              <Input
                value={form.cp}
                onChange={(e) => handleInputChange('cp', e.target.value)}
                onBlur={() => handleBlur('cp')}
              />
              {cpError && <Text color="red.500" fontSize="sm">{cpError}</Text>}
              {form.city && form.cp.length >= 5 && (
                <Text color="green.500" fontSize="md" mt={2}>
                  {form.city}
                </Text>
              )}

            </FormControl>

            <FormControl isInvalid={!!phoneError}>
              <FormLabel>{t("admin__appointments_table__telephone")}</FormLabel>
              <Input
                value={form.phone}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                onBlur={() => handleBlur('phone')}
              />
              {phoneError && <Text color="red.500" fontSize="sm">{phoneError}</Text>}
            </FormControl>

            <FormControl isInvalid={!!emailError}>
              <FormLabel>{t("admin__appointments_table__email")}<Text as="span" color="red.500">*</Text></FormLabel>
              <Input
                value={form.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                onBlur={() => handleBlur('email')}
              />
              {emailError && <Text color="red.500" fontSize="sm">{emailError}</Text>}
            </FormControl>
            <FormControl>
              <FormLabel>{t("admin__appointments_table__average_monthly_income")} (EUR)</FormLabel>
              <Input
                value={form.average_monthly_income}
                type="number"
                onChange={(e) => handleInputChange('average_monthly_income', e.target.value)}
                onBlur={() => handleBlur('average_monthly_income')}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("admin__appointments_table__regime")}</FormLabel>
              <Select
                value={form.regime}
                onChange={(e) => handleInputChange('regime', e.target.value)}
                onBlur={() => handleBlur('regime')}
              >
                <option value="AUTRE">{t("autre")}</option>
                <option value="CAF">CAF</option>
                <option value="MSA">MSA</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>{t("admin__appointments_table__legal_aid")}</FormLabel>
              <Checkbox
                isChecked={form.legal_aid}
                onChange={(e) => handleInputChange('legal_aid', e.target.checked)}
              >
                {t("admin__appointments_table__legal_aid_value")}
              </Checkbox>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4} justify="flex-end" width="100%">
            <Button
              colorScheme="red"
              onClick={handleSubmit}
              isLoading={isSubmitting}
              loadingText={t(".....")}
              isDisabled={!areRequiredFieldsFilled() || !!(nameError || statusError || addressError || cpError || emailError)}
            >
              {participant
                ? t("update")
                : t("admin__appointments_table__add_participant")}
            </Button>
            <Button onClick={onClose}>{t("cancel")}</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ParticipantModal;
