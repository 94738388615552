import React, { useState, useCallback } from 'react';
import {
    Box,
    Image,
    Icon,
    Flex,
    Button,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Text
} from '@chakra-ui/react';
import { FaUpload } from 'react-icons/fa';
import Cropper from 'react-easy-crop';
import { useTranslation } from "react-i18next";
import { getCroppedImg } from './cropImage'; // Ensure this function is implemented

const UploadBox = ({ label, image, altText, noImageText, onUpload, isSignature }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedFile, setSelectedFile] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const aspectRatio = isSignature ? 2 : 1; // Adjust the aspect ratio based on isSignature

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setSelectedFile(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const handleSaveFile = async () => {
        try {
            const croppedImage = await getCroppedImg(selectedFile, croppedAreaPixels);
            onUpload(croppedImage); // Send cropped image to parent
            handleCloseModal();
        } catch (error) {
            console.error('Error cropping the image:', error);
        }
    };

    const handleCloseModal = () => {
        setSelectedFile(null); // Reset selected file state
        setCrop({ x: 0, y: 0 }); // Reset crop state
        setZoom(1); // Reset zoom state
        setCroppedAreaPixels(null); // Reset cropped area pixels state
        onClose(); // Close the modal
    };


    return (
        <Box flex="1" maxW="300px">
            <Box
                position="relative"
                w="100%"
                h="200px"
                borderRadius={isSignature ? 'md' : 'lg'}
                overflow="hidden"
                border="2px dashed"
                borderColor="gray.300"
                transition="all 0.3s"
                _hover={{ borderColor: 'blue.500' }}
                onClick={onOpen}
            >
                {image ? (
                    <Image w="100%" h="100%" objectFit="cover" src={image} alt={altText} />
                ) : (
                    <Flex
                        w="100%"
                        h="100%"
                        alignItems="center"
                        justifyContent="center"
                        bg="gray.50"
                        flexDirection="column"
                        onClick={onOpen}
                    >
                        <Icon as={FaUpload} boxSize={8} color="gray.400" mb={2} />
                        <Text color="gray.500" fontSize="sm" textAlign="center">
                            {noImageText}
                        </Text>
                    </Flex>
                )}
            </Box>
            {isOpen ?
                <Modal isOpen={isOpen} onClose={handleCloseModal} closeOnOverlayClick closeOnEsc>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{label}</ModalHeader>
                        <ModalBody>
                            {selectedFile ? (
                                <Box position="relative" width="100%" height="300px">
                                    <Cropper
                                        image={selectedFile}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={aspectRatio} // Set the aspect ratio dynamically
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    position="relative"
                                    w="100%"
                                    h="200px"
                                    borderRadius={isSignature ? 'md' : 'lg'}
                                    overflow="hidden"
                                    border="2px dashed"
                                    borderColor="gray.300"
                                    transition="all 0.3s"
                                    _hover={{ borderColor: 'blue.500' }}
                                >
                                    <Flex
                                        w="100%"
                                        h="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                        bg="gray.50"
                                        flexDirection="column"
                                    >
                                        <Icon as={FaUpload} boxSize={8} color="gray.400" mb={2} />
                                        <Text color="gray.500" fontSize="sm" textAlign="center">
                                            {noImageText}
                                        </Text>
                                    </Flex>
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        opacity={0}
                                        w="100%"
                                        h="100%"
                                        position="absolute"
                                        top={0}
                                        left={0}
                                        cursor="pointer"
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Flex justify="flex-end" gap={4}>
                                <Button
                                    bg="#FF6666" // Set initial background color
                                    color="white" // Text color
                                    _hover={{ bg: "#CC4444" }} // Set hover color
                                    _disabled={{ bg: "gray.300", color: "gray.500", _hover: { bg: "gray.300" }, cursor: "not-allowed" }} // Disabled state
                                    textTransform="capitalize"
                                    onClick={handleSaveFile}>
                                    {t("save")}
                                </Button>
                                <Button variant="outline" onClick={handleCloseModal}>
                                    {t("cancel")}
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                : ''}
        </Box>
    );
};

export default UploadBox;
