// src/actions/mediatorActions.js
export const setSelectedMediator = (mediator) => {
    return {
      type: 'SET_SELECTED_MEDIATOR',
      payload: mediator,
    };
  };
  
  export const clearMediator = () => ({
    type: "CLEAR_MEDIATOR",
  });