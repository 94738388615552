import axiosInstance from '../axiosConfig';
import axios from "axios"
const API_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary/rdv/session/`;
const API_GENERAL_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-api/`;

const rdvSessionService = {
  initial: async ({ locationId, serviceId, date }) => {
    try {
      const response = await axiosInstance.get(`${API_URL}avaibility/?&date=${date}&location=${locationId}&service=${serviceId}`);
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Failed to fetch availability. Please check your credentials.");
    }
  },

  getByMonthAvailabilityWithProviders: async ({ locationId, serviceId, type, fromDate, toDate, accessToken }) => {
    let url
    if (locationId) {
      url = `${API_GENERAL_URL}cithea/avaibility/?from=${fromDate}&to=${toDate}&location=${locationId}&service=${serviceId}&type=${type}`
    } else {
      url = `${API_GENERAL_URL}cithea/avaibility/?from=${fromDate}&to=${toDate}&service=${serviceId}&is_location_agnostic=true`
    }
    try {
      const response = await axios.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      // console.log(response.data);
      return response.data;
    } catch (err) {
      throw err;
    }
  },

  getByMonthAvailability: async ({ locationId, serviceId, fromDate, type, toDate }) => {
    let url
    if (locationId) {
      url = `${API_GENERAL_URL}cithea/avaibility/?from=${fromDate}&to=${toDate}&location=${locationId}&service=${serviceId}&type=${type}`
    } else {
      url = `${API_GENERAL_URL}cithea/avaibility/?from=${fromDate}&to=${toDate}&service=${serviceId}&is_location_agnostic=true`
    }
    try {
      const response = await axios.get(`${url}`);
      // console.log(response.data);
      return response.data;
    } catch (err) {
      throw new Error("Failed to fetch availability.");
    }
  },
  get: async ({ pk }) => {
    throw new Error("Not implemented");
  },

  create: async ({ payload }) => {
    try {
      const response = await axiosInstance.post(`${API_URL}`, payload);
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Failed to create session. Please check your credentials.");
    }
  },

  update: async ({ pk, payload }) => {
    try {
      console.log(`Payload is ${JSON.stringify(payload)}`);
      const response = await axiosInstance.patch(`${API_URL}${pk}/`, payload);
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Update failed, please log out and try again.");
    }
  },
  updateDoc: async ({ pk, payload, docId }) => {
    try {
      console.log(`Payload is ${JSON.stringify(payload)}`);
      const response = await axiosInstance.patch(`${API_URL}${pk}/document/${docId}/`, payload);
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Update failed, please log out and try again.");
    }
  },
  addComment: async ({ pk, obj, accessToken }) => {
    try {
      console.log('calling adding a new comment');
      const response = await axiosInstance.post(`${API_URL}${pk}/comment/`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  softDelete: async ({ pk }) => {
    try {
      const response = await axiosInstance.delete(`${API_URL}${pk}/`);
      console.log(response);
      return;
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Failed to delete the RDV Session.");
    }
  },

  deleteDoc: async (sessionId, docId) => {
    try {

      const res = await axiosInstance.delete(`${API_URL}${sessionId}/document/${docId}/`);
      return {
        data: res.data
      }
    }
    catch (error) {
      throw new Error('Failed to delete the session doc');
    }
  },

  generateAttestation: async (sessionId, participantId, timeZoneInfo) => {
    try {
      console.log({ sessionId, participantId, timeZoneInfo });
      const res = await axiosInstance.get(`${API_URL}${sessionId}/participant/${participantId}/attestation/`, {
        params: {
          timezone: timeZoneInfo,
        },
      });
      return {
        data: res.data,
      }
    } catch {
      throw new Error('Failed to generate attestaion');
    }
  },

  emailAttestation: async (sessionId, participantId) => {
    try {
      console.log({ sessionId, participantId });
      const res = await axiosInstance.get(`${API_URL}${sessionId}/participant/${participantId}/attestation/email/`);
      return {
        data: res.data,
      }
    } catch {
      throw new Error('Failed to generate attestaion');
    }
  },

  generatePaymentTicket: async (sessionId, participantId, timeZoneInfo) => {
    try {
      console.log({ sessionId, participantId });
      const res = await axiosInstance.get(`${API_URL}${sessionId}/participant/${participantId}/payment/`, {
        params: {
          timezone: timeZoneInfo,
        },
      });
      return {
        data: res.data,
      }
    } catch {
      throw new Error('Failed to generate payment');
    }
  },

  emailPaymentTicket: async (sessionId, participantId) => {
    try {
      console.log({ sessionId, participantId });
      const res = await axiosInstance.get(`${API_URL}${sessionId}/participant/${participantId}/payment/email/`);
      return {
        data: res.data,
      }
    } catch {
      throw new Error('Failed to generate attestaion');
    }
  },

};

export default rdvSessionService;
