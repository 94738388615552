import axiosInstance from '../axiosConfig'; // Import the configured Axios instance

const API_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-api`;

const locationService = {
  getAll: async (associationId) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axiosInstance.get(`${API_URL}/cithea/location/`);
      // Log
      console.log("Location data: ", response.data);
      // Return data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Unable to fetch locations.");
    }
  },

  new: async (payload, accessToken) => {
    try {
        const response = await axiosInstance.post(`${API_URL}/secretary/location/`, payload);
        return response.data;
    }catch (err){
      console.log(err);
      throw new Error("Unable to create a new Location");
    }
  },

  update: async (locationId,payload, accessToken) => {
    try {
      console.log(payload);
      // return;
      const response = await axiosInstance.patch(`${API_URL}/secretary/location/${locationId}/`, payload);
      return response.data;
    }catch (err){
      console.log(err);
      throw new Error("Unable to to update the Location");
    }
  }
};

export default locationService;
