// src/reducers/rdvReducer.js
import { SET_SELECTED_RDV, UPDATE_SELECTED_RDV } from '../actions/rdvActions';

const initialState = {
  selectedRDV: null,
};

const rdvReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_RDV:
      return {
        ...state,
        selectedRDV: action.payload,
      };
    case UPDATE_SELECTED_RDV:
      return {
        ...state,
        selectedRDV: {
          ...state.selectedRDV,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default rdvReducer;
