// actions.js
import axios from "axios";
import {
  COLLABORATOR__FETCH_DATA_FAILURE,
  COLLABORATOR__FETCH_DATA_REQUEST,
  COLLABORATOR__FETCH_DATA_SUCCESS,
} from "./types/collaboratorAction.types";

// Action creators
const fetchDataRequest = () => ({
  type: COLLABORATOR__FETCH_DATA_REQUEST,
});

const fetchDataSuccess = (data) => ({
  type: COLLABORATOR__FETCH_DATA_SUCCESS,
  payload: data,
});

const fetchDataFailure = (error) => ({
  type: COLLABORATOR__FETCH_DATA_FAILURE,
  payload: error,
});

export const fetchData = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const accessToken = state.auth.accessToken;

    dispatch(fetchDataRequest());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary/mediator`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(fetchDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};
