import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  Flex,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Select,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Portal
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EditIcon, DeleteIcon, WarningIcon } from "@chakra-ui/icons";
import { FaEnvelope, FaFileAlt, FaTicketAlt, FaUserPlus, FaCheckCircle, FaCheck, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import rdvSessionService from "services/rdvSessionService";
import rdvService from "services/rdvService";
import locationService from "services/locationService";
import ParticipantModal from "./ParticipantModal";
import GenericAlertDialog from "./GenericAlertDialog";
import CreateFirstSessionDialog from "./CreateFirstSessionDialog";
import PostponedSessionDialog from "./PostponedSessionDialog";
import DocumentsTab from "./DocumentsTab";
import SessionCommantsTab from "./SessionCommantsTab";
import Utils from "services/Utils";
import CancelReasonModal from "./CancelReasonModal";
import AttestationPaymentActions from "./AttestationPaymentActions";

const activeTabStyle = {
  color: "#FF6666",
  fontSize: "18px",
  fontWeight: "600",
  borderBottom: "2px solid #FF6666",
};

const inactiveTabStyle = {
  color: "#565656",
  fontSize: "18px",
  fontWeight: "600",
};

const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  fontWeight: "500",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};



const CSessions = ({
  collaboratorsData,
  appointmentData,
  accessToken,
  onStateChange,
  association
}) => {
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(0);

  const { t, i18n } = useTranslation();

  const [activeTab, setActiveTab] = useState("sessionDetails");
  const [isFirstSessionDialogOpen, setIsFirstSessionDialogOpen] = useState(false);
  const [isPostponedSessionDialogOpen, setIsPostponedSessionDialogOpen] = useState(false);

  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(appointmentData.location);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [alertDialogBody, setAlertDialogBody] = useState("");
  const [alertDialogOnClose, setAlertDialogOnClose] = useState(() => () => { });
  const [alertDialogConfirm, setAlertDialogConfirm] = useState(() => () => { });
  const [alertConfirmCallback, setAlertConfirmCallback] = useState(() => () => { });
  const [confirmationType, setConfirmationType] = useState("");

  const [isCancelReasonModalOpen, setIsCancelReasonModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");


  const [currentParticipant, setCurrentParticipant] = useState(null);
  const initialParticipantRef = useRef(null);
  const finalParticipantRef = useRef(null);

  console.log({ collaboratorsData });

  const reasonTranslationMap = {
    "Participant unavailable": t("participant_unavailable"),
    "Location unavailable": t("location_unavailable"),
    "Mediator unavailable": t("mediator_unavailable"),
    "Something else": t("something_else"),
  };

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  useEffect(() => {
    locationService.getAll(appointmentData.association).then((response) => {
      setAvailableLocations(response.data);
    });
  }, [appointmentData.association]);

  useEffect(() => {
    console.log("appointmentData", appointmentData.rdv_sessions)
    if (appointmentData.rdv_sessions.length > 0) {
      let newSelectedSession;
      const newSessionId = localStorage.getItem('newSessionId');
      const lastSessionId = localStorage.getItem('lastSessionId');

      if (newSessionId) {
        console.log('New session found in local storage');
        newSelectedSession = appointmentData.rdv_sessions.find(
          (session) => session.id === parseInt(newSessionId)
        );
        localStorage.removeItem('newSessionId'); // Clear the new session ID from local storage
      } else if (lastSessionId) {
        console.log('Last session found in local storage');
        newSelectedSession = appointmentData.rdv_sessions.find(
          (session) => session.id === parseInt(lastSessionId)
        );
        if (!newSelectedSession) {
          console.log('undefined');
          newSelectedSession = appointmentData.rdv_sessions[0];
        }
      } else {
        console.log('No session found in local storage');
        newSelectedSession = appointmentData.rdv_sessions[0];
      }

      setSelectedSession(newSelectedSession);
      setSelectedSessionId(newSelectedSession.id);
      setCurrentSessionIndex(
        appointmentData.rdv_sessions.findIndex(
          (session) => session.id === newSelectedSession.id
        )
      );

      // Store the selected session ID in local storage whenever it changes
      localStorage.setItem('lastSessionId', newSelectedSession.id);
    }
  }, [appointmentData.rdv_sessions]);

  const openDialog = (title, body, confirmType, confirm, close) => {
    setAlertDialogTitle(title);
    setAlertDialogBody(body);
    setConfirmationType(confirmType);
    setAlertDialogConfirm(() => confirm);
    setAlertDialogOnClose(() => close);
    onOpen();
  };

  const handleConfirm = () => {
    console.log("handleConfirm called");
    alertDialogConfirm(); // Call the stored confirmation callback
    onClose(); // Close the dialog after confirming
  };

  const handleClose = () => {
    console.log("handleClose called");
    alertDialogOnClose();
    onClose();
  };

  const updateRdvSession = async ({ payload }) => {
    try {
      await rdvSessionService.update({
        pk: selectedSession.id,
        payload,
        accessToken,
      });
      onStateChange();
    } catch (error) {
      console.error(t("select_appropriate_location_and_participants"));
    }
  };

  const handleInviteParticipant = async (participant) => {
    try {
      await updateRdvSession({
        payload: {
          participants: [...selectedSession.participants.map(p => p.id), participant.id],
          location: selectedLocation.id,
        },
      });
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("participants_invited")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
    } catch (error) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("failed_to_invite")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  };

  const handlePostponeSession = () => {
    setIsPostponedSessionDialogOpen(true);
  };

  const handleAddParticipant = useCallback(async (participantForm) => {
    try {
      await rdvService.addParticipant({
        pk: appointmentData.id,
        participant: participantForm,
        accessToken: accessToken,
      });
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("messages.participantAdded")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
      onStateChange();
    } catch (error) {
      console.error("Failed to add participant:", error);
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.failedToAddParticipant")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  }, [appointmentData?.id, accessToken, onStateChange, t, onClose, onOpen]);

  const handleEditParticipant = useCallback(async (participantForm) => {
    try {
      await rdvService.updateParticipantInfo({
        rdvId: appointmentData.id,
        participantId: currentParticipant.id,
        obj: participantForm,
        accessToken,
      });
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("messages.changesSaved")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
      onStateChange();
    } catch (error) {
      console.error("Failed to update participant:", error);
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.failedToUpdateParticipant")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  }, [appointmentData?.id, currentParticipant, accessToken, onStateChange, t]);

  const handleRemoveParticipant = useCallback(async (participant) => {
    try {
      const updatedParticipants = appointmentData.participants.filter(
        (p) => p.id !== participant.id
      );
      const response = await rdvService.deleteParticipant({
        pk: appointmentData.id,
        updatedParticipants: {
          participants: updatedParticipants.map(p => p.id),
        },
        accessToken,
      });

      toast.success(
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaCheck style={{ marginRight: "8px" }} />
          {t("messages.changesSaved")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: "auto",
            minWidth: "400px",
          },
          icon: false,
        }
      );
      await onStateChange();
    } catch (error) {
      console.error("Error in alertConfirmCallback:", error);
      toast.error(
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaTimes style={{ marginRight: "8px", color: "white" }} />
          {t("messages.failedToRemoveParticipant")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: "auto",
            minWidth: "400px",
          },
          progressBar: false,
          icon: false
        }
      );
    }
  }, [appointmentData?.id, accessToken, onStateChange, t, onClose, onOpen]);


  const openModal = useCallback((participant = null) => {
    setCurrentParticipant(participant);
    onModalOpen();
  }, [onModalOpen]);

  const handleCancelConfirmation = async (reason) => {
    setCancelReason(reason);
    const newStatus = "Cancelled";
    try {
      await updateRdvSession({
        payload: {
          status: newStatus,
          status_cancel_reason: reason, // Include the reason in the payload
        },
      });

      setCancelReason("");
      // Call the function to refresh state
      onStateChange();

      toast.success(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("status_updated")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
    } catch (error) {
      console.error("Failed to update session status:", error);
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px' }} />
          {t("status_update_failed")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
    }
  };


  const handlePrevSession = () => {
    setCurrentSessionIndex((prevIndex) => (prevIndex - 1 + appointmentData.rdv_sessions.length) % appointmentData.rdv_sessions.length);
    const newIndex = (currentSessionIndex - 1 + appointmentData.rdv_sessions.length) % appointmentData.rdv_sessions.length;
    const newSession = appointmentData.rdv_sessions[newIndex];
    setSelectedSessionId(newSession.id);
    setSelectedSession(newSession);
    localStorage.setItem('lastSessionId', newSession.id);
  };

  const handleNextSession = () => {
    setCurrentSessionIndex((prevIndex) => (prevIndex + 1) % appointmentData.rdv_sessions.length);
    const newIndex = (currentSessionIndex + 1) % appointmentData.rdv_sessions.length;
    const newSession = appointmentData.rdv_sessions[newIndex];
    setSelectedSessionId(newSession.id);
    setSelectedSession(newSession);
    localStorage.setItem('lastSessionId', newSession.id);
  };


  const condittionsToMarkAsOccured = (session) => {

    // const nextDayMidnight = new Date();
    // nextDayMidnight.setHours(0, 0, 0, 0); // Set the time to 00:00 of the current day
    // nextDayMidnight.setDate(nextDayMidnight.getDate() + 1); // Move to the next day

    if (new Date(session?.calendar_block?.end_date) > new Date()) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.dateNotArrived")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
      return false;
    }
    if (session?.thread.length === 0) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {t("messages.noThreadsAdded")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            margin: "auto",
            color: "white",
            minWidth: '600px'
          },
          progressBar: false,
          icon: false
        }
      );
      return false;
    }

    if (session?.documents.length === 0) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.noDocAdded")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '600px'
          },
          progressBar: false,
          icon: false
        }
      );
      return false;
    }

    if (session?.participants.length === 0) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.noParticipantInvited")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '600px'
          },
          progressBar: false,
          icon: false
        }
      );
      return false;
    }

    return true;
  };

  const condittionsToMarkAsScheduled = (session) => {
    if (session?.participants.length === 0) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {t("messages.noParticipantInvitedV2")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            margin: "auto",
            color: "white",
            minWidth: '600px'
          },
          progressBar: false,
          icon: false
        }
      );
      return false;
    }

    return true;
  }

  return (
    <Box mt={4}>
      {appointmentData.rdv_sessions.length === 0 ? (
        <Flex>
          {t('no_session_found_create_new')}
        </Flex>
      ) : (
        <>
          <Grid templateRows="" p={2}>
            <GridItem
              width={'100%'}
              height={'200px'}
              overflowX={'auto'}
              overflowY={'hidden'}
              p={3}
              css={{
                /* Custom scrollbar styling */
                '&::-webkit-scrollbar': {
                  height: '4px', // Set the scrollbar height to 1px
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#EE6666', /* Thumb color */
                  borderRadius: '4px', // Adjust the radius to match the height
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#EE7777', /* Thumb color on hover */
                },
                '-ms-overflow-style': 'none', /* IE and Edge */
                'scrollbar-width': 'none', /* Firefox */
              }}
            >
              <Box display="flex" alignItems="center"
              // border={'1px solid green'}
              >
                {/* <IconButton
                  icon={<ChevronLeftIcon boxSize={10} color="#EE6464" />}
                  onClick={handlePrevSession}
                  bg="none"
                  disabled={appointmentData.rdv_sessions.length <= 1}
                  aria-label="Previous session"
                  _hover={{}}
                /> */}
                <Flex alignItems={"stretch"} justify="start" flexGrow={1}>
                  <>
                    {appointmentData.rdv_sessions.length > 0 ? appointmentData.rdv_sessions.map((session) => (
                      <Box
                        key={session.id}
                        minWidth="250px"
                        borderWidth="1px"
                        borderRadius="md"
                        padding="4"
                        marginX="3"
                        cursor="pointer"
                        onClick={() => {
                          setSelectedSession(session);
                          setSelectedSessionId(session.id);
                          localStorage.setItem('lastSessionId', session.id);
                        }}
                        backgroundColor={
                          session.status === "Cancelled" || session.status === "Late Cancel"
                            ? "#FFCCCC"
                            : session.status === "Occurred"
                              ? "#CCE5FF"
                              : session.status === "Scheduled"
                                ? "#CCFFCC"
                                : session.status === "Postponed"
                                  ? "#E6FFE6"
                                  : session.status === "Created"
                                    ? "#FFE6CC"
                                    : "whiteAlpha.100"
                        }
                        transform={selectedSession && selectedSession.id === session.id ? "scale(1.09)" : "scale(1)"}
                        transition="transform 0.1s"
                        border={selectedSession && selectedSession.id === session.id ? "2px solid #EE6666" : "1px solid #ccc"}
                      >
                        <Grid templateColumns="90% 10%">
                          <GridItem>
                            <Text fontWeight="bold" fontSize={"16px"} color={"#FF3E3E"}>
                              {session.type === 'Info' ? t("admin_session_type_info") : t("admin_session_type_mediation")}
                            </Text>
                            <Text fontWeight="bold" fontSize={"16px"} color={"gray"}>
                              {session.calendar_block ? `${session.calendar_block.title}` : ""}
                            </Text>
                            <Flex alignItems="center">
                              <Text fontWeight="bold" color={"gray"} fontSize={"14px"} mr={2}>
                                {t(`status__${session.status.toLowerCase().replace(" ", "_")}`)}
                              </Text>
                              <Menu>
                                <MenuButton
                                  as={IconButton}
                                  icon={<ChevronDownIcon />}
                                  variant="outline"
                                  size="md"
                                  aria-label="Options"
                                  border='none'
                                  isDisabled={appointmentData.is_closed}
                                />

                                <Portal
                                >
                                  <MenuList zIndex="20000" position={'absolute'}
                                  >
                                    {!appointmentData.is_closed && (
                                      <>
                                        {session.status === 'Created' && (
                                          <>
                                            <MenuItem
                                              onClick={async () => {
                                                if (condittionsToMarkAsScheduled(selectedSession)) {
                                                  const newStatus = "Scheduled";
                                                  setSelectedSession({
                                                    ...selectedSession,
                                                    status: newStatus,
                                                  });

                                                  await updateRdvSession({
                                                    payload: {
                                                      status: newStatus,
                                                    },
                                                  });

                                                  toast.success(
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                      <FaCheck style={{ marginRight: '8px' }} />
                                                      {t("status_updated")}
                                                    </div>,
                                                    {
                                                      position: "bottom-center",
                                                      style: {
                                                        backgroundColor: "#FF6666",
                                                        color: "white",
                                                        width: 'auto',
                                                        minWidth: '400px'
                                                      },
                                                      icon: false
                                                    }
                                                  );

                                                  onStateChange();
                                                }
                                              }}
                                            >
                                              {t("status__scheduled")}
                                            </MenuItem>
                                            {/* <MenuItem>
                                        {t("status__cancelled")}
                                        </MenuItem> */}
                                          </>
                                        )}
                                        {session.status === "Scheduled" && (
                                          <>
                                            <MenuItem
                                              onClick={() => {
                                                setIsCancelReasonModalOpen(true);
                                              }}
                                            >
                                              {t("status__cancelled")}
                                            </MenuItem>

                                            <MenuItem
                                              onClick={async () => {
                                                if (condittionsToMarkAsOccured(selectedSession)) {
                                                  const newStatus = "Occurred";
                                                  setSelectedSession({
                                                    ...selectedSession,
                                                    status: newStatus,
                                                  });

                                                  await updateRdvSession({
                                                    payload: {
                                                      status: newStatus,
                                                    },
                                                  });

                                                  toast.success(
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                      <FaCheck style={{ marginRight: '8px' }} />
                                                      {t("status_updated")}
                                                    </div>,
                                                    {
                                                      position: "bottom-center",
                                                      style: {
                                                        backgroundColor: "#FF6666",
                                                        color: "white",
                                                        width: 'auto',
                                                        minWidth: '400px'
                                                      },
                                                      icon: false
                                                    }
                                                  );
                                                }
                                              }}
                                            >
                                              {t("status__occurred")}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={handlePostponeSession}
                                            >
                                              {t("status__postponed")}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={async () => {
                                                const newStatus = "Late Cancel";
                                                setSelectedSession({
                                                  ...selectedSession,
                                                  status: newStatus,
                                                });

                                                await updateRdvSession({
                                                  payload: {
                                                    status: newStatus,
                                                  },
                                                });

                                                toast.success(
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <FaCheck style={{ marginRight: '8px' }} />
                                                    {t("status_updated")}
                                                  </div>,
                                                  {
                                                    position: "bottom-center",
                                                    style: {
                                                      backgroundColor: "#FF6666",
                                                      color: "white",
                                                      width: 'auto',
                                                      minWidth: '400px'
                                                    },
                                                    icon: false
                                                  }
                                                );

                                                onStateChange();
                                              }}
                                            >
                                              {t("status__late_cancel")}
                                            </MenuItem>
                                          </>
                                        )}
                                        {session.status === "Postponed" && (
                                          <>
                                            <MenuItem
                                              onClick={() => {
                                                setIsCancelReasonModalOpen(true);
                                              }}
                                            >
                                              {t("status__cancelled")}
                                            </MenuItem>

                                            <MenuItem
                                              onClick={async () => {
                                                if (condittionsToMarkAsOccured(selectedSession)) {
                                                  const newStatus = "Occurred";
                                                  setSelectedSession({
                                                    ...selectedSession,
                                                    status: newStatus,
                                                  });

                                                  await updateRdvSession({
                                                    payload: {
                                                      status: newStatus,
                                                    },
                                                  });

                                                  toast.success(
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                      <FaCheck style={{ marginRight: '8px' }} />
                                                      {t("status_updated")}
                                                    </div>,
                                                    {
                                                      position: "bottom-center",
                                                      style: {
                                                        backgroundColor: "#FF6666",
                                                        color: "white",
                                                        width: 'auto',
                                                        minWidth: '400px'
                                                      },
                                                      icon: false
                                                    }
                                                  );
                                                }
                                              }
                                              }

                                            >
                                              {t("status__occurred")}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={handlePostponeSession}
                                            >
                                              {t("status__postponed_again")}
                                            </MenuItem>
                                          </>
                                        )}
                                        {session.status === "Cancelled" && (
                                          <>
                                            <MenuItem isDisabled>{t("status__cancelled")}</MenuItem>
                                          </>
                                        )}
                                        {session.status === "Late Cancel" && (
                                          <>
                                            <MenuItem isDisabled>{t("status__late_cancel")}</MenuItem>
                                          </>
                                        )}
                                        {session.status === "Occurred" && (
                                          <>
                                            <MenuItem isDisabled>{t("status__occurred")}</MenuItem>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </MenuList>

                                </Portal>

                              </Menu>
                            </Flex>
                            <Flex alignItems={'flex-end'}>

                              <Text>
                                {new Date(session?.calendar_block?.start_date).toLocaleDateString(
                                  i18n.language === 'fr' ? 'fr-FR' : 'en-US',
                                  {
                                    day: '2-digit',
                                    month: 'short'
                                  }
                                )
                                  .replace('.', '') // Remove periods (in some locales)
                                  .replace(/\b\w/g, char => char.toUpperCase())} {/* Capitalize each word */}
                                {' - '}
                                {`${Utils.convertUTCToLocalTimeZone(new Date(session.calendar_block.start_date).toISOString().split("T")[1].slice(0, 5))}h`}
                              </Text>

                            </Flex>
                          </GridItem>
                          <GridItem>
                            {!appointmentData.is_closed && (
                              <Center>
                                <DeleteIcon
                                  size={20}
                                  color={"#FF6666"}
                                  cursor={session.status === "Occurred" ? "not-allowed" : "pointer"}
                                  display={session.status === "Occurred" ? "none" : ""}
                                  onClick={() => {
                                    openDialog(
                                      t("delete_session"),
                                      t("delete_session_confirmation"),
                                      "deleteSession",
                                      async () => {
                                        try {
                                          await rdvSessionService.softDelete({
                                            pk: session.id
                                          });

                                          // Update the list of sessions
                                          onStateChange();

                                          // Select the first session in the updated list
                                          if (appointmentData.rdv_sessions.length > 0) {
                                            const newSelectedSession = appointmentData.rdv_sessions[0];
                                            setSelectedSession(newSelectedSession);
                                            setSelectedSessionId(newSelectedSession.id);
                                            localStorage.setItem('lastSessionId', newSelectedSession.id);
                                          } else {
                                            // If no sessions left, clear the session state
                                            setSelectedSession(null);
                                            setSelectedSessionId(null);
                                            localStorage.removeItem('lastSessionId');
                                          }

                                          localStorage.removeItem('newSessionId'); // Clear the new session ID from local storage
                                        } catch (error) {
                                          console.error("Failed to delete session:", error);
                                          toast.error(
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              <FaTimes style={{ marginRight: "8px", color: "white" }} />
                                              {t("failed_to_delete_session")}
                                            </div>,
                                            {
                                              position: "bottom-center",
                                              style: {
                                                backgroundColor: "red",
                                                color: "white",
                                                width: "auto",
                                                minWidth: "400px",
                                              },
                                              progressBar: false,
                                              icon: false,
                                            }
                                          );
                                        }
                                      },
                                      () => {
                                        console.log("Closed without confirming.");
                                      }
                                    );
                                  }}
                                />

                              </Center>
                            )}
                          </GridItem>
                        </Grid>
                      </Box>
                    )) : "no Session Found"}
                  </>
                </Flex>
                {/* <IconButton
                  icon={<ChevronRightIcon boxSize={10} color="#EE6464" />}
                  onClick={handleNextSession}
                  bg="none"
                  disabled={appointmentData.rdv_sessions.length <= 1}
                  aria-label="Next session"
                  _hover={{}}
                /> */}
              </Box>
            </GridItem>
            <GridItem mt={12}>
              <Tabs onChange={(index) => {
                switch (index) {
                  case 0:
                    setActiveTab("sessionDetails");
                    break;
                  case 1:
                    setActiveTab("notes");
                    break;
                  case 2:
                    setActiveTab("documents");
                    break;
                  default:
                    break;
                }
              }}>
                <TabList >
                  <Tab ml={5} sx={activeTab === "sessionDetails" ? activeTabStyle : inactiveTabStyle}
                    _selected={{
                      color: '#FF6666',
                      borderBottom: '2px solid #FF6666',
                    }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    {t("session_details")}
                  </Tab>
                  <Tab sx={activeTab === "notes" ? activeTabStyle : inactiveTabStyle}
                    _selected={{
                      color: '#FF6666',
                      borderBottom: '2px solid #FF6666',
                    }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    {t("notes")} {`(${selectedSession?.thread?.length})`}
                  </Tab>
                  <Tab ml={5} sx={activeTab === "documents" ? activeTabStyle : inactiveTabStyle}
                    _selected={{
                      color: '#FF6666',
                      borderBottom: '2px solid #FF6666',
                    }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    {t("documents")} {`(${selectedSession?.documents?.length})`}
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Flex direction="column" width="100%">
                      <FormControl mb={4} width={"100%"} isDisabled={appointmentData.is_closed}>
                        <FormLabel display={"flex"}>
                          {t("admin__appointments__status")}
                          <Text ml={1} color={"#EE0000"}>*</Text>
                        </FormLabel>
                        <Text>{new Date(selectedSession?.date) < new Date()}</Text>
                        <Select
                          id="status"
                          name="status"
                          className="form-control"
                          isRequired={true}
                          bg={"gray.100"}
                          fontSize={"14px"}
                          fontWeight={"400"}
                          borderRadius="4px"
                          readOnly
                          contentEditable={false}
                          value={selectedSession?.status || ''}
                          onChange={async (e) => {
                            const newValue = e.target.value;
                            setSelectedSession({
                              ...selectedSession,
                              status: newValue,
                            });
                            await updateRdvSession({
                              payload: {
                                status: newValue,
                              },
                            });
                            toast.success(
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FaCheck style={{ marginRight: '8px' }} />
                                {t("status_updated")}
                              </div>,
                              {
                                position: "bottom-center",
                                style: {
                                  backgroundColor: "#FF6666",
                                  color: "white",
                                  width: 'auto',
                                  minWidth: '400px'
                                },
                                icon: false
                              }
                            );
                          }}
                        >
                          {appointmentData.is_closed || selectedSession?.status !== "Scheduled" || !(new Date(selectedSession?.date) < new Date()) ? (
                            <option value={selectedSession?.status}>
                              {selectedSession?.status === "Postponed"
                                ? `${t(`status__${selectedSession?.status.toLowerCase().replace(" ", "_")}`)} (${selectedSession?.postpone_count} ${t("postpone_times")})`
                                : t(`status__${selectedSession?.status.toLowerCase().replace(" ", "_")}`)}
                            </option>
                          ) : (
                            <>
                              <option value="Scheduled">{t("status__scheduled")}</option>
                              <option value="Occurred">{t("status__occurred")}</option>
                              <option value="No Show">{t("status__no_show")}</option>
                              <option value="Late Cancel">{t("status__late_cancel")}</option>
                            </>
                          )}
                        </Select>

                      </FormControl>
                      {/* Postpone Reason */}
                      {selectedSession?.status === "Postponed" && (
                        <FormControl mb={4} width={"100%"}>
                          <FormLabel display={"flex"}>{t("postpone_reason")}</FormLabel>
                          <Select
                            cursor={'not-allowed'}
                            bg={"gray.100"}
                            fontSize="14px"
                            fontWeight="400"
                            borderRadius="4px"
                            value={selectedSession?.status_postpone_reason}
                            readOnly
                          >
                            <option value={selectedSession?.status_postpone_reason}>
                              {reasonTranslationMap[selectedSession?.status_postpone_reason] || selectedSession?.status_postpone_reason}
                            </option>
                          </Select>
                        </FormControl>
                      )}


                      {/* Cancel Reason */}
                      {selectedSession?.status === "Cancelled" && (
                        <FormControl mb={4} width={"100%"}>
                          <FormLabel display={"flex"}>{t("cancel_reason")}</FormLabel>
                          <Select cursor={'not-allowed'} bg={"gray.100"} fontSize="14px" fontWeight="400" borderRadius="4px" value={selectedSession?.status_cancel_reason} readOnly>
                            <option value={selectedSession?.status_cancel_reason}>
                              {selectedSession?.status_cancel_reason}
                            </option>
                          </Select>
                        </FormControl>
                      )}

                      <FormControl mb={4} isDisabled={appointmentData.is_closed}>
                        <FormLabel display={"flex"}>
                          {t("consultation_mode")}
                          <Text ml={1} color={"#EE0000"}>*</Text>
                        </FormLabel>
                        {/* {selectedSession?.id} */}
                        <Input
                          id="consultation_mode"
                          name="consultation_mode"
                          bg={"gray.100"}
                          className="form-control"
                          isRequired={true}
                          fontSize={"14px"}
                          fontWeight={"400"}
                          borderRadius="4px"
                          value={selectedSession?.consultation_mode
                            ? (i18n.language === 'fr'
                              ? selectedSession.consultation_mode.name_fr
                              : selectedSession.consultation_mode.name)
                            : 'N/A'}
                          contentEditable={false}
                          readOnly
                        />
                      </FormControl>

                      <FormControl mb={4} isDisabled={appointmentData.is_closed}>
                        <FormLabel display={"flex"}>
                          {t("session_type")}
                          <Text ml={1} color={"#EE0000"}>*</Text>
                        </FormLabel>
                        <Select
                          id="status"
                          name="status"
                          bg={"gray.100"}
                          className="form-control"
                          isRequired={true}
                          fontSize={"14px"}
                          fontWeight={"400"}
                          borderRadius="4px"
                          cursor={'not-allowed'}
                        >
                          <option value={selectedSession?.type}>{selectedSession?.type === 'Info' ? t("info") : t("mediation")}</option>
                        </Select>
                      </FormControl>
                      <FormControl mb={4} isDisabled={appointmentData.is_closed}>
                        <FormLabel display={"flex"}>
                          {t("location")}
                          <Text ml={1} color={"#EE0000"}>*</Text>
                        </FormLabel>
                        <Select
                          id="location"
                          name="location"
                          bg={"gray.100"}
                          className="form-control"
                          isRequired={true}
                          fontSize={"14px"}
                          fontWeight={"400"}
                          borderRadius="4px"
                          onChange={(e) => {
                            setSelectedLocation(e.target.value);
                          }}
                          value={selectedSession?.location ?? appointmentData?.location?.id}
                          contentEditable={false}
                          readOnly
                        >
                          {availableLocations.map((location) => (
                            <option key={location.id} value={location.id}>
                              {location.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>


                      <FormControl mb={4} isDisabled={appointmentData.is_closed}>
                        <Flex width={"100%"} gap={4}>
                          <Box width={"50%"}>
                            <FormLabel display={"flex"}>
                              {t("mediator")}
                              <Text ml={1} color={"#EE0000"}>*</Text>
                            </FormLabel>
                            <Select
                              id="mediator"
                              name="mediator"
                              bg={"gray.100"}
                              className="form-control"
                              isRequired={true}
                              fontSize={"14px"}
                              fontWeight={"400"}
                              borderRadius="4px"
                              value={selectedSession?.service_provider ? selectedSession?.service_provider.id : ""}
                              cursor={'not-allowed'}
                            >
                              <option value={selectedSession?.service_provider ? selectedSession?.service_provider.id : ""}>
                                {selectedSession?.service_provider
                                  ? `${selectedSession?.service_provider.last_name} ${selectedSession?.service_provider.first_name}`
                                  : t("select_mediator")}
                              </option>
                            </Select>
                          </Box>

                          <Box width={"50%"}>
                            <FormLabel display={"flex"}>
                              {t("supporting_mediator")}
                              {/* <Text ml={1} color={"#EE0000"}>*</Text> */}
                            </FormLabel>
                            <Select
                              bg={"gray.100"}
                              className="form-control"
                              isRequired={true}
                              fontSize={"14px"}
                              fontWeight={"400"}
                              borderRadius="4px"
                              value={selectedSession?.supporting_service_provider ? selectedSession?.supporting_service_provider.id : ""}
                              onChange={async (e) => {
                                const newValue = e.target.value;
                                console.log(newValue);
                                setSelectedSession({
                                  ...selectedSession,
                                  supporting_service_provider: newValue,
                                });
                                await updateRdvSession({
                                  payload: {
                                    supporting_service_provider: newValue,
                                  },
                                });
                                toast.success(
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FaCheck style={{ marginRight: '8px' }} />
                                    {t("messages.supporting_mediator_updated")}
                                  </div>,
                                  {
                                    position: "bottom-center",
                                    style: {
                                      backgroundColor: "#FF6666",
                                      color: "white",
                                      width: 'auto',
                                      minWidth: '400px'
                                    },
                                    icon: false
                                  }
                                );
                              }}
                            >
                              {/* Placeholder option to show "No Supporting Provider" */}
                              <option value="" disabled={!selectedSession?.supporting_service_provider}>
                                {t("no_supporting_provider_selected")}
                              </option>

                              {collaboratorsData
                                ?.filter((med) => med.id !== selectedSession?.service_provider.id) // Replace 'specificId' with the ID you want to exclude
                                .map((med) => (
                                  <option key={med.id} value={med.id}>
                                    {med.first_name} {med.last_name}
                                  </option>
                                ))}

                            </Select>
                          </Box>

                        </Flex>
                      </FormControl>


                      <FormControl mb={4} isDisabled={appointmentData.is_closed} width={"100%"}>
                        <Flex width={"100%"} gap={4}>
                          <Box width={"50%"}>
                            <FormLabel display="flex">
                              {t("date")}
                              <Text ml={1} color="#EE0000">*</Text>
                            </FormLabel>
                            <Input
                              bg={"gray.100"}
                              id="appointmentDate"
                              name="appointmentDate"
                              isRequired={true}
                              fontSize="14px"
                              fontWeight="400"
                              borderRadius="4px"
                              cursor={'not-allowed'}
                              mr={2}
                              value={selectedSession?.calendar_block ? selectedSession?.calendar_block.start_date.split('T')[0] : ''}
                              isReadOnly={true}
                            />
                          </Box>

                          <Box width={"50%"}>
                            <FormLabel display="flex">
                              {t("start_time")}
                              <Text ml={1} color="#EE0000">*</Text>
                            </FormLabel>
                            <Input
                              bg={"gray.100"}
                              id="appointmentTime"
                              name="appointmentTime"
                              type="text"
                              cursor={'not-allowed'}
                              fontSize="14px"
                              fontWeight="400"
                              borderRadius="4px"
                              value={selectedSession?.calendar_block
                                ? `${Utils.convertUTCToLocalTimeZone(selectedSession.calendar_block.start_date.split('T')[1].slice(0, -4))}h`
                                : " "}
                              isReadOnly={true}
                            />
                          </Box>
                        </Flex>
                      </FormControl>
                    </Flex>

                    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" mt={6}>
                      <Flex justify="space-between" p={4} borderBottomWidth="1px">
                        <Text fontSize="2xl" fontWeight="semibold">{t("admin__appointments_table__participants")}</Text>
                        <Button
                          fontSize={"16px"}
                          fontWeight={"500"}
                          marginLeft={3}
                          marginY={"auto"}
                          color={"#FF6666"}
                          bg={"none"}
                          onClick={() => openModal()}
                          isDisabled={selectedSession?.status === 'Cancelled' || selectedSession?.status === 'Late Cancel' || selectedSession?.status === 'Occurred'}
                        >
                          {t("admin__appointments_table__add_participant")}
                        </Button>
                      </Flex>

                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__name")}</Th>
                            {/* <Th sx={tableHeaderStyles}>{t("admin__appointments_table__status")}</Th> */}
                            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__email")}</Th>
                            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__telephone")}</Th>
                            {/* <Th sx={tableHeaderStyles}>{t("admin__appointments_table__city")}</Th> */}
                            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__average_monthly_income")}</Th>
                            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__amount_per_session")}</Th>
                            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__regime")}</Th>
                            <Th sx={tableHeaderStyles}>{t("actions")}</Th>
                            <Th sx={tableHeaderStyles}>{t("documents")}</Th> {/* New column for document actions */}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {appointmentData.participants.length === 0 ? (
                            <Tr>
                              <Td colSpan={10} textAlign="left">
                                <Text fontSize="18px" color="red.500">{t("no_participant_added")}</Text>
                              </Td>
                            </Tr>
                          ) : (
                            appointmentData.participants.map((participant, index) => (
                              <Tr key={index}>
                                <Td sx={tableContentStyles}>{participant?.name}</Td>
                                {/* <Td sx={tableContentStyles}>
                                  {participant.status && t(`family_relation__${participant.status.charAt(0).toLowerCase() + participant.status.slice(1)}`)}
                                </Td> */}
                                <Td sx={tableContentStyles}>{participant?.email}</Td>
                                <Td sx={tableContentStyles}>{participant?.phone}</Td>
                                {/* <Td sx={tableContentStyles}>{participant.city}</Td> */}
                                <Td sx={tableContentStyles}>{participant?.average_monthly_income ? `${participant?.average_monthly_income}Є` : ""}</Td>
                                <Td sx={tableContentStyles}>{participant?.amount_per_session ? `${participant?.amount_per_session}Є` : ""}</Td>
                                <Td sx={tableContentStyles}>{participant?.regime === 'AUTRE' ? t('autre') : participant?.regime}</Td>
                                <Td sx={tableContentStyles}>
                                  <Flex justify="space-evenly">
                                    {!selectedSession?.participants.map(p => p.id).includes(participant.id) ? (
                                      <Tooltip label={t("invite_participants")}>
                                        <IconButton
                                          aria-label={t("invite_participants")}
                                          icon={<FaUserPlus />}
                                          size="md"
                                          color="black"
                                          bg={'none'}
                                          isDisabled={selectedSession?.status !== "Created" && selectedSession?.status !== "Scheduled" && selectedSession?.status !== "Postponed" || !participant.email}
                                          onClick={() =>
                                            openDialog(
                                              t("confirm_invitation"),
                                              t("confirm_invitation__body"),
                                              "inviteParticipant",
                                              () => {
                                                console.log("Action Confirmed!");
                                                handleInviteParticipant(participant);
                                              },
                                              () => {
                                                console.log("Closed without confirming.");
                                              }
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip label={t("already_invited")}>
                                        <IconButton
                                          aria-label={t("already_invited")}
                                          icon={<FaCheckCircle />}
                                          size="md"
                                          color="black"
                                          cursor="not-allowed"
                                          bg={'none'}
                                        />
                                      </Tooltip>
                                    )}
                                    <IconButton
                                      aria-label="Edit participant"
                                      icon={<EditIcon />}
                                      size="md"
                                      mr={2}
                                      bg={'none'}
                                      isDisabled={
                                        selectedSession?.status === 'Cancelled' ||
                                        selectedSession?.status === 'Late Cancel' ||
                                        selectedSession?.status === 'Occurred'
                                      }
                                      onClick={() => openModal(participant)}
                                    />
                                    <IconButton
                                      aria-label="Remove participant"
                                      icon={<DeleteIcon />}
                                      size="sm"
                                      colorScheme="red"
                                      isDisabled={
                                        selectedSession?.status === 'Cancelled' ||
                                        selectedSession?.status === 'Late Cancel' ||
                                        selectedSession?.status === 'Occurred'
                                      }
                                      onClick={() =>
                                        openDialog(
                                          t("messages.removeParticipantConfirmation"),
                                          t("messages.removeParticipantWarning"),
                                          "removeParticipant",
                                          () => {
                                            console.log("Action Confirmed!");
                                            handleRemoveParticipant(participant);
                                          },
                                          () => {
                                            console.log("Closed without confirming.");
                                          }
                                        )
                                      }
                                    />
                                  </Flex>
                                </Td>
                                <Td sx={tableContentStyles}>
                                  <Flex justify="space-evenly">
                                    <Td sx={tableContentStyles}>
                                      <Flex justify="space-evenly">
                                        <AttestationPaymentActions
                                          participant={participant}
                                          selectedSession={selectedSession}
                                          onStateChange={onStateChange}
                                        />
                                      </Flex>
                                    </Td>

                                  </Flex>
                                </Td>
                              </Tr>
                            ))
                          )}
                        </Tbody>
                      </Table>
                    </Box>


                  </TabPanel>

                  <TabPanel>
                    <SessionCommantsTab
                      data={selectedSession}
                      accessToken={accessToken}
                      onStateChange={onStateChange}
                    />
                  </TabPanel>
                  <TabPanel>
                    {collaboratorsData ? (
                      <DocumentsTab
                        collaboratorsData={collaboratorsData}
                        sessionId={selectedSession?.id}
                        accessToken={accessToken}
                        documents={selectedSession?.documents}
                        liftStateUp={() => onStateChange()}
                      />
                    ) : (
                      <DocumentsTab
                        collaboratorsData={[]}
                        sessionId={selectedSession?.id}
                        accessToken={accessToken}
                        documents={selectedSession?.documents}
                        liftStateUp={() => onStateChange()}
                      />
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </Grid>
        </>
      )}

      <GenericAlertDialog
        isOpen={isOpen}
        title={alertDialogTitle}
        body={alertDialogBody}
        onClose={handleClose}
        confirm={handleConfirm}
      />
      {selectedSession && (
        <>{isPostponedSessionDialogOpen ?
          <PostponedSessionDialog
            isOpen={isPostponedSessionDialogOpen}
            onClose={() => setIsPostponedSessionDialogOpen(false)}
            accessToken={accessToken}
            originalRdvId={appointmentData?.id}
            liftStateUp={() => onStateChange()}
            availableLocations={availableLocations}
            serviceId={appointmentData?.service?.id}
            selectedSession={selectedSession}
            association={association}
          />
          : ''} </>
      )}
      {selectedSession && (
        <>{isCancelReasonModalOpen ?
          <CancelReasonModal
            isOpen={isCancelReasonModalOpen}
            onClose={() => setIsCancelReasonModalOpen(false)}
            onConfirm={handleCancelConfirmation}
          /> : ''}</>
      )}

      {isModalOpen ?
        <ParticipantModal
          isOpen={isModalOpen}
          onClose={onModalClose}
          initialRef={initialParticipantRef}
          finalRef={finalParticipantRef}
          addParticipant={handleAddParticipant}
          editParticipant={handleEditParticipant}
          participant={currentParticipant}
        /> : ''}
    </Box>
  );
};

export default function Sessions({
  collaboratorsData,
  appointmentData,
  accessToken,
  onStateChange,
  association
}) {
  return (
    <CSessions
      collaboratorsData={collaboratorsData}
      appointmentData={appointmentData}
      accessToken={accessToken}
      onStateChange={onStateChange}
      association={association}
    />
  );
}
