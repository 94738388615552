import {
  Avatar,
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

function TopCreatorTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify="space-between"
          w="100%"
          px="22px"
          pb="20px"
          mb="10px"
          boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
        >
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Service Providers
          </Text>
          <Button variant="action">See all</Button>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor="transparent"
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);

              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (
                      cell.column.Header === "LinkedIn" ||
                      cell.column.Header === "Facebook" ||
                      cell.column.Header === "Instagram"
                    ) {
                      // Return Link with external link icon
                      data = (
                        <Button
                          variant="link"
                          colorScheme="brandScheme"
                          size="sm"
                          href={cell.value}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {cell.column.Header}
                        </Button>
                      );
                    } else if (cell.column.Header === "Availability") {
                      // Return a radio button for Availability
                      data = (
                        <Box>
                          <Button
                            variant="action"
                            size="sm"
                            // Red if not available, Green if available
                            colorScheme={cell.value ? "green" : "red"}
                            // On click, change the availability
                            onClick={() => {
                              // Mock API call
                              console.log(
                                `TODO: Change availability for ${cell.row.original.email}`
                              );
                            }}
                          >
                            {cell.value ? "Available" : "Not Available"}
                          </Button>
                        </Box>
                      );
                    } else if (cell.column.Header === "Rating") {
                      data = (
                        <Box>
                          <Progress
                            variant="table"
                            colorScheme="brandScheme"
                            value={cell.value}
                          />
                        </Box>
                      );
                    } else {
                      // Return the cell value as is in Text
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize="sm"
                          fontWeight="500"
                        >
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
    </>
  );
}

export default TopCreatorTable;
