import React from "react";
import { Tr, Td, Skeleton } from "@chakra-ui/react";

const TableSkeleton = ({ numCols, numRows = 5 }) => {
  return (
    <>
      {Array.from({ length: numRows }).map((_, rowIndex) => (
        <Tr key={rowIndex}>
          {Array.from({ length: numCols }).map((_, colIndex) => (
            <Td key={colIndex}>
              <Skeleton height="20px" />
            </Td>
          ))}
        </Tr>
      ))}
    </>
  );
};

export default TableSkeleton;
