import React from "react";
import {
  Flex,
  Input,
  Box,
  Select,
  Button,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

const selectStyles = {
  backgroundColor: "rgba(86, 86, 86, 15%)",
  borderRadius: "12px",
  color: "gray.900",
  marginLeft: "10px",
};

const SearchAndFilterMediators = ({
  searchQuery,
  setSearchQuery,
  handleSearch,
}) => {
  const { t } = useTranslation();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <Box
        marginY={"10px"}
        width={"100%"}
        border="1px"
        borderColor="gray.300"
        borderRight={"none"}
        paddingY={2}
      >
        <Flex alignItems={"center"} justifyContent="">
          <SearchIcon fontSize={"2xl"} marginX={4} />
          <Input
            placeholder={t("mediationTab.search_placeholder")}
            style={{ width: "60%" }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Box width={"10%"}></Box>
        </Flex>
      </Box>
      <Box
        width={"100%"}
        // border="1px"
        borderColor="gray.300"
        borderRight={"none"}
        paddingY={2}
        borderTop={"none"}
      >
      </Box>
    </>
  );
};

export default SearchAndFilterMediators;
