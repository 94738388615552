import axios from 'axios';
import axiosInstance from '../axiosConfig';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary/rdv`;
const API_URL_gen = `${process.env.REACT_APP_API_BASE_URL}collaboration-api`;

const rdvService = {
  getAll: async (accessToken, limit = 10, offset = 0) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          limit,
          offset,
        },
      });
      console.log({ getAll: response.data });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },

  get: async ({ pk, accessToken }) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/${pk}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error
    }
  },

  new: async (accessToken, formData) => {
    try {
      const response = await axiosInstance.post(`${API_URL.replace("/secretary", "")}/create/`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request to add new rdv failed. Please check your credentials.");

    }
  },

  update: async ({ pk, obj, accessToken }) => {
    try {
      const response = await axiosInstance.patch(`${API_URL}/${pk}/`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  addComment: async ({ pk, obj, accessToken }) => {
    try {
      console.log('calling adding a new comment');
      const response = await axiosInstance.post(`${API_URL}/${pk}/comment/`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });


      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },
  updateDoc: async ({ pk, payload, docId }) => {
    try {
      console.log(`Payload is ${JSON.stringify(payload)}`);
      const response = await axiosInstance.patch(`${API_URL}${pk}/document/${docId}/`, payload);
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Update failed, please log out and try again.");
    }
  },
  updateParticipantInfo: async ({ rdvId, participantId, obj, accessToken }) => {
    try {
      const response = await axiosInstance.patch(`${API_URL}/${rdvId}/participant/${participantId}`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },

  updateChildInfo: async ({ rdvId, childId, obj, accessToken }) => {
    try {
      const response = await axiosInstance.patch(`${API_URL}/${rdvId}/child/${childId}`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },

  deleteParticipant: async ({ pk, updatedParticipants, accessToken }) => {
    console.log("Updated Participants: ", updatedParticipants);

    const response = await axiosInstance.patch(
      `${API_URL}/${pk}/participant/delete`,
      updatedParticipants,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    console.log("Response: ", response);

    if (response.status !== 200) {
      console.log("Throwing error | SERVICE");
      throw new Error(response.data);
    }
    return {
      data: response.data,
    };
  },

  deleteDoc: async (rdvId, docId) => {
    try {

      const res = await axiosInstance.delete(`${API_URL}/${rdvId}/document/${docId}/`);
      return {
        data: res.data
      }
    }
    catch (error) {
      throw new Error('Failed to delete the session doc');
    }
  },
  updateDoc: async ({ pk, payload, docId }) => {
    try {

      const response = await axiosInstance.patch(`${API_URL}/${pk}/document/${docId}/`, payload);

      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Update failed, please log out and try again.");
    }
  },
  addParticipant: async ({ pk, participant, accessToken }) => {
    participant['association'] = '1';
    try {
      const response = await axiosInstance.post(
        `${API_URL}/${pk}/participant`,
        participant,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },

  addChild: async ({ pk, child, accessToken }) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/${pk}/child/`, child, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Request failed. Please check your credentials.");
    }
  },

  softDelete: ({ rdv_id }) => {
    throw new Error("Not implemented");
  },

  search: async (accessToken, query, limit = 10, offset = 0) => {
    try {
      console.log('search query is');
      console.log(query);
      console.log(`${API_URL}/${query}`);
      const response = await axiosInstance.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { ...query, limit, offset },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Search failed. Please check your credentials.");
    }
  },

  filter: async (accessToken, query, limit = 10, offset = 0) => {
    console.log('search query is');
    console.log(query);
    try {
      const response = await axiosInstance.get(`${API_URL}/`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: { ...query, limit, offset },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error
    }
  },
  searchDocuments: async (accessToken, keyword, appointment_id, session_id) => {
    try {
      const response = await axiosInstance.get(`${API_URL.slice(0, -4)}/document/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { keyword, appointment_id, session_id },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Document search failed. Please check your credentials.");
    }
  },

  getServices: async (accessToken, slug) => {
    try {
      // Base URL
      let url = `${API_URL_gen}/service/?limit=100&offset=0`;

      // Append slug if it is available
      if (slug) {
        url = `${API_URL_gen}/${slug}/service/?limit=100&offset=0`;
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return {
        data: response.data,
      }
    } catch (error) {
      // throw new Error("Availability failed. Please check your credentials.");
      return { error: error.response || error.message };
    }
  },
  getConsultationMode: async (accessToken, slug) => {
    try {
      // Base URL
      let url = `${API_URL_gen}/cithea/consultation-mode/`;

      // Append slug if it is available
      if (slug) {
        url = `${API_URL_gen}/${slug}/consultation-mode/`;
      }

      // Make the API call
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      // Return the data received from the API
      return {
        data: response.data,
      };
    } catch (error) {
      // Handle error appropriately
      return { error: error.response || error.message };
    }
  },
};

export default rdvService;
