export const tableColumnsTopCreators = [
  {
    Header: "First Name",
    accessor: "first_name",
  },
  {
    Header: "Last Name",
    accessor: "last_name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Designation",
    accessor: "desgination",
  },
  {
    Header: "Consultation Duration",
    accessor: "consultation_duration",
  },
  {
    Header: "Consultation Fee",
    accessor: "consultation_fee",
  },
  {
    Header: "Location(s)",
    accessor: "locations",
  },
  {
    Header: "Availability",
    accessor: "is_consultation_possible",
  },
  {
    Header: "Service(s)",
    accessor: "services",
  },
  {
    Header: "About",
    accessor: "about",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Country Code",
    accessor: "country_code",
  },
  {
    Header: "Phone Number",
    accessor: "phone_number",
  },
  {
    Header: "LinkedIn",
    accessor: "linkedin_url",
  },
  {
    Header: "Facebook",
    accessor: "facebook_url",
  },
  {
    Header: "Instagram",
    accessor: "instagram_url",
  },
];
