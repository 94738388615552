import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import authService from "../services/authService";
import { logout, loginSuccess } from "../actions/authActions";
import {
  selectUser,
  selectAccessToken,
  selectAssociation,
  selectLocations,
  selectIsAuthenticated,
} from "selectors/authSelector";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/sign-in" />
        )
      }
    />
  );
};

export default ProtectedRoute;
