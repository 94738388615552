import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ChakraProvider, Spinner, Center } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { Provider } from "react-redux";
import { store, persistor } from "store";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import ProtectedRoute from "./components/ProtectedRoute";
import routes from "./routes";
// require('dotenv').config();

// Sentry imports
import * as Sentry from "@sentry/react";

// Initialize Sentry
Sentry.init({
  dsn: "__DSN__", // Replace with your actual DSN

});

const container = document.getElementById("root");
const root = createRoot(container);

console.log("main entry point of js Application");
console.log(process.env);

// Create a loading component with Chakra UI Spinner
const LoadingSpinner = () => (
  <Center height="100vh">
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  </Center>
);

root.render(
    <ChakraProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <React.StrictMode>
              <ThemeEditorProvider>
                <HashRouter>
                  <Suspense fallback={<LoadingSpinner />}>
                    <Switch>
                      {routes.map((route) =>
                        route.isPublic ? (
                          <Route
                            key={route.path}
                            path={`${route.layout}${route.path}`}
                            component={route.component}
                            exact
                          />
                        ) : (
                          <ProtectedRoute
                            key={route.path}
                            path={`${route.layout}${route.path}`}
                            component={route.component}
                            exact
                          />
                        )
                      )}
                      <Redirect from="/" to="/admin/default" />
                    </Switch>
                  </Suspense>
                </HashRouter>
              </ThemeEditorProvider>
            </React.StrictMode>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </ChakraProvider>
);
