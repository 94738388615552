import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Flex,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Spinner,
  Skeleton,
  Select,
  IconButton,
  Image,
  Text,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  Modal,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FaCheck, FaTimes } from "react-icons/fa";
import { debounce } from "lodash";
import rdvService from "services/rdvService";
import Card from "components/card/Card";
import SearchAndFilter from "./components/SearchAndFilter";
import NewRequestForm from "./components/NewRequestForm";
import { useHistory } from "react-router-dom";
import BrandHeader from "components/menu/BrandHeader";
import { connect, useSelector, useDispatch } from "react-redux";
import TableSkeleton from "components/TableSkeleton";
import {
  selectUser,
  selectAccessToken,
  selectAssociation,
  selectLocations,
  selectIsAuthenticated,
} from "selectors/authSelector";
import { logout } from "actions/authActions";
import {
  getData,
  getError,
  getLoading,
} from "../../../selectors/collaboratorSelector";
import { fetchData } from "../../../actions/collaboratorActions.js";
import Utils from "services/Utils";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import i18n from "i18n";
import ToFirstPage from "../../../assets/img/dashboards/first.svg";
import ToLastPage from "../../../assets/img/dashboards/last.svg";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  setSearchQuery,
  setFilterQuery,
  setFromDate,
  setToDate,
  setSearchType,
} from "../../../reducers/searchFilterReducer";

const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};

function Primer({ text }) {
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text fontSize="lg" fontFamily="heading" fontWeight="500">
          {text}
        </Text>
      </Flex>
    </Card>
  );
}

const CUserReports = ({ user, accessToken, association }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const collaboratorData = useSelector(getData);
  const loading = useSelector(getLoading);
  const error = useSelector(getError);
  const locationsList = useSelector(selectLocations);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const searchType = useSelector((state) => state.searchFilter.searchType);
  const searchQuery = useSelector((state) => state.searchFilter.searchQuery);
  const filterQuery = useSelector((state) => state.searchFilter.filterQuery);
  const fromDate = useSelector((state) => state.searchFilter.fromDate);
  const toDate = useSelector((state) => state.searchFilter.toDate);
  const [appointmentIsLoading, setAppointmentIsLoading] = useState(true);
  const [appointmentsHasError, setAppointmentsHasError] = useState(null);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const [isErrorHandled, setIsErrorHandled] = useState(false);

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const formatDateString = (date) => {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };

  const fetchAppointments = useCallback(() => {
    // console.log({ filterQuery });
    // console.log({ searchType });
    setAppointmentIsLoading(true);
    setIsFiltering(false);
    const query = {
      ...filterQuery,
      [searchType]: searchQuery,
      from: fromDate ? formatDateString(fromDate) : "1970-01-01",
      to: toDate
        ? formatDateString(toDate)
        : new Date().toISOString().split("T")[0],
    };

    rdvService
      .filter(accessToken, query, limit, offset)
      .then((res) => {
        setAppointmentsData(res.data.results);
        setTotalResults(res.data.count);
      })
      .catch((err) => {
        // Check if it's a 401 error and if the token is invalid
        console.log("err", err)

        if (err.response && err.status === 401) {
          const errorCode = err.response.data?.code;
          // alert("the code");
          if (errorCode === "token_not_valid") {
            console.error("Token expired or invalid, logging out");
            toast.error(`${t('session_expired_error_msg')}`, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              theme: "colored",
            });

            // Log out the user and redirect to the sign-in page
            setTimeout(() => {
              dispatch(logout());
              history.push("/auth/sign-in");
            }, 2000);
            setAppointmentsData([]);
            setTotalResults(0);
          }
        } else {
          console.error("Error fetching appointments", err);
          setAppointmentsHasError("An error occurred. Please try again.");
        }

        setAppointmentsData([]);
        setTotalResults(0);
      })
      .finally(() => {
        setAppointmentIsLoading(false);
      });
  }, [
    accessToken,
    limit,
    offset,
    filterQuery,
    searchQuery,
    searchType,
    fromDate,
    toDate,
    dispatch,
    history,
  ]);

  const debouncedFetchAppointments = useCallback(
    debounce(fetchAppointments, 300),
    [fetchAppointments],
  );

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/auth/sign-in");
    }
  }, [isAuthenticated, history]);

  const handleResetSearchAndFilter = useCallback(() => {
    dispatch(setSearchType("participant_name"));
    dispatch(setSearchQuery(""));
    dispatch(setFilterQuery({ status: "", location: "", department: '', principal_mediator: '', session_mediator: '' }));
    dispatch(setFromDate(null));
    dispatch(setToDate(null));
    setOffset(0);
    setIsErrorHandled(false);
    fetchAppointments();
  }, [dispatch, fetchAppointments]);

  const handleSubmitNewRequest = async (formData) => {
    let info = {
      association: association?.id ? association?.id : '1',
      location: formData.location,
      date: formData.date,
      time: formData.time,
      service: formData.service,
      language: i18n.language.replace("-US", ""),
      additional_info: formData.additionalDetails,
      tentative_availability_slot: formData.tentative_availability_slot,
      scheduled_by_secretary: true,
      participants: [
        {
          name: `${formData.name}`,
          status: `${formData.status}`,
          sex: `${formData.sex}`,
          city: `${formData.city}`,
          email: `${formData.email}`,
          phone: `${formData.phone}`,
        },
        {
          name: `${formData.additionalParticipant1}`,
        },
        {
          name: `${formData.additionalParticipant2}`,
        },
      ].filter((participant) => participant.name.trim() !== ""),
    };
    // console.log(info);
    try {
      let res = await rdvService.new(accessToken, info);
      toast.success(`${t("toast.successfull_new_request")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: false,
        theme: "colored",
      });
      handleClose();
    } catch (error) {
      toast.error(`${t("toast.failed_new_request")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: false,
        theme: "colored",
      });
    }
  };

  const handleNextPage = useCallback(() => {
    setOffset((prevOffset) => prevOffset + limit);
  }, [limit]);

  const handlePreviousPage = useCallback(() => {
    setOffset((prevOffset) => Math.max(prevOffset - limit, 0));
  }, [limit]);

  const handleFirstPage = useCallback(() => {
    setOffset(0);
  }, []);

  const handleLastPage = useCallback(() => {
    const lastPageOffset = Math.floor(totalResults / limit) * limit;
    setOffset(lastPageOffset);
  }, [totalResults, limit]);

  const handleClose = useCallback(() => {
    onClose();
    fetchAppointments();
  }, [onClose, fetchAppointments]);

  useEffect(() => {
    debouncedFetchAppointments();
    return debouncedFetchAppointments.cancel;
  }, [debouncedFetchAppointments]);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  useEffect(() => {
    debouncedFetchAppointments();
    return debouncedFetchAppointments.cancel;
  }, [
    limit,
    offset,
    filterQuery,
    searchQuery,
    searchType,
    fromDate,
    toDate,
    debouncedFetchAppointments,
  ]);

  const renderContent = () => {
    if (appointmentsHasError) {
      return <Primer text={`Error: ${error}`} />;
    }

    return (
      <>
        <SearchAndFilter
          searchType={searchType}
          setSearchType={(type) => dispatch(setSearchType(type))}
          searchQuery={searchQuery}
          setSearchQuery={(query) => dispatch(setSearchQuery(query))}
          filterQuery={filterQuery}
          setFilterQuery={(query) => dispatch(setFilterQuery(query))}
          handleSearch={debouncedFetchAppointments}
          handleFilter={debouncedFetchAppointments}
          locationsList={locationsList}
          handleResetSearchAndFilter={handleResetSearchAndFilter}
          fromDate={fromDate}
          setFromDate={(date) => dispatch(setFromDate(date))}
          toDate={toDate}
          setToDate={(date) => dispatch(setToDate(date))}
        />

        {appointmentIsLoading ? (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__status")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__file_number")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__location")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__service")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__proposed_date")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__proposed_time")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__requested_at")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__number_of_sessions")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__participants")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__legal_context")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <TableSkeleton numCols={10} />
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__status")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__file_number")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__location")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__service")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__proposed_date")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__proposed_time")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__requested_at")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__number_of_sessions")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__participants")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__legal_context")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {isFiltering ? (
                  Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <Tr key={index}>
                        {Array(10)
                          .fill(0)
                          .map((_, cellIndex) => (
                            <Td key={cellIndex}>
                              <Skeleton height="20px" />
                            </Td>
                          ))}
                      </Tr>
                    ))
                ) : appointmentsData.length === 0 ? (
                  <Tr>
                    <Td colSpan={10}>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="100px"
                      >
                        <Text
                          fontSize="lg"
                          fontWeight="medium"
                          color={"red.500"}
                        >
                          {t("admin__main__no_results_found")}
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                ) : (
                  appointmentsData.map((appointment, index) => (
                    <Tr
                      key={index}
                      onClick={() =>
                        history.push(
                          `/admin/appointment-checkin/${appointment?.id}`,
                        )
                      }
                      cursor={"pointer"}
                      _hover={{
                        bg: "gray.200",
                      }}
                    >
                      <Td>{AppointmentStatusLabel(appointment, t)}</Td>
                      <Td sx={tableContentStyles}>
                        {appointment?.file_number ?? "N/A"}
                      </Td>
                      <Td
                        sx={tableContentStyles}
                        style={{
                          maxWidth: "250px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {appointment?.location?.name || 'N/A'}
                      </Td>
                      <Td sx={tableContentStyles}>
                        {t(`${appointment?.service?.name || 'N/A'}`)}
                      </Td>
                      <Td sx={tableContentStyles}>
                        {`${Utils.convertUTCToLocalDate(appointment?.date)}` || "N/A"}
                      </Td>
                      <Td sx={tableContentStyles}>
                        {`${Utils.convertUTCToLocalTimeZone(appointment.time)}` || "N/A"}
                      </Td>
                      <Td sx={tableContentStyles}>
                        {`${Utils.convertUTCToLocalDate(appointment.created_at)}, ${Utils.convertUTCToLocalTimeZone(new Date(appointment.created_at).toISOString().split("T")[1].slice(0, 5))}`}

                        {/* {new Date(appointment.created_at)
                          .toLocaleString("fr-FR")
                          .replaceAll("/", "-")
                          .slice(0, -3)} */}
                      </Td>
                      <Td sx={tableContentStyles}>
                        {appointment?.rdv_sessions?.length}
                      </Td>
                      <Td sx={tableContentStyles}>
                        {appointment?.participants?.length}
                      </Td>
                      <Td sx={tableContentStyles}>
                        {appointment?.legal_context
                          ? (() => {
                            if (
                              appointment?.legal_context ===
                              "Double convocation"
                            ) {
                              return t("legal_context__double_convocation");
                            } else if (
                              appointment?.legal_context === "Injonction"
                            ) {
                              return t("legal_context__injonction");
                            } else if (
                              appointment?.legal_context === "Ordonnance"
                            ) {
                              return t("legal_context__ordonnance");
                            } else if (
                              appointment?.legal_context ===
                              "Cadre judiciaire sur invitation écrite de la Juridiction"
                            ) {
                              return t("legal_context__cadre_judiciaire");
                            } else if (
                              appointment?.legal_context === "TMFPO"
                            ) {
                              return t("legal_context__tmfpo");
                            } else {
                              return appointment?.legal_context;
                            }
                          })()
                          : "N/A"}
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Flex justifyContent="flex-end" alignItems="center" mt={4}>
          <Text mr={4}>
            {t("Rows_per_page")}
            <Select
              value={limit}
              onChange={(e) => {
                setLimit(Number(e.target.value));
                setOffset(0);
                debouncedFetchAppointments();
              }}
              display="inline-block"
              width="auto"
              ml={2}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Select>
          </Text>
          <Text mr={4}>
            {`${Math.min(offset + 1, totalResults)}-${Math.min(offset + limit, totalResults)} of ${totalResults}`}
          </Text>
          <Flex>
            <IconButton
              onClick={handleFirstPage}
              disabled={offset === 0}
              icon={<Image src={ToFirstPage} alt="First Page" />}
              aria-label="First Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handlePreviousPage}
              disabled={offset === 0}
              icon={<ChevronLeftIcon boxSize={6} />}
              aria-label="Previous Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleNextPage}
              disabled={offset + limit >= totalResults}
              icon={<ChevronRightIcon boxSize={6} />}
              aria-label="Next Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleLastPage}
              disabled={offset + limit >= totalResults}
              icon={<Image src={ToLastPage} alt="Last Page" />}
              aria-label="Last Page"
              border={"none"}
              bg={"none"}
            />
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <BrandHeader />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={1}
      />
      <Flex
        direction={"column"}
        margin={3}
        border="1px"
        borderColor="gray.300"
        borderRadius={"8px"}
      >
        <Flex justifyContent={"space-between"}>
          <Box
            fontSize={"24px"}
            fontWeight={"500"}
            marginLeft={3}
            marginY={"12px"}
          >
            {t("admin__header__requests")}
          </Box>

          <Button
            background={"none"}
            fontSize={"18px"}
            fontWeight={"500"}
            marginY={"12px"}
            color={"rgb(255, 102, 102)"}
            onClick={onOpen}
          >
            {t(`admin_add_new_request`)}
          </Button>
        </Flex>
        {renderContent()}
      </Flex>

      {isOpen ?
        <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t(`admin_add_new_request`)}</ModalHeader>
            <ModalBody>
              <NewRequestForm
                onSubmit={handleSubmitNewRequest}
                onClose={handleClose}
                association={association}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
        : ''}
    </>
  );
};

function AppointmentStatusLabel(appointment, t) {
  let status = t("admin_status.in_progress");
  let statusColor = "#199CD6";
  let statusBgColor = "#E4F5FC";

  if (appointment.mediator === null) {
    status = t("admin_status_new");
    statusColor = "#FF6666";
    statusBgColor = "#FFEEEE";
  }

  if (appointment.is_closed) {
    status = t("admin_status.completed");
    statusColor = "#9FB80A";
    statusBgColor = "#F7F9ED";
  }

  return (
    <Box
      color={statusColor}
      bgColor={statusBgColor}
      borderRadius={"8px"}
      padding={"4px 8px"}
      textAlign={"center"}
      width={"fit-content"}
    >
      {status}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
  association: selectAssociation(state),
});

const ConnectedUserReports = connect(mapStateToProps)(CUserReports);

export default function UserReports() {
  return <ConnectedUserReports />;
}
