// src/actions/rdvActions.js
export const SET_SELECTED_RDV = 'SET_SELECTED_RDV';
export const UPDATE_SELECTED_RDV = 'UPDATE_SELECTED_RDV';

export const setSelectedRDV = (rdv) => ({
  type: SET_SELECTED_RDV,
  payload: rdv,
});

export const updateSelectedRDV = (updates) => ({
  type: UPDATE_SELECTED_RDV,
  payload: updates,
});
