export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_FILTER_QUERY = 'SET_FILTER_QUERY';
export const SET_FROM_DATE = 'SET_FROM_DATE';
export const SET_TO_DATE = 'SET_TO_DATE';

export const setSearchType = (searchType) => ({
  type: SET_SEARCH_TYPE,
  payload: searchType,
});

export const setSearchQuery = (query) => ({
  type: SET_SEARCH_QUERY,
  payload: query,
});

export const setFilterQuery = (query) => ({
  type: SET_FILTER_QUERY,
  payload: query,
});

export const setFromDate = (date) => ({
  type: SET_FROM_DATE,
  payload: date,
});

export const setToDate = (date) => ({
  type: SET_TO_DATE,
  payload: date,
});

const initialState = {
  searchType: 'participant_name',
  searchQuery: '',
  filterQuery: { status: '', location: '', department: '', principal_mediator: '', session_mediator: '', },
  fromDate: null,
  toDate: null,
};

 const searchFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.payload,
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case SET_FILTER_QUERY:
      return {
        ...state,
        filterQuery: action.payload,
      };
    case SET_FROM_DATE:
      return {
        ...state,
        fromDate: action.payload,
      };
    case SET_TO_DATE:
      return {
        ...state,
        toDate: action.payload,
      };
    default:
      return state;
  }
};


export default searchFilterReducer;
