import React from "react";
import ReactDOM from "react-dom";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const CustomModal = ({ isOpen, onClose, children }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '500px',
        width: '100%',
      }}>
        {children}
          <Button
            onClick={onClose}
            fontSize={"18px"}
            borderRadius={"4px"}
            ml={2}
            mx={"auto"}
          >
            {t("cancel")}
          </Button>
      </div>
    </div>,
    document.body
  );
};

export default CustomModal;
