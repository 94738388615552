import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  FormErrorMessage,
  Text,
  InputGroup,
  InputRightElement,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import { FaCalendarAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FaEnvelope, FaFileAlt, FaTicketAlt, FaUserPlus, FaCheckCircle, FaCheck, FaTimes } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import rdvSessionService from "services/rdvSessionService";
import rdvService from "services/rdvService";
import { enUS, fr } from "date-fns/locale";
import { startOfMonth, endOfMonth, subMonths, addMonths } from "date-fns";
import Utils from "services/Utils";

const CPostponedSessionDialog = ({
  isOpen,
  onClose,
  accessToken,
  originalRdvId,
  liftStateUp,
  availableLocations,
  selectedSession,
  serviceId,
  association
}) => {
  const { t, i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [sessionDuration, setSessionDuration] = useState("");
  const [sessionType, setSessionType] = useState("");
  const [location, setLocation] = useState("");
  const [consultationModes, setConsultationModes] = useState("");
  const [mediator, setMediator] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [monthAvailability, setMonthAvailability] = useState({});
  const [disabledDates, setDisabledDates] = useState([]);
  const [postponeNote, setPostponeNote] = useState("Participant unavailable");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [originalStartDate, setOriginalStartDate] = useState("");
  const [originalStartTime, setOriginalStartTime] = useState("");
  const [originalMediator, setOriginalMediator] = useState("");
  const [consultationModesList, setConsultationModesList] = useState([]);
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);
  const datePickerRef = useRef();



  useEffect(() => {
    if (isOpen && selectedSession) {
      const originalDate = selectedSession?.calendar_block?.start_date.split("T")[0] || "";
      const originalTime = selectedSession?.calendar_block?.end_date.split("T")[0] || "";
      const originalMediator = `${selectedSession?.service_provider?.id}` || "";

      setTitle(selectedSession?.calendar_block?.title || "");
      setStartDate(originalDate);
      setOriginalStartDate(originalDate);
      setStartTime(originalTime);
      setOriginalStartTime(originalTime);
      setSessionDuration(selectedSession?.calendar_block?.duration || "");
      setSessionType(selectedSession?.type || "");
      setLocation(selectedSession?.location || "");
      setMediator(originalMediator);
      setOriginalMediator(originalMediator);
      fetchMonthAvailability(selectedSession?.location, serviceId, originalDate);
    }
  }, [isOpen, selectedSession, serviceId, accessToken]);

  useEffect(() => {
    const originalDate = selectedSession?.calendar_block?.start_date.split("T")[0] || "";
    fetchMonthAvailability(selectedSession?.location, serviceId, originalDate);
  }, [isOpen, selectedSession, sessionType, accessToken]);

  const isUnchanged = originalStartDate === startDate && originalStartTime === startTime && originalMediator === mediator;

  useEffect(() => {
    if (timeSlots.length > 0) {
      const firstSlot = timeSlots[0];
      setStartTime(firstSlot.start_time);
      setMediator(firstSlot.service_provider.id.toString());
      setSelectedSlot(firstSlot);
    }
  }, [timeSlots]);

  useEffect(() => {
    HandleGetConsultationModeList(association.slug)
    console.log("association", association)
  }, [association])

  const HandleGetConsultationModeList = async (slug) => {

    try {
      const res = await rdvService.getConsultationMode(accessToken, slug);
      console.log("consultation mode response", res.data);

      // Set the selected services only if results exist
      if (Array.isArray(res.data)) {
        setConsultationModesList(res.data);
      } else {
        console.error("No results found in the consultation mode response.");
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  }

  const fetchMonthAvailability = async (locationId, serviceId, date) => {
    const startDate = startOfMonth(subMonths(date, 1))
      .toISOString()
      .split("T")[0];
    const endDate = endOfMonth(addMonths(date, 1)).toISOString().split("T")[0];
    let response

    try {
      // const response = await rdvSessionService.getByMonthAvailabilityWithProviders({
      //   locationId,
      //   serviceId,
      //   fromDate: startDate,
      //   toDate: endDate,
      //   type: sessionType,
      //   accessToken,
      // });


      if (isLocationDisabled) {
        response = await rdvSessionService.getByMonthAvailabilityWithProviders({
          serviceId,
          fromDate: startDate,
          toDate: endDate,
          type: sessionType,
          accessToken
        });
      } else {
        response = await rdvSessionService.getByMonthAvailabilityWithProviders({
          locationId,
          serviceId,
          fromDate: startDate,
          toDate: endDate,
          type: sessionType,
          accessToken
        });
      }

      setMonthAvailability(response || {});

      const emptyDates = Object.entries(response)
        .filter(([_, events]) => events.length === 0)
        .map(([date, _]) => new Date(date));

      setDisabledDates(emptyDates);
    } catch (error) {
      console.error("Error fetching month availability", error);
      setMonthAvailability({});
      setDisabledDates([]);
    }
  };

  const fetchTimeSlots = async (locationId, date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const availableSlots = monthAvailability[formattedDate] || [];
    setTimeSlots(availableSlots);
  };

  const isDateDisabled = (date) => {
    return disabledDates.some(
      (disabledDate) =>
        date.getFullYear() === disabledDate.getFullYear() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getDate() === disabledDate.getDate()
    );
  };

  const handleSlotSelect = (e) => {
    const selectedIndex = parseInt(e.target.value);
    const selectedSlot = timeSlots[selectedIndex];
    if (selectedSlot) {
      setMediator(selectedSlot.service_provider.id.toString());
      setStartTime(selectedSlot.start_time);
      setSelectedSlot(selectedSlot);
    } else {
      setMediator("");
      setStartTime("");
      setSelectedSlot(null);
    }
  };

  const handleSessionTypeChange = (e) => {
    const newType = e.target.value;
    console.log("newType", newType);
    setSessionType(newType)
    // Reset date and time when location changes
    handleDateChange(null);
    setTimeSlots([]);
  }

  const handleConsultationModeChange = (e) => {
    console.log("the consultation modes", e.target.value)

    const selectedMode = consultationModesList.find((mode) => mode.id === Number(e.target.value));
    // Set the disabled state for location based on is_location_agnostic
    setConsultationModes(e.target.value)
    const isLocationAgnostic = selectedMode?.is_location_agnostic || false;
    setIsLocationDisabled(isLocationAgnostic);
    // Reset date and time slots
    setStartDate(null);
    setTimeSlots([]);
    if (isLocationAgnostic) {
      setLocation()
    }
  }

  const handleLocationChange = (e) => {
    const newLocation = e.target.value;
    setLocation(newLocation);

    // Reset date and time slots
    setStartDate(null);
    setTimeSlots([]);

    if (startDate) {
      // Fetch availability for the month if there's a valid start date
      fetchMonthAvailability(newLocation, serviceId, startDate);
    }
  };



  const handleDateChange = (date) => {
    setStartDate(date);

    if (date) {
      // Fetch available time slots for the selected date
      fetchTimeSlots(location, date);
    } else {
      // Reset time slots if no date is selected
      setTimeSlots([]);
    }

  };

  const checkFormValidity = () => {
    const newErrors = {};
    if (title.trim() === "") newErrors.title = t("session_name_required");
    if (!startDate) newErrors.startDate = t("session_date_required");
    if (startTime === "") newErrors.startTime = t("session_time_required");
    if (sessionType === "") newErrors.sessionType = t("session_type_required");
    if (location === "") newErrors.location = t("session_location_required");
    if (mediator === "") newErrors.mediator = t("session_mediator_required");
    if (!selectedSlot) newErrors.startTime = t("no_time_slots_available");
    if (postponeNote === "") newErrors.postponeNote = t("postpone_reason_required");
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    const formIsValid = checkFormValidity();

    if (!formIsValid) {
      return;
    }

    const postponedToDate = moment(startDate).format("YYYY-MM-DD");

    let payload = {
      session_title: title,
      date: postponedToDate,
      type: sessionType,
      service_provider: parseInt(mediator),
      location: parseInt(location),
      tentative_availability_slot: selectedSlot.id,
      status_postpone_reason: postponeNote,
      consultation_mode: parseInt(consultationModes)
    }
    console.log(payload);
    try {
      await rdvSessionService.update({
        pk: selectedSession.id,
        payload,
      });
      await updateRdv();
      toast.success(
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaCheck style={{ marginRight: "8px" }} />
          {t("messages.changesSaved")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: "auto",
            minWidth: "400px",
          },
          icon: false,
        }
      );
      resetForm();
      liftStateUp();
      onClose();
    } catch (error) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("failed_to_postpone")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  };

  const updateRdv = async () => {
    try {
      await rdvService.update({
        pk: originalRdvId,
        obj: {
          mediator: parseInt(mediator),
        },
        accessToken,
      });
    } catch (error) {
      console.error("Error updating RDV", error);
    }
  };

  const resetForm = () => {
    setTitle("");
    setStartDate("");
    setStartTime("");
    setSessionType("");
    setLocation("");
    setPostponeNote("");
    setMediator("");
    setSelectedSlot();
    setErrors({});
    setIsSubmitted();
  };

  const getDateLocale = () => {
    switch (i18n.language) {
      case "fr":
        return fr;
      default:
        return enUS;
    }
  };

  useEffect(() => {
    fetchMonthAvailability(location, serviceId, new Date())
    console.log("dates", disabledDates, location)
  }, [location]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("postpone_session")}</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={isSubmitted && !!errors.title}>
            <FormLabel>{t("name_for_session")} <Text as="span" color="red">*</Text></FormLabel>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t("enter_name")}
              autoFocus
              bg="gray.200"
            />
            <FormErrorMessage>{errors.title}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.sessionType}>
            <FormLabel>{t("select_consultation_mode")} <Text as="span" color="red">*</Text></FormLabel>
            <Select
              value={consultationModes}
              onChange={handleConsultationModeChange}
              bg="gray.200"
            >
              {consultationModesList.map((mode) => (
                <option key={mode.id} value={mode.id}>
                  {i18n.language === 'fr' ? mode.name_fr : mode.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.sessionType}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.sessionType}>
            <FormLabel>{t("select_session_type")} <Text as="span" color="red">*</Text></FormLabel>
            <Select
              value={sessionType}
              onChange={handleSessionTypeChange}
              bg="gray.200"
            >
              <option value="Info">{t("info")}</option>
              <option value="Mediation">{t("mediation")}</option>
            </Select>
            <FormErrorMessage>{errors.sessionType}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.location}>
            <FormLabel>{t("select_location")} <Text as="span" color="red">*</Text></FormLabel>
            <Select value={location} onChange={handleLocationChange} bg="gray.200" disabled={isLocationDisabled}>
              {availableLocations.map((loc) => (
                <option key={loc.id} value={loc.id}>
                  {loc.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.location}</FormErrorMessage>
          </FormControl>

          {/* <FormControl mt={4} isInvalid={isSubmitted && !!errors.startDate}>
            <FormLabel>{t("select_session_date")} <Text as="span" color="red">*</Text></FormLabel>
            <InputGroup
              display="flex"
              border="1px solid #E2E8F0"
              borderRadius="md"
              alignItems="center"
              bg="gray.200"
            >
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                className="chakra-input"
                showPopperArrow={false}
                ref={datePickerRef}
                customInput={
                  <Input
                    variant="unstyled"
                    padding="8px 12px"
                    borderRadius="md"
                    placeholder={t("new_request_form.select_date")}
                  />
                }
                minDate={new Date()}
                filterDate={(date) => !isDateDisabled(date)}
                onMonthChange={(date) =>
                  fetchMonthAvailability(location, serviceId, date)
                }
                locale={getDateLocale()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <InputRightElement
                pointerEvents="all"
                top="50%"
                transform="translateY(-50%)"
                onClick={() => datePickerRef.current.setOpen(true)}
              >
                <Icon as={FaCalendarAlt} color="gray.500" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.startDate}</FormErrorMessage>
          </FormControl> */}
          <FormControl mt={4} isInvalid={isSubmitted && !!errors.startDate}>
            <FormLabel>{t("select_session_date")} <Text as="span" color="red">*</Text></FormLabel>
            <InputGroup
              display="flex"
              border="1px solid #E2E8F0"
              borderRadius="md"
              alignItems="center"
              bg="gray.200"
            >
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                className="chakra-input"
                showPopperArrow={false}
                ref={datePickerRef}
                customInput={
                  <Input
                    variant="unstyled"
                    padding="8px 12px"
                    borderRadius="md"
                    placeholder={t("new_request_form.select_date")}
                  />
                }
                minDate={new Date()}
                filterDate={(date) => !isDateDisabled(date)}
                onMonthChange={(date) =>
                  fetchMonthAvailability(location, serviceId, date)
                }
                locale={getDateLocale()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <InputRightElement
                pointerEvents="all"
                top="50%"
                transform="translateY(-50%)"
                onClick={() => datePickerRef.current.setOpen(true)}
              >
                <Icon as={FaCalendarAlt} color="gray.500" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.startDate}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.startTime}>
            <FormLabel>{t("select_time_slot")} <Text as="span" color="red">*</Text></FormLabel>
            <Select
              value={timeSlots.findIndex((slot) => slot.start_time === startTime)}
              onChange={handleSlotSelect}
              bg="gray.200"
            >
              {timeSlots.length > 0 ? (
                timeSlots.map((slot, index) => (
                  <option key={index} value={index}>
                    {`${Utils.convertUTCToLocalTimeZone(slot.start_time)}`}
                  </option>
                ))
              ) : (
                <option value="">{t("no_time_slots_available")}</option>
              )}
            </Select>
            <FormErrorMessage>{errors.startTime}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.mediator}>
            <FormLabel>{t("select_mediator")} <Text as="span" color="red">*</Text></FormLabel>
            <Select value={mediator} onChange={(e) => setMediator(e.target.value)} bg="gray.200">
              {selectedSlot && mediator ? (
                <option value={selectedSlot.service_provider.id}>
                  {`${selectedSlot.service_provider.first_name} ${selectedSlot.service_provider.last_name}`}
                </option>
              ) : (
                <option value="">{t("no_mediator_found")}</option>
              )}
            </Select>
            <FormErrorMessage>{errors.mediator}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={!!errors.postponeNote}>
            <FormLabel>{t("reason_to_postpone")} <Text as="span" color="red">*</Text></FormLabel>
            <Select
              value={postponeNote}
              onChange={(e) => setPostponeNote(e.target.value)}
              bg="gray.200"
            >
              <option value="Participant unavailable">{t("participant_unavailable")}</option>
              <option value="Location unavailable">{t("location_unavailable")}</option>
              <option value="Mediator unavailable">{t("mediator_unavailable")}</option>
              <option value="Something else">{t("something_else")}</option>
            </Select>
            <FormErrorMessage>{errors.postponeNote}</FormErrorMessage>
          </FormControl>


          <Flex mt={4} justifyContent="flex-end">
            <Button
              colorScheme="red"
              mr={3}
              onClick={handleSubmit}
              isDisabled={isUnchanged}
            >
              {t("postpone_session")}
            </Button>
            <Button variant="outline" onClick={onClose}>
              {t("close")}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default function PostponedSessionDialog({
  isOpen,
  onClose,
  accessToken,
  originalRdvId,
  liftStateUp,
  availableLocations,
  selectedSession,
  serviceId,
  association
}) {
  return (
    <CPostponedSessionDialog
      isOpen={isOpen}
      onClose={onClose}
      accessToken={accessToken}
      originalRdvId={originalRdvId}
      liftStateUp={liftStateUp}
      availableLocations={availableLocations}
      selectedSession={selectedSession}
      serviceId={serviceId}
      association={association}
    />
  );
}
