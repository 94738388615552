// SearchAndFilter.js
import React, { useState, useEffect, useCallback } from "react";
import { Flex, Input, Select, Box } from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import FilterIcon from "../../../../assets/img/dashboards/filter_icon.svg";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import collaboratorService from "services/collaboratorService";

registerLocale("fr", fr);

const selectStyles = {
  backgroundColor: "rgba(86, 86, 86, 15%)",
  borderRadius: "12px",
  color: "gray.900",
  marginLeft: "10px",
};

const datePickerStyles = {
  ...selectStyles,
  padding: "8px 12px",
  border: "none",
  width: "150px",
  marginRight: "10px",
  _placeholder: { color: "black", fontSize: "" },
};

// Import the department codes list you provided
const departmentCodes = [
  { code: "01", name: "Ain" },
  { code: "02", name: "Aisne" },
  { code: "03", name: "Allier" },
  { code: "04", name: "Alpes-de-Haute-Provence" },
  { code: "05", name: "Hautes-Alpes" },
  { code: "06", name: "Alpes-Maritimes" },
  { code: "07", name: "Ardèche" },
  { code: "08", name: "Ardennes" },
  { code: "09", name: "Ariège" },
  { code: "10", name: "Aube" },
  { code: "11", name: "Aude" },
  { code: "12", name: "Aveyron" },
  { code: "13", name: "Bouches-du-Rhône" },
  { code: "14", name: "Calvados" },
  { code: "15", name: "Cantal" },
  { code: "16", name: "Charente" },
  { code: "17", name: "Charente-Maritime" },
  { code: "18", name: "Cher" },
  { code: "19", name: "Corrèze" },
  { code: "2A", name: "Corse-du-Sud" },
  { code: "2B", name: "Haute-Corse" },
  { code: "21", name: "Côte-d'Or" },
  { code: "22", name: "Côtes-d'Armor" },
  { code: "23", name: "Creuse" },
  { code: "24", name: "Dordogne" },
  { code: "25", name: "Doubs" },
  { code: "26", name: "Drôme" },
  { code: "27", name: "Eure" },
  { code: "28", name: "Eure-et-Loir" },
  { code: "29", name: "Finistère" },
  { code: "30", name: "Gard" },
  { code: "31", name: "Haute-Garonne" },
  { code: "32", name: "Gers" },
  { code: "33", name: "Gironde" },
  { code: "34", name: "Hérault" },
  {
    code: "35", name:
      "Ille-et-Vilaine"
  },
  { code: "36", name: "Indre" },
  { code: "37", name: "Indre-et-Loire" },
  { code: "38", name: "Isère" },
  { code: "39", name: "Jura" },
  { code: "40", name: "Landes" },
  { code: "41", name: "Loir-et-Cher" },
  { code: "42", name: "Loire" },
  { code: "43", name: "Haute-Loire" },
  { code: "44", name: "Loire-Atlantique" },
  { code: "45", name: "Loiret" },
  { code: "46", name: "Lot" },
  { code: "47", name: "Lot-et-Garonne" },
  { code: "48", name: "Lozère" },
  { code: "49", name: "Maine-et-Loire" },
  { code: "50", name: "Manche" },
  { code: "51", name: "Marne" },
  { code: "52", name: "Haute-Marne" },
  { code: "53", name: "Mayenne" },
  { code: "54", name: "Meurthe-et-Moselle" },
  { code: "55", name: "Meuse" },
  { code: "56", name: "Morbihan" },
  { code: "57", name: "Moselle" },
  { code: "58", name: "Nièvre" },
  { code: "59", name: "Nord" },
  { code: "60", name: "Oise" },
  { code: "61", name: "Orne" },
  { code: "62", name: "Pas-de-Calais" },
  { code: "63", name: "Puy-de-Dôme" },
  { code: "64", name: "Pyrénées-Atlantiques" },
  { code: "65", name: "Hautes-Pyrénées" },
  { code: "66", name: "Pyrénées-Orientales" },
  { code: "67", name: "Bas-Rhin" },
  { code: "68", name: "Haut-Rhin" },
  { code: "69", name: "Rhône" },
  { code: "70", name: "Haute-Saône" },
  { code: "71", name: "Saône-et-Loire" },
  { code: "72", name: "Sarthe" },
  { code: "73", name: "Savoie" },
  { code: "74", name: "Haute-Savoie" },
  { code: "75", name: "Paris" },
  { code: "76", name: "Seine-Maritime" },
  { code: "77", name: "Seine-et-Marne" },
  { code: "78", name: "Yvelines" },
  { code: "79", name: "Deux-Sèvres" },
  { code: "80", name: "Somme" },
  { code: "81", name: "Tarn" },
  { code: "82", name: "Tarn-et-Garonne" },
  { code: "83", name: "Var" },
  { code: "84", name: "Vaucluse" },
  { code: "85", name: "Vendée" },
  { code: "86", name: "Vienne" },
  { code: "87", name: "Haute-Vienne" },
  { code: "88", name: "Vosges" },
  { code: "89", name: "Yonne" },
  { code: "90", name: "Territoire de Belfort" },
  { code: "91", name: "Essonne" },
  { code: "92", name: "Hauts-de-Seine" },
  { code: "93", name: "Seine-Saint-Denis" },
  { code: "94", name: "Val-de-Marne" },
  { code: "95", name: "Val-d'Oise" }
]

const SearchAndFilter = ({
  searchType,
  setSearchType,
  searchQuery,
  setSearchQuery,
  filterQuery,
  setFilterQuery,
  handleSearch,
  locationsList,
  handleResetSearchAndFilter,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  const { t } = useTranslation();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const [serviceProviderListData, setServiceProviderListData] = useState([]);

  const fetchServiceProviders = useCallback(() => {
    collaboratorService
      .getAll('', '')
      .then((res) => {
        console.log({res});
        setServiceProviderListData(res.data);
        console.log({serviceProviderListData})
      })
      .catch((err) => {
      });
  }, [t]);

  useEffect(() => {
    fetchServiceProviders();
  }, [fetchServiceProviders]);

  return (
    <>
      <Box
        marginY={"10px"}
        width={"100%"}
        border="1px"
        borderColor="gray.300"
        borderRight={"none"}
        paddingY={2}
      >
        <Flex alignItems={"center"} justifyContent="space-around">
          <SearchIcon fontSize={"2xl"} marginX={4} />
          <Select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            width="auto"
            marginRight={4}
          >
            <option value="participant_name">{t("admin_filters.names")}</option>
            <option value="file_number">{t("admin_filters.file_numbers")}</option>
            {/* <option value="documents">{t("admin_filters.documents")}</option> */}
            <option value="participant_email">{t("admin_filters.emails")}</option>
            {/* <option disabled value="mediator_name">{t("admin_filters.mediator_name")}</option> */}
          </Select>
          <Input
            placeholder={t("admin_filters.search")}
            style={{ width: "77%" }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Box width={"10%"}></Box>
        </Flex>
      </Box>
      <Box
        width={"100%"}
        border="1px"
        borderColor="gray.300"
        borderRight={"none"}
        paddingY={2}
        borderTop={"none"}
      >
        <Flex alignItems="center">
          <Box marginLeft={4}>
            <img
              style={{ width: "30px", height: "30px" }}
              src={FilterIcon}
              alt="filter"
            />
          </Box>
          <Select
            placeholder={t("placeholder.status")}
            sx={selectStyles}
            width={"auto"}
            marginRight={4}
            value={filterQuery.status}
            onChange={(e) =>
              setFilterQuery({ ...filterQuery, status: e.target.value })
            }
          >
            <option value="New">{t("admin_status_new")}</option>
            <option value="In Progress">{t("admin_status.in_progress")}</option>
            <option value="Completed">{t("admin_status.completed")}</option>
          </Select>

          <Select
            placeholder={t("placeholder.department")}
            sx={selectStyles}
            width={"auto"}
            marginRight={4}
            value={filterQuery.department}
            onChange={(e) =>
              setFilterQuery({ ...filterQuery, department: e.target.value })
            }
          >
            {departmentCodes.map((dept) => (
              <option key={dept.code} value={dept.code}>
                {dept.code} - {dept.name}
              </option>
            ))}
          </Select>
              
          <Select
            placeholder={t("placeholder.principal_mediator")}
            sx={selectStyles}
            width={"auto"}
            marginRight={4}
            value={filterQuery.principal_mediator}  // Ensure this key exists in filterQuery
            onChange={(e) =>
              setFilterQuery({ ...filterQuery, principal_mediator: e.target.value })
            }
          >
            {serviceProviderListData?.map((med) => (
              <option key={med.id} value={med.id}>
                {med.first_name} {med.last_name}
              </option>
            ))}
          </Select>

          <Select
            placeholder={t("placeholder.session_mediator")}
            sx={selectStyles}
            width={"auto"}
            marginRight={4}
            value={filterQuery.session_mediator}  // Ensure this key exists in filterQuery
            onChange={(e) =>
              setFilterQuery({ ...filterQuery, session_mediator: e.target.value })
            }
          >
            {serviceProviderListData?.map((med) => (
              <option key={med.id} value={med.id}>
                {med.first_name} {med.last_name}
              </option>
            ))}
          </Select>


          <Select
            placeholder={t("placeholder.location")}
            sx={selectStyles}
            width={"auto"}
            marginRight={4}
            value={filterQuery.location}
            onChange={(e) =>
              setFilterQuery({ ...filterQuery, location: e.target.value })
            }
          >
            {locationsList.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </Select>


          <DatePicker
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            selectsStart
            startDate={fromDate}
            endDate={toDate}
            placeholderText={t("admin_filters.from_date")}
            customInput={<Input sx={datePickerStyles} />}
            showPopperArrow={true}
            locale="fr"
            dateFormat="dd-MM-yyyy"
          />
          <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            selectsEnd
            startDate={fromDate}
            endDate={toDate}
            placeholderText={t("admin_filters.to_date")}
            customInput={<Input sx={datePickerStyles} />}
            showPopperArrow={true}
            locale="fr"
            dateFormat="dd-MM-yyyy"
          />
          <CloseIcon
            mr={6}
            ml={"auto"}
            cursor="pointer"
            onClick={handleResetSearchAndFilter}
          />
        </Flex>
      </Box>
    </>
  );
};

export default SearchAndFilter;
