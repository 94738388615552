import axiosInstance from '../axiosConfig';
const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;


const statisticService = {

    getDataConsumption: async () => {
        try {
            const response = await axiosInstance.get(`${API_URL}/collaboration-api/secretary/media/statistics/`);
            return response.data;
          } catch (error) {
            throw error;
          }
    }

}

export default statisticService;

