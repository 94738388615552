
import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  Box,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  Avatar,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
// Language selector
import LanguageSelector from "./LanguageSelector";

// Language Management
import { useTranslation } from "react-i18next";

import { MdSecurity } from "react-icons/md";

import statisticService from "services/statisticService";

// Redux
import { connect, useDispatch } from "react-redux";
import {
  selectUser,
  selectAssociation,
  selectProfessionalProfile,
} from "selectors/authSelector";
import { logout } from "actions/authActions";

// Router
import { useHistory, useLocation } from "react-router-dom";
import { setAction } from "reducers/calendarReducer";
import { ActionTypes } from "reducers/calendarReducer";
import Utils from "services/Utils";

const activeTabStyle = {
  textDecoration: "none",
  color: "#FF6666",
  cursor: "pointer",
};

const inactiveTabStyle = {
  textDecoration: "none",
  color: "#252525",
  cursor: "pointer",
};

const CBrandHeader = ({ association, user, professionalProfile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("");
  const [dataConsumption, setDataConsumption] = useState(null);
  const timeZoneInfo = Utils.getLocalTimeZoneInfo(); // Call the function once

  const GetTimeZone = `${timeZoneInfo.timeZone} - ${timeZoneInfo.abbreviation}`; // Create the string

  useEffect(() => {

    const currentPath = location.pathname;
    if (currentPath.includes("service-provider")) {
      setActiveTab("service-providers");
    } else if (currentPath.includes("locations")) {
      setActiveTab("locations");
    } else if (currentPath.includes("calendar")) {
      setActiveTab("calendar");
    } else {
      setActiveTab("default");
    }
    //This also handles the auth validations error message popup toast
    const fetchData = async () => {
      try {
        const res = await statisticService.getDataConsumption();
        setDataConsumption(res);
      } catch (error) {
        console.error("Error fetching data consumption:", error);
        if (error.response && error.status === 401) {
          const errorCode = error.response.data?.code;
          // alert("the code");
          if (errorCode === "token_not_valid") {
            console.error("Token expired or invalid, logging out");
            toast.error(`${t('session_expired_error_msg')}`, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              theme: "colored",
            });

            // Log out the user and redirect to the sign-in page
            setTimeout(() => {
              dispatch(logout());
              history.push("/auth/sign-in");
            }, 2000);

          }
        } else {
          console.error("Error fetching data", error);
        }
      }
    };

    fetchData();
  }, [location.pathname]);


  const handleTabClick = (tab, path) => {
    setActiveTab(tab);
    history.push(path);
  };

  return (
    <Box>
      <ToastContainer position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Flex direction={"column"}>
        <Flex justify={"space-between"} mx={8} py={1}>
          <Box>
            <Image
              src="https://kiido-media.s3.eu-west-3.amazonaws.com/Kiido_Logo_Original+(1).png"
              alt="Kiido Logo"
              sx={{
                height: "50px",
                width: "auto",
              }}
            />
          </Box>
          <Flex
            style={{
              alignItems: "center",
              fontSize: "18px",
              fontWeight: "bold",
              gap: "24px",
            }}
          >
            <Link
              style={activeTab === "service-providers" ? activeTabStyle : inactiveTabStyle}
              onClick={() => handleTabClick("service-providers", "/admin/service-providers")}
            >
              {t("admin__header__mediators")}
            </Link>
            <Link
              style={activeTab === "locations" ? activeTabStyle : inactiveTabStyle}
              onClick={() => handleTabClick("locations", "/admin/locations")}
            >
              {t("admin__header__locations")}
            </Link>
            <Link
              style={activeTab === "default" ? activeTabStyle : inactiveTabStyle}
              onClick={() => handleTabClick("default", "/admin/default")}
            >
              {t("admin__header__requests")}
            </Link>
            <Link
              style={activeTab === "calendar" ? activeTabStyle : inactiveTabStyle}
              onClick={() => handleTabClick("calendar", "/admin/calendar")}
            >
              {t("calendar.header_menu")}
            </Link>
          </Flex>
          <Flex gap={16}>
            <Flex height={"fit-content"} my={"auto"}>
              <LanguageSelector />
            </Flex>
            <Box my={"auto"} zIndex='100000'>
              <Menu>
                <MenuButton p="0px">
                  <Avatar
                    _hover={{ cursor: "pointer" }}
                    color="white"
                    name={user?.full_name ?? "User"}
                    bg="#11047A"
                    size="sm"
                    w="40px"
                    h="40px"
                  />
                </MenuButton>
                <MenuList
                  boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.18)"
                  p="0px"
                  mt="10px"
                  borderRadius="20px"
                  bg={useColorModeValue("white", "navy.800")}
                  border="none"
                >
                  <Flex
                    w="100%"
                    mb="0px"
                    justifyContent={"center"}
                    paddingTop={"5px"}
                  >
                    <Avatar
                      _hover={{ cursor: "pointer" }}
                      color="white"
                      name={user?.full_name ?? "User"}
                      bg="#11047A"
                      size="sm"
                      w="40px"
                      h="40px"
                      bgImage={professionalProfile?.display_picture}
                    />
                  </Flex>
                  <Flex w="100%" mb="0px">
                    <Text
                      ps="20px"
                      pt="16px"
                      pb="10px"
                      w="100%"
                      borderBottom="1px solid"
                      borderColor="#E6ECFA"
                      fontSize="sm"
                      fontWeight="700"
                      color={useColorModeValue("secondaryGray.900", "white")}
                      justifyContent={"center"}
                    >
                      👋&nbsp; {t("admin__home__greet")},{" "}
                      {user?.full_name ?? "User"}
                    </Text>
                  </Flex>

                  <Flex flexDirection="column" p="10px">
                    <MenuItem _hover={{ bg: "none" }} _focus={{ bg: "none" }} borderRadius="8px" px="14px">
                      <Text fontSize="sm">{user?.email}</Text>
                    </MenuItem>
                    <MenuItem _hover={{ bg: "none" }} _focus={{ bg: "none" }} borderRadius="8px" px="14px">
                      <Text fontSize="sm">{GetTimeZone}</Text>
                    </MenuItem>
                    <MenuItem _hover={{ bg: "none" }} _focus={{ bg: "none" }} borderRadius="8px" px="14px">
                      <Image
                        src={association?.logo}
                        alt={`${association?.name}'s logo`}
                        sx={{ height: "30px", width: "auto" }}
                        margin={"auto"}
                      />
                    </MenuItem>
                    <MenuItem _hover={{ bg: "none" }} _focus={{ bg: "none" }} borderRadius="8px" px="14px">
                      <Flex>
                        <Text fontSize="sm">
                          {" "}
                          <span>
                            <MdSecurity />
                            {t("admin__home__safe_datacentre")}
                          </span>
                        </Text>
                      </Flex>
                    </MenuItem>
                    {dataConsumption && (
                      <>
                        <MenuItem _hover={{ bg: "none" }} _focus={{ bg: "none" }} borderRadius="8px" px="14px">
                          <Text fontSize="sm">
                            {t("admin__home__total_files")}: {dataConsumption.total_files}
                          </Text>
                        </MenuItem>
                        <MenuItem _hover={{ bg: "none" }} _focus={{ bg: "none" }} borderRadius="8px" px="14px">
                          <Text fontSize="sm">
                            {t("admin__home__consumption_percentage")}: {dataConsumption.total_size_humanized} / {dataConsumption.storage_allocation_left_humanized}
                          </Text>
                        </MenuItem>
                      </>
                    )}
                    <MenuItem
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      color="red.400"
                      borderRadius="8px"
                      px="14px"
                      onClick={() => {
                        dispatch(logout());

                        history.push("/auth/sign-in");
                      }}
                    >
                      <Text fontSize="sm">{t("admin__home__sign_out")}</Text>
                    </MenuItem>
                  </Flex>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        </Flex>
        <Flex px={8} py={3} borderY={"solid 1px #E7E7E7"} alignItems={"center"}>
          <Image
            src={association?.logo}
            alt=""
            sx={{
              height: "50px",
              width: "auto",
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  association: selectAssociation(state),
  user: selectUser(state),
  professionalProfile: selectProfessionalProfile(state),
});

const ConnectedBrandHeader = connect(mapStateToProps)(CBrandHeader);

export default function BrandHeader() {
  return <ConnectedBrandHeader />;
}

