export const ActionTypes = {
  SET_SELECTED_DATE: "SET_SELECTED_DATE",
  SET_ACCORDION_INDEX: "SET_ACCORDION_INDEX",
  SET_SESSION_TYPE_FILTER: "SET_SESSION_TYPE_FILTER",
  SET_MEDIATION_STATUS_FILTER: "SET_MEDIATION_STATUS_FILTER",
  SET_CURRENT_VIEW: "SET_CURRENT_VIEW",
  SET_SELECTED_LOCATIONS: "SET_SELECTED_LOCATIONS",
  SET_SELECTED_SERVICE: "SET_SELECTED_SERVICE",
  SET_SELECTED_MEDIATOR: "SET_SELECTED_MEDIATOR",
  SET_SELECTED_MEDS: "SET_SELECTED_MEDS",
  SET_LOCATIONS_LIST: "SET_LOCATIONS_LIST",
  SET_MEDIATORS_LIST: "SET_MEDIATORS_LIST",
  SET_FILTERED_MEDS_LIST: "SET_FILTERED_MEDS_LIST",
  SET_MEDIATORS_LIST_LOADING: "SET_MEDIATORS_LIST_LOADING",
  SET_SESSIONS_LIST: "SET_SESSIONS_LIST",
  SET_TIME_SLOTS_LIST: "SET_TIME_SLOTS_LIST",
  SET_LOADING_SESSIONS: "SET_LOADING_SESSIONS",
  SET_LOADING_TIME_SLOTS: "SET_LOADING_TIME_SLOTS",
  SET_ERROR: "SET_ERROR",
  TOGGLE_IS_MEDIATOR_FIRST: "TOGGLE_IS_MEDIATOR_FIRST",
  RESET_FILTERS: "RESET_FILTERS"
};

export const setAction = (type, payload) => {
  return { type, payload };
};


const initialState = {
  selectedDate: new Date(2024, 8, 8, 10, 20),
  accordionIndex: [],
  sessionTypeFilter: "All",
  mediationStatusFilter: "",
  currentView: 'Week',
  selectedLocations: [],
  selectedService: 1,
  selectedMediator: [],
  selectedMeds: [],
  locationsList: [],
  mediatorsList: [],
  filteredMedsList: [],
  mediatorsListIsLoading: true,
  sessionsList: [],
  timeSlotsList: [],
  isLoadingSessions: false,
  isLoadingTimeSlots: false,
  error: null,
  isMediatorFirst: true,
};

function calendarReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_DATE:
      return { ...state, selectedDate: action.payload };
    case ActionTypes.SET_ACCORDION_INDEX:
      return { ...state, accordionIndex: action.payload };
    case ActionTypes.SET_SESSION_TYPE_FILTER:
      return { ...state, sessionTypeFilter: action.payload };
    case ActionTypes.SET_MEDIATION_STATUS_FILTER:
      return { ...state, mediationStatusFilter: action.payload };
    case ActionTypes.SET_CURRENT_VIEW:
      return { ...state, currentView: action.payload };
    case ActionTypes.SET_SELECTED_LOCATIONS:
      return { ...state, selectedLocations: action.payload };
    case ActionTypes.SET_SELECTED_SERVICE:
      return { ...state, selectedService: action.payload };
    case ActionTypes.SET_SELECTED_MEDIATOR:
      return { ...state, selectedMediator: action.payload };
    case ActionTypes.SET_SELECTED_MEDS:
      return { ...state, selectedMeds: action.payload };
    case ActionTypes.SET_LOCATIONS_LIST:
      return { ...state, locationsList: action.payload };
    case ActionTypes.SET_MEDIATORS_LIST:
      return { ...state, mediatorsList: action.payload };
    case ActionTypes.SET_FILTERED_MEDS_LIST:
      return { ...state, filteredMedsList: action.payload };
    case ActionTypes.SET_MEDIATORS_LIST_LOADING:
      return { ...state, mediatorsListIsLoading: action.payload };
    case ActionTypes.SET_SESSIONS_LIST:
      return { ...state, sessionsList: action.payload };
    case ActionTypes.SET_TIME_SLOTS_LIST:
      return { ...state, timeSlotsList: action.payload };
    case ActionTypes.SET_LOADING_SESSIONS:
      return { ...state, isLoadingSessions: action.payload };
    case ActionTypes.SET_LOADING_TIME_SLOTS:
      return { ...state, isLoadingTimeSlots: action.payload };
    case ActionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    case ActionTypes.TOGGLE_IS_MEDIATOR_FIRST:
      return { ...state, isMediatorFirst: !state.isMediatorFirst };
    case ActionTypes.RESET_FILTERS:
      return {
        ...state,
        selectedLocations: [],
        selectedMediator: [],
        selectedMeds: [],
        filteredMedsList: [],
        selectedService: null,
        locationsList: action.payload,
        sessionsList: [],
        timeSlotsList: [],
        sessionTypeFilter: "All",
        mediationStatusFilter: "",
      };
    case ActionTypes.LOGOUT:  // Handle logout action
      return initialState;  // Reset state to initial state
    default:
      return state;
  }
}

export default calendarReducer;

