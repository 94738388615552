import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  FormErrorMessage,
  Text,
  InputGroup,
  InputRightElement,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
} from "@chakra-ui/react";
import { FaCalendarAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import rdvSessionService from "services/rdvSessionService";
import rdvService from "services/rdvService";
import { enUS, fr } from "date-fns/locale";
import { startOfMonth, endOfMonth, subMonths, addMonths } from "date-fns";
import Utils from "services/Utils";
import { Bounce, toast } from "react-toastify";
import { FaCheck, FaTimes } from "react-icons/fa";
import { logout } from "actions/authActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const CCreateFirstSessionDialog = ({
  isOpen,
  onClose,
  accessToken,
  originalRdvId,
  liftStateUp,
  availableLocations,
  preferredLocation,
  proposedDate,
  proposedTime,
  serviceId,
  isFirstSession,
  principalParticipant,
  association
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(isFirstSession && proposedDate ? new Date(proposedDate) : null);
  const [startTime, setStartTime] = useState(isFirstSession && proposedTime ? proposedTime : "");
  const [sessionType, setSessionType] = useState(isFirstSession ? "Info" : "Info");
  const [title, setTitle] = useState(`${principalParticipant} + ${sessionType}`);
  const [location, setLocation] = useState(preferredLocation ? preferredLocation : "");
  const [consultationModes, setConsultationModes] = useState('');
  const [mediator, setMediator] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [monthAvailability, setMonthAvailability] = useState({});
  const [disabledDates, setDisabledDates] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDropdownClicked, setIsDropdownClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const toastShownRef = useRef(false);
  const [consultationModesList, setConsultationModesList] = useState([]);
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);

  const datePickerRef = useRef();

  const handleDropdownClick = () => {
    setIsDropdownClicked(true);
  };

  useEffect(() => {
    HandleGetConsultationModeList(association.slug)
    console.log("association", association)
  }, [association])

  const HandleGetConsultationModeList = async (slug) => {

    try {
      const res = await rdvService.getConsultationMode(accessToken, slug);
      console.log("consultation mode response", res.data);

      // Set the selected services only if results exist
      if (Array.isArray(res.data)) {
        setConsultationModesList(res.data);
      } else {
        console.error("No results found in the consultation mode response.");
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  }

  useEffect(() => {
    setTitle(`${principalParticipant} + ${sessionType}`);
  }, [sessionType]);

  useEffect(() => {
    if (isOpen && location && startDate) {
      if (isFirstSession && proposedDate) {
        fetchDayAvailability(location, serviceId, new Date(proposedDate));
      } else {
        fetchMonthAvailability(location, serviceId, startDate);
      }
    }
  }, [isOpen, location, startDate, serviceId, sessionType, accessToken]);

  useEffect(() => {
    if (monthAvailability && isFirstSession && proposedDate) {
      fetchMonthAvailability(location, serviceId, startDate);
    }
  }, [proposedDate, location, startDate, sessionType]);

  const fetchDayAvailability = async (locationId, serviceId, date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    let response

    try {
      // const response = await rdvSessionService.getByMonthAvailabilityWithProviders({
      //   locationId,
      //   serviceId,
      //   fromDate: formattedDate, // Start and end date are the same
      //   toDate: formattedDate,
      //   type: sessionType,
      //   accessToken
      // });

      if (isLocationDisabled) {
        response = await rdvSessionService.getByMonthAvailabilityWithProviders({
          serviceId,
          fromDate: formattedDate, // Start and end date are the same
          toDate: formattedDate,
          type: sessionType,
          accessToken
        });
      } else {
        response = await rdvSessionService.getByMonthAvailabilityWithProviders({
          locationId,
          serviceId,
          fromDate: formattedDate, // Start and end date are the same
          toDate: formattedDate,
          type: sessionType,
          accessToken
        });
      }

      setMonthAvailability({ [formattedDate]: response[formattedDate] || [] });
      setTimeSlots(response[formattedDate] || []);
    } catch (error) {
      console.error("Error fetching day availability", error);
      setMonthAvailability({});
      setTimeSlots([]);
    }
  };

  const fetchMonthAvailability = async (locationId, serviceId, date) => {
    setLoading(true);
    // Ensure date is a valid Date object
    const validDate = date instanceof Date && !isNaN(date) ? date : new Date();

    const startDate = startOfMonth(subMonths(validDate, 1))
      .toISOString()
      .split("T")[0];

    const endDate = endOfMonth(addMonths(validDate, 1))
      .toISOString()
      .split("T")[0];

    try {
      const response = await rdvSessionService.getByMonthAvailabilityWithProviders({
        locationId,
        serviceId,
        fromDate: startDate,
        toDate: endDate,
        type: sessionType,
        accessToken
      });
      setMonthAvailability(response || {});

      // Calculate disabled dates
      const emptyDates = Object.entries(response)
        .filter(([_, events]) => events.length === 0)
        .map(([date, _]) => new Date(date));

      setDisabledDates(emptyDates);
    } catch (error) {
      console.error("Error fetching month availability", error);
      setMonthAvailability({});
      setDisabledDates([]);
      if (error.response && error.response.status === 401) {
        handleUnauthorized();
      } else {
        console.log("Error fetching availability.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUnauthorized = () => {
    console.error("Unauthorized. Logging out and redirecting to sign-in.");
    if (!toastShownRef.current) {
      toast.error(`${t('session_expired_error_msg')}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      toastShownRef.current = true; // Set the flag to true to prevent future toasts
    }
    setTimeout(() => {
      // Assuming you want to dispatch logout and redirect here
      dispatch(logout()); // Uncomment this line
      history.push('/auth/sign-in'); // Uncomment this line
      // alert("logged out");
    }, 2000);
  };

  useEffect(() => {
    if (proposedTime && timeSlots.length > 0) {
      console.log(`proposedTime && timeSlots.length > 0 is true`)
      const matchingSlot = timeSlots.find(slot => slot.start_time === proposedTime);
      if (matchingSlot) {
        setStartTime(matchingSlot.start_time);
        setMediator(matchingSlot.service_provider.id.toString());
        setSelectedSlot(matchingSlot);
      } else {
        setStartTime(timeSlots[0].start_time);
        setMediator(timeSlots[0].service_provider.id.toString());
        setSelectedSlot(timeSlots[0]);
      }
    } else {
      if (timeSlots.length > 0) {
        setStartTime(timeSlots[0].start_time);
        setMediator(timeSlots[0].service_provider.id.toString());
        setSelectedSlot(timeSlots[0]);
      }
    }
  }, [proposedTime, timeSlots]);

  useEffect(() => {
    if (isSubmitted) {
      checkFormValidity();
    }
  }, [title, startDate, startTime, sessionType, location, mediator, isSubmitted]);

  const isDateDisabled = (date) => {
    return disabledDates.some(
      (disabledDate) =>
        date.getFullYear() === disabledDate.getFullYear() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getDate() === disabledDate.getDate(),
    );
  };

  const handleSlotSelect = (e) => {
    console.log('index = ', parseInt(e.target.value));
    const selectedIndex = parseInt(e.target.value);
    const selectedSlot = timeSlots[selectedIndex];
    if (selectedSlot) {
      setMediator(selectedSlot.service_provider.id.toString());
      setStartTime(selectedSlot.start_time);
      setSelectedSlot(selectedSlot);
    } else {
      setMediator("");
      setStartTime("");
      setSelectedSlot(null);
    }
  };
  const handleConsultationModeChange = (e) => {
    console.log("the consultation modes", e.target.value)

    const selectedMode = consultationModesList.find((mode) => mode.id === Number(e.target.value));
    // Set the disabled state for location based on is_location_agnostic
    setConsultationModes(e.target.value)
    const isLocationAgnostic = selectedMode?.is_location_agnostic || false;
    setIsLocationDisabled(isLocationAgnostic);
        // Reset date and time when location changes
        handleDateChange(null);
        setTimeSlots([]);
    if (isLocationAgnostic) {
      setLocation()
    }
  }

  const handleLocationChange = (e) => {
    const newLocation = e.target.value;
    setLocation(newLocation);

    // Reset date and time when location changes
    handleDateChange(null);
    setTimeSlots([]);

    if (isFirstSession && proposedDate) {
      // Fetch availability for the proposed date
      fetchDayAvailability(newLocation, serviceId, new Date(proposedDate));
    } else if (startDate) {
      // Fetch availability for the month if there's a valid start date
      fetchMonthAvailability(newLocation, serviceId, startDate);
    }
  };

  const handleSessionTypeChange = (e) => {
    const newType = e.target.value;
    console.log("newType", newType);
    setSessionType(newType)
    // Reset date and time when location changes
    handleDateChange(null);
    setTimeSlots([]);
  }

  const handleDateChange = (date) => {
    setStartDate(date);
    if (date) {
      fetchTimeSlots(location, date);
    } else {
      setTimeSlots([]); // Reset time slots if date is cleared
    }
  };

  const fetchTimeSlots = async (locationId, date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const availableSlots = monthAvailability[formattedDate] || [];
    setTimeSlots(availableSlots);
  };

  const checkFormValidity = () => {
    const newErrors = {};
    if (title.trim() === "") newErrors.title = t("session_name_required");
    if (!startDate) newErrors.startDate = t("session_date_required");
    if (startTime === "") newErrors.startTime = t("session_time_required");
    if (sessionType === "") newErrors.sessionType = t("session_type_required");
    if (location === "") newErrors.location = t("session_location_required");
    if (mediator === "") newErrors.mediator = t("session_mediator_required");
    if (!selectedSlot) newErrors.startTime = t("no_time_slots_available");
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    const formIsValid = checkFormValidity();

    if (!formIsValid) {
      return;
    }

    const startDateTime = new Date(`${moment(startDate).format("YYYY-MM-DD")}T${startTime}`);
    const endDateTime = new Date(startDateTime.getTime() + Utils.durationToSeconds(selectedSlot.duration) * 1000);


    let date = startDateTime;
    const utcDate = new Date(date.toUTCString());
    const formattedDate = utcDate.toISOString().split('T')[0];
    console.log(formattedDate);

    try {
      const newSession = await rdvSessionService.create({
        payload: {
          session_title: title,
          date: formattedDate,
          type: sessionType,
          original_rdv: originalRdvId,
          service_provider: parseInt(mediator),
          location: parseInt(location),
          tentative_availability_slot: selectedSlot.id,
          consultation_mode: parseInt(consultationModes)
        },
        accessToken,
      });

      setLocation(preferredLocation);
      resetForm();


      localStorage.setItem('newSessionId', newSession.data.id);
      localStorage.setItem('lastSessionId', newSession.data.id);

      await updateRdv();

      liftStateUp();
      onClose();
      toast.success(
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaCheck style={{ marginRight: "8px" }} />
          {t("messages.changesSaved")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: "auto",
            minWidth: "400px",
          },
          icon: false,
        }
      );
    } catch (error) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("failed_to_invite")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  };

  const updateRdv = async () => {
    try {
      await rdvService.update({
        pk: originalRdvId,
        obj: {
          mediator: parseInt(mediator),
        },
        accessToken,
      });
    } catch (error) {
      console.error("Error updating RDV", error);
    }
  };

  const resetForm = () => {
    setTitle("");
    setStartDate("");
    setStartTime("");
    setSessionType("");
    setLocation(preferredLocation);
    setMediator("");
    setSelectedSlot(null);
    setErrors({});
    setIsSubmitted(false);
  };

  const getDateLocale = () => {
    switch (i18n.language) {
      case "fr":
        return fr;
      default:
        return enUS;
    }
  };

  useEffect(() => {
    fetchMonthAvailability(location, serviceId, new Date())
    console.log("dates", disabledDates, location)
  }, [location]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("admin_add_new_session")}</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={isSubmitted && !!errors.title}>
            <FormLabel>{t("name_for_session")} <Text as="span" color="red">*</Text></FormLabel>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t("enter_name")}
              autoFocus
              bg="gray.200"
            />
            <FormErrorMessage>{errors.title}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.sessionType}>
            <FormLabel>{t("select_consultation_mode")} <Text as="span" color="red">*</Text></FormLabel>
            <Select
              value={consultationModes}
              onChange={handleConsultationModeChange}
              bg="gray.200"
            >
              <option value="" disabled>
                {t("select_consultation_mode")} {/* Default "Select Consultation Mode" */}
              </option>
              {consultationModesList.map((mode) => (
                <option key={mode.id} value={mode.id}>
                  {i18n.language === 'fr' ? mode.name_fr : mode.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.sessionType}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.sessionType}>
            <FormLabel>{t("select_session_type")} <Text as="span" color="red">*</Text></FormLabel>
            <Select
              value={sessionType}
              onChange={handleSessionTypeChange}
              placeholder={isFirstSession ? "Select session type" : undefined}
              bg="gray.200"
            >
              {isFirstSession ? (
                <option value="Info">{t("info")}</option>
              ) : (
                <>
                  <option value="Info">{t("info")}</option>
                  <option value="Mediation">{t("mediation")}</option>
                </>
              )}
            </Select>
            <FormErrorMessage>{errors.sessionType}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.location}>
            <FormLabel>{t("select_location")} <Text as="span" color="red">*</Text></FormLabel>
            <Select
              value={location}
              onChange={handleLocationChange}
              bg="gray.200"
              disabled={isLocationDisabled}
            >
               <option value="" disabled>
                {t("select_location")} {/* Default "Select" */}
              </option>
              {availableLocations.map((loc) => (
                <option key={loc.id} value={loc.id}>
                  {loc.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.location}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.startDate}>
            <FormLabel>{t("select_session_date")} <Text as="span" color="red">*</Text></FormLabel>
            <InputGroup onClick={() => fetchMonthAvailability(location, serviceId, new Date())} display="flex" border="1px solid #E2E8F0" borderRadius="md" bg="gray.200" alignItems="center" disabled={!consultationModes || consultationModes.length === 0}>
              <DatePicker
                selected={startDate}
                placeholderText={t("no_date_selected")}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                className="chakra-input"
                showPopperArrow={false}
                ref={datePickerRef}
                customInput={
                  <Input
                    variant="unstyled"
                    padding="8px 12px"
                    bg="gray.200"
                    borderRadius="md"
                    placeholder={t('new_request_form.select_date')}
                    isDisabled={!consultationModes || consultationModes.length === 0} 
                  />
                }
                minDate={new Date()}
                filterDate={(date) => !isDateDisabled(date)}
                onMonthChange={(date) => fetchMonthAvailability(location, serviceId, date)}
                locale={getDateLocale()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={!consultationModes || consultationModes.length === 0}
              />
              <InputRightElement
                pointerEvents="all"
                top="50%"
                transform="translateY(-50%)"
                onClick={() => datePickerRef.current.setOpen(true)}
                disabled={!consultationModes || consultationModes.length === 0}
              >
                <Icon as={FaCalendarAlt} color="gray.500" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.startDate}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.startTime}>
            <FormLabel>{t("select_time_slot")} <Text as="span" color="red">*</Text></FormLabel>
            <Select
              value={timeSlots.findIndex(slot => slot.start_time === startTime)}
              onChange={handleSlotSelect}
              bg="gray.200"
              disabled={!startDate}
            >
              {timeSlots.length > 0 ? (
                timeSlots.map((slot, index) => (
                  <option key={index} value={index}>
                    {`${Utils.convertUTCToLocalTimeZone(slot.start_time)}`}
                  </option>
                ))
              ) : (
                <option value="">{t("no_time_slots_available")}</option>
              )}
            </Select>
            <FormErrorMessage>{errors.startTime}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={isSubmitted && !!errors.mediator}>
            <FormLabel>{t("select_mediator")} <Text as="span" color="red">*</Text></FormLabel>
            <Select
              value={mediator}
              onChange={(e) => setMediator(e.target.value)}
              bg="gray.200"
              disabled={!startTime}
            >
              {selectedSlot && mediator ? (
                <option value={selectedSlot.service_provider.id}>
                  {`${selectedSlot.service_provider.first_name} ${selectedSlot.service_provider.last_name}`}
                </option>
              ) : (
                <option value="">{t("no_mediator_found")}</option>
              )}
            </Select>
            <FormErrorMessage>{errors.mediator}</FormErrorMessage>
          </FormControl>

          <Flex mt={4} justifyContent="flex-end">
            <Button
              fontSize={"16px"}
              color={"white"}
              backgroundColor={"#FF6666"}
              _hover={{
                backgroundColor: "#CC4444",  // Darker shade on hover
              }}
              mr={3}
              onClick={handleSubmit}
            >
              {t("create_session")}
            </Button>
            <Button
              variant="outline"
              onClick={onClose}
            >
              {t("close")}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default function CreateFirstSessionDialog({
  isOpen,
  onClose,
  accessToken,
  originalRdvId,
  liftStateUp,
  availableLocations,
  preferredLocation,
  proposedDate,
  proposedTime,
  serviceId,
  isFirstSession,
  principalParticipant,
  association
}) {
  return (
    <CCreateFirstSessionDialog
      isOpen={isOpen}
      onClose={onClose}
      accessToken={accessToken}
      originalRdvId={originalRdvId}
      liftStateUp={liftStateUp}
      availableLocations={availableLocations}
      preferredLocation={preferredLocation}
      proposedDate={proposedDate}
      proposedTime={proposedTime}
      serviceId={serviceId}
      isFirstSession={isFirstSession}
      principalParticipant={principalParticipant}
      association={association}
    />
  );
}
