import React, { useEffect, useState } from 'react';
import {
    Box,
    Select,
    Checkbox,
    IconButton,
    Flex,
    VStack,
    HStack,
    Text,
    Spinner,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Button,
    FormControl,
    // useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    Tooltip
} from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, CheckboxGroup } from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';
import { FaTimes, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Utils from 'services/Utils'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import rdvService from 'services/rdvService';

const Availability = ({ mediator }) => {
    let accessToken = useSelector((state) => state.auth.accessToken);
    let association = useSelector((state) => state.auth.association);
    const { t, i18n } = useTranslation();
    const [selectedLocation, setSelectedLocation] = useState(mediator?.locations[0]?.id || []);
    const [availabilityBlocks, setAvailabilityBlocks] = useState([]);
    const [availabilityOddWeekStructure, setAvailabilityOddWeekStructure] = useState([]);
    const [availabilityEvenWeekStructure, setAvailabilityEvenWeekStructure] = useState([]);
    const [availabilityAllWeekStructure, setAvailabilityAllWeekStructure] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedDaysAll, setSelectedDaysAll] = useState([]);
    const [selectedDaysEven, setSelectedDaysEven] = useState([]);
    const [selectedDaysOdd, setSelectedDaysOdd] = useState([]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
    const [deletingConfig, setDeletingConfig] = useState(null);
    const [handlePopupUncheck, setHandlePopupUncheck] = useState(false)
    const [hasChanges, setHasChanges] = useState(false);
    const [serviceOptions, setServiceOptions] = useState(mediator?.consultation_specialities);
    const dayMap = i18n.language === 'en' ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] : ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    const [consultationModeOptions, setConsultationModeOptions] = useState([]);
    const timeSlots = Utils.generateTimeSlots();
    const displayTimezone = Utils.getLocalTimeZoneInfo().timeZone;
    // const toast = useToast();

    useEffect(() => {
        console.log("i18n.language", i18n.language, association)
        HandleGetConsultationModeList(association?.slug)
    }, [i18n.language])

    const HandleGetConsultationModeList = async (slug) => {

        try {
            const res = await rdvService.getConsultationMode(accessToken, slug);
            console.log("consultation mode response", res.data);

            // Set the selected services only if results exist
            if (Array.isArray(res.data)) {
                setConsultationModeOptions(res.data);
            } else {
                console.error("No results found in the consultation mode response.");
            }
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    }

    // const serviceOptions = [
    //     { id: '1', name: 'Family Mediation', name_fr: 'Médiation Familiale' },
    //     // { id: '2', name: 'Therapy', name_fr: 'Thérapie' }
    // ];

    // const consultationModeOptions = [
    //     { id: '1', name: 'Telephone', name_fr: 'Téléphone' },
    //     { id: '2', name: 'Online', name_fr: 'En ligne' },
    //     { id: '3', name: 'In person', name_fr: 'En personne' }
    // ];


    const normalizeTime = (timeString) => {
        // console.log("timeString", timeString, "converted local", Utils.convertUTCToLocalTimeZone(timeString))
        return timeString ? Utils.convertUTCToLocalTimeZone(timeString) : '';  // Removes the ":00" part
    };

    const mapServiceNameToId = (service) => {
        const serviceNameToIdMap = {
            'Family Mediation': '1',
            'Therapy': '2'
        };
        return {
            ...service,
            id: serviceNameToIdMap[service.name] || null
        };
    };

    const buildAvailabilityBlocks = () => {
        if (availabilityBlocks.length === 0) {
            setAvailabilityOddWeekStructure([]);
            setSelectedDaysOdd([]);
            setAvailabilityEvenWeekStructure([]);
            setSelectedDaysEven([]);
            setAvailabilityAllWeekStructure([]);
            setSelectedDaysAll([]);
            return;
        };

        const oddWeekBlocks = [];
        const evenWeekBlocks = [];
        const allWeekBlocks = [];

        availabilityBlocks.forEach(avb => {
            if (avb.applicable_week === 'Odd') {
                oddWeekBlocks.push(avb);
                setSelectedDaysOdd(prev => [...new Set([...prev, ...avb.applicable_days])]);
            } else if (avb.applicable_week === 'Even') {
                evenWeekBlocks.push(avb);
                setSelectedDaysEven(prev => [...new Set([...prev, ...avb.applicable_days])]);
            } else if (avb.applicable_week === 'All') {
                allWeekBlocks.push(avb);
                setSelectedDaysAll(prev => [...new Set([...prev, ...avb.applicable_days])]);
            }
        });
        setAvailabilityOddWeekStructure(oddWeekBlocks);
        setAvailabilityEvenWeekStructure(evenWeekBlocks);
        setAvailabilityAllWeekStructure(allWeekBlocks);
    };

    const fetchAvailabilityBlocks = async () => {
        setLoading(true);
        setHasChanges(false);
        try {
            let res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary/mediator/availability/`, {
                params: {
                    locations: selectedLocation,
                    service_providers: mediator.id
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            const blocks = res.data.map(block => ({
                ...block,
                start_time: normalizeTime(block.start_time),
                end_time: normalizeTime(block.end_time),
                consultation_mode: block.consultation_mode.map(Number),
                service: block.service
            }));

            setAvailabilityBlocks(blocks);

        } catch (error) {
            console.error('Error fetching availability blocks:', error);

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        buildAvailabilityBlocks();
    }, [availabilityBlocks]);

    useEffect(() => {
        if (mediator?.id) {
            fetchAvailabilityBlocks();
        }
    }, [selectedLocation]);

    const handleLocationChange = (locationId) => {
        setSelectedLocation(locationId);
        setAvailabilityBlocks([]);
    };

    const handleDeleteConfirm = async (day, weekType) => {
        let updatedStructure;

        const removeConfig = async (structure) => {
            try {
                const blocksToRemove = structure.filter(block => block.applicable_days.includes(day));
                console.log("Blocks to remove for day:", blocksToRemove);

                for (const block of blocksToRemove) {
                    if (String(block.id).length < 10) {
                        await deleteAvailabilityBlock(block.id); // Remove via API if ID < 5 digits
                    }
                }
                // Remove from state
                return structure.filter(block => !block.applicable_days.includes(day));
            } catch (error) {
                console.error("Failed to remove configuration:", error);
            }
        };

        switch (weekType) {
            case 'all':
                updatedStructure = await removeConfig(availabilityAllWeekStructure);
                setAvailabilityAllWeekStructure(updatedStructure);
                break;
            case 'even':
                updatedStructure = await removeConfig(availabilityEvenWeekStructure);
                setAvailabilityEvenWeekStructure(updatedStructure);
                break;
            case 'odd':
                updatedStructure = await removeConfig(availabilityOddWeekStructure);
                setAvailabilityOddWeekStructure(updatedStructure);
                break;
            default:
                console.error("Invalid week type:", weekType);
                break;
        }

        // After deletion, update the selected days to uncheck the checkbox
        updateDaySelection(day, weekType, false);
        setHandlePopupUncheck(false);
    };

    const handleDayChange = (day, type, isChecked) => {
        console.log("day, type, isChecked", selectedDaysAll, day, type, isChecked);

        if (isChecked === false) {
            // Open confirmation popup without updating the checkbox state yet
            setHandlePopupUncheck({ day, type, isOpen: true });
        } else {
            // Update checkbox immediately if checked (no need for confirmation)
            updateDaySelection(day, type, isChecked);
        }
    };

    const updateDaySelection = (day, type, isChecked) => {
        // This function updates the selected days based on type
        switch (type) {
            case 'all':
                setSelectedDaysAll((prev) =>
                    isChecked
                        ? [...prev, day]
                        : prev.filter(d => d !== day)
                );
                break;
            case 'even':
                setSelectedDaysEven((prev) =>
                    isChecked
                        ? [...prev, day]
                        : prev.filter(d => d !== day)
                );
                break;
            case 'odd':
                setSelectedDaysOdd((prev) =>
                    isChecked
                        ? [...prev, day]
                        : prev.filter(d => d !== day)
                );
                break;
            default:
                break;
        }
    };

    const handleInputChange = (day, weekType, field, value, blockId) => {
        setHasChanges(true)
        const updateBlock = (blocks) =>
            blocks.map(block =>
                block.id === blockId
                    ? updateSelection(block, value)
                    : block
            );

        const updateSelection = (block, value) => {
            if (field === 'service') {
                const newServiceSelection = serviceOptions.filter(service => value.includes(service.id));
                return { ...block, service: newServiceSelection };
            } else if (field === 'consultation_mode') {
                return { ...block, consultation_mode: value.map(Number) };
            }
            return { ...block, [field]: value };
        };

        if (weekType === 'all') {
            setAvailabilityAllWeekStructure(prev => {
                const updatedBlocks = updateBlock(prev);
                // updatedBlocks.forEach(block => updateUnsavedChanges(block, weekType));
                return updatedBlocks;
            });
        } else if (weekType === 'even') {
            setAvailabilityEvenWeekStructure(prev => {
                const updatedBlocks = updateBlock(prev);
                // updatedBlocks.forEach(block => updateUnsavedChanges(block, weekType));
                return updatedBlocks;
            });
        } else if (weekType === 'odd') {
            setAvailabilityOddWeekStructure(prev => {
                const updatedBlocks = updateBlock(prev);
                // updatedBlocks.forEach(block => updateUnsavedChanges(block, weekType));
                return updatedBlocks;
            });
        }
    };
    function generateUniqueId() {
        const timestamp = Date.now(); // Get the current timestamp in milliseconds
        const randomNum = Math.floor(Math.random() * 1000); // Get a random number between 0 and 999
        const id = `${timestamp}${randomNum}`.slice(0, 15); // Concatenate and ensure it is 15 digits long
        return id;
    }
    const addNewDayConfig = (day, weekType) => {
        setHasChanges(true)
        const newConfig = {
            id: generateUniqueId(), // Unique ID
            service: [],
            consultation_mode: [],
            start_time: '',
            end_time: '',
            applicable_days: [day],
            new_config: true
        };

        if (weekType === 'all') {
            setAvailabilityAllWeekStructure(prev => {
                const updatedBlocks = [...prev, newConfig];
                // updateUnsavedChanges(newConfig, weekType);
                return updatedBlocks;
            });
        } else if (weekType === 'even') {
            setAvailabilityEvenWeekStructure(prev => {
                const updatedBlocks = [...prev, newConfig];
                // updateUnsavedChanges(newConfig, weekType);
                return updatedBlocks;
            });
        } else if (weekType === 'odd') {
            setAvailabilityOddWeekStructure(prev => {
                const updatedBlocks = [...prev, newConfig];
                // updateUnsavedChanges(newConfig, weekType);
                return updatedBlocks;
            });
        }
    };

    const deleteAvailabilityBlock = async (id) => {
        console.log("ids", id);
        // setLoading(true);

        try {
            await axios.delete(
                `${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary/availability-seed/${id}/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
            console.log(`Successfully deleted availability block with ID: ${id}`);
            if (i18n.language === 'en') {
                toast.success(`The availability block has been successfully removed.`, {
                    position: "bottom-center",
                    progressBar: false,
                    icon: false
                });
            } else {
                toast.success(`Le créneau de disponibilité a été supprimé avec succès.`);
            }
        } catch (error) {
            console.error(`Error deleting availability block with ID: ${id}`, error);

        } finally {
            // Whether success or error, loading should stop after the API call
            setLoading(false);
        }
    };

    const removeDayConfig = async (day, weekType, configId) => {
        console.log("day, weekType, configId", day, weekType, configId);

        let updatedStructure;
        let itemsRemoved = false; // Flag to track if any items were removed

        const removeConfig = async (structure) => {
            try {
                if (configId) {
                    // Remove specific configuration based on ID
                    const configToRemove = structure.find(block => block.applicable_days.includes(day) && block.id === configId);

                    if (configToRemove && String(configToRemove.id).length < 10) {
                        // If the ID is less than 5 digits, delete via API  
                        await deleteAvailabilityBlock(configToRemove.id);
                    }
                    itemsRemoved = true;
                    // Remove from UI state
                    return structure.filter(block => !(block.applicable_days.includes(day) && block.id === configId));
                } else {
                    // Remove all configurations for the given day
                    const blocksToRemove = structure.filter(block => block.applicable_days.includes(day));
                    console.log("here else for configId", blocksToRemove);

                    // Loop through and remove blocks with ID < 5 digits via API, remove others only from UI
                    for (const block of blocksToRemove) {
                        if (String(block.id).length < 10) {
                            await deleteAvailabilityBlock(block.id); // Remove via API if ID < 5 digits
                        }
                    }
                    itemsRemoved = true;
                    // Remove all configurations from the state
                    return structure.filter(block => !block.applicable_days.includes(day));
                }
            } catch (error) {
                console.error("Failed to remove configuration:", error);
            }
        };

        switch (weekType) {
            case 'all':
                updatedStructure = await removeConfig(availabilityAllWeekStructure);
                setAvailabilityAllWeekStructure(updatedStructure);
                break;
            case 'even':
                updatedStructure = await removeConfig(availabilityEvenWeekStructure);
                setAvailabilityEvenWeekStructure(updatedStructure);
                break;
            case 'odd':
                updatedStructure = await removeConfig(availabilityOddWeekStructure);
                setAvailabilityOddWeekStructure(updatedStructure);
                break;
            default:
                break;
        }

        if (configId.length < 10) {
            fetchAvailabilityBlocks();
        }
        // If any items were removed and no more configurations exist for the day, uncheck the day
        // if (itemsRemoved) {
        //     const hasRemainingConfigs = updatedStructure.some(block => block.applicable_days.includes(day));
        //     if (!hasRemainingConfigs) {
        //         handleDayChange(day, weekType, false); // Uncheck the day
        //     }
        // }


    };

    const handleDelete = async () => {
        if (deletingConfig) {
            const { day, weekType, configId } = deletingConfig;
            await removeDayConfig(day, weekType, configId);
        }
        setDeletingConfig(null); // Clear after deletion
        onClose(); // Close the dialog
    };

    const handleDeleteClick = (day, weekType, configId) => {
        setDeletingConfig({ day, weekType, configId }); // Set the config to delete
        onOpen(); // Open the confirmation dialog
    };



    const renderAvailabilityForWeekType = (availabilityWeekStructure, selectedDays, weekType) => {
        // Always show all days of the week and sort them by dayMap
        const sortedDays = dayMap
            .map((_, index) => index) // Get all the days (as numbers)

        return (
            <VStack align="start" spacing={4}>
                {sortedDays.map((day, dayIndex) => {
                    let dayConfigs = availabilityWeekStructure.filter(block => block.applicable_days.includes(day));

                    if (selectedDays.includes(day) && dayConfigs.length === 0) {
                        addNewDayConfig(day, weekType); // Add new config if needed
                        dayConfigs = availabilityWeekStructure.filter(block => block.applicable_days.includes(day));
                    }

                    return (
                        <Box key={dayIndex} w="100%">
                            <Checkbox
                                isChecked={selectedDays.includes(day)}
                                onChange={(e) => handleDayChange(day, weekType, e.target.checked)}
                                mr={4}
                                css={{
                                    '.chakra-checkbox__control': {
                                        borderColor: '#CBD5E0',  // Default border color (light grey)
                                        transition: 'background-color 0.3s, border-color 0.3s',  // Smooth transition for background and border
                                        padding: '0.25rem',  // Extra padding for better click area
                                    },
                                    '.chakra-checkbox__control:hover': {
                                        backgroundColor: '#F7FAFC',  // Light background on hover
                                        borderColor: '#E64E64',  // Border color changes to match the checked state
                                    },
                                    '.chakra-checkbox__control[data-checked]': {
                                        backgroundColor: '#e64e64',  // Checked background color
                                        borderColor: '#e64e64',  // Checked border color
                                        color: 'white',
                                    },
                                    '.chakra-checkbox__control[data-checked]:hover': {
                                        backgroundColor: '#cc3f3f',  // Darker background on hover when checked
                                    },
                                    '.chakra-checkbox__control[aria-disabled="true"]': {
                                        backgroundColor: '#e64e64',
                                        borderColor: '#e64e64',
                                        opacity: 0.6,  // Slight transparency for disabled state
                                        cursor: 'not-allowed',
                                        color: 'white',
                                    },
                                    '.chakra-checkbox__control:focus': {
                                        boxShadow: '0 0 0 2px rgba(230, 78, 100, 0.4)',  // Focus ring for better accessibility
                                    },
                                }}
                            >
                                <Text fontSize={'xl'} fontWeight="medium" color={selectedDays.includes(day) ? '#e64e64' : '#2D3748'}>
                                    {dayMap[day]}
                                </Text>
                            </Checkbox>


                            {selectedDays.includes(day) && dayConfigs.map((dayConfig) => (
                                <Flex
                                    key={dayConfig.id}
                                    pl={{ base: 2, md: 6 }}  // Responsive padding-left
                                    pt={{ base: 1, md: 2 }}  // Responsive padding-top
                                    justify={{ base: 'flex-start', md: 'space-around' }}  // Responsive justification
                                    flexWrap="wrap"  // Allow wrapping
                                    direction={{ base: 'column', md: 'row' }}  // Stack items vertically on mobile, horizontally on larger screens
                                    gap={{ base: 2, md: 4 }}  // Responsive gap between items
                                >
                                    {/* <h1>{dayConfig.id}</h1> */}
                                    <Box flex={{ base: '1 1 100%', md: '1 1 15%' }} pr={{ base: 0, md: 2 }} mb={{ base: 4, md: 0 }}>
                                        <Accordion allowMultiple borderRadius="md">
                                            <AccordionItem borderRadius="md">
                                                <AccordionButton bg={'#EDF2F7'} borderRadius="md">
                                                    <Box flex="1" textAlign="left">
                                                        {t("services_availability")} ({dayConfig.service.length})
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>

                                                <AccordionPanel pb={4} borderRadius="md">
                                                    <CheckboxGroup
                                                        value={dayConfig.service.map(service => service.id)}
                                                        onChange={(selectedValues) => {
                                                            const numericValues = selectedValues.map(value => Number(value));  // Convert strings to numbers
                                                            handleInputChange(day, weekType, 'service', numericValues, dayConfig.id);  // Pass numeric values
                                                        }}
                                                    >
                                                        <VStack align="start">
                                                            {serviceOptions.length > 0 && serviceOptions.map((service) => (
                                                                <>

                                                                    <Checkbox
                                                                        key={service.id}
                                                                        value={service.id}
                                                                        isChecked={dayConfig?.service.some(selectedService => selectedService.id === service.id)}
                                                                        css={{
                                                                            '.chakra-checkbox__control': {
                                                                                borderColor: '#CBD5E0',  // Default border color
                                                                                transition: 'background-color 0.3s, border-color 0.3s',  // Smooth transition
                                                                            },
                                                                            '.chakra-checkbox__control[data-checked]': {
                                                                                backgroundColor: '#FF6666',  // Checked background color
                                                                                borderColor: '#FF6666',  // Checked border color
                                                                                color: 'white',  // Text color for checked state
                                                                            },
                                                                            '.chakra-checkbox__control[data-checked]:hover': {
                                                                                backgroundColor: '#cc3f3f',  // Darker checked background color on hover
                                                                            },
                                                                            '.chakra-checkbox__control:focus': {
                                                                                boxShadow: '0 0 0 2px rgba(230, 78, 100, 0.4)',  // Focus ring for accessibility
                                                                            },
                                                                        }}
                                                                    >
                                                                        {i18n.language === 'fr' ? service.name_fr : service.name}
                                                                    </Checkbox>
                                                                </>
                                                            ))}
                                                        </VStack>
                                                    </CheckboxGroup>
                                                </AccordionPanel>

                                            </AccordionItem>
                                        </Accordion>
                                    </Box>

                                    <Box flex={{ base: '1 1 100%', md: '1 1 15%' }} pr={{ base: 0, md: 2 }} mb={{ base: 4, md: 0 }}>
                                        <Accordion allowMultiple borderRadius="md">
                                            <AccordionItem borderRadius="md">
                                                <AccordionButton bg={'#EDF2F7'} p={2} borderRadius="md">
                                                    <Box flex="1" textAlign="left">
                                                        {t("consultation_mode")} ({dayConfig.consultation_mode.length})
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>

                                                <AccordionPanel pb={4} borderRadius="md">
                                                    <CheckboxGroup
                                                        value={dayConfig.consultation_mode}
                                                        onChange={(selectedValues) => {
                                                            handleInputChange(day, weekType, 'consultation_mode', selectedValues, dayConfig.id)
                                                            console.log("selectedValues", selectedValues);
                                                        }}

                                                    >
                                                        <VStack align="start">
                                                            {consultationModeOptions.map((mode) => {
                                                                const isModeIncluded = dayConfig.consultation_mode.includes(mode.id); // Check the whole array
                                                                // console.log("dayConfig.consultation_mode:", dayConfig.consultation_mode);

                                                                // console.log(`Checking mode ID ${mode.id}:`, isModeIncluded);

                                                                return ( // Return the JSX here
                                                                    <div key={mode.id}>
                                                                        {/* <h1>
                                                                            {mode.id} - Included: {isModeIncluded.toString()} - Stringified check: {`${dayConfig.consultation_mode.includes(mode.id)}`}
                                                                        </h1> */}
                                                                        <Checkbox
                                                                            value={mode.id}
                                                                            isChecked={isModeIncluded}
                                                                            css={{
                                                                                '.chakra-checkbox__control': {
                                                                                    borderColor: '#CBD5E0',  // Default border color
                                                                                    transition: 'background-color 0.3s, border-color 0.3s',  // Smooth transition
                                                                                },
                                                                                '.chakra-checkbox__control[data-checked]': {
                                                                                    backgroundColor: '#FF6666',  // Checked background color
                                                                                    borderColor: '#FF6666',  // Checked border color
                                                                                    color: 'white',  // Text color for checked state
                                                                                },
                                                                                '.chakra-checkbox__control[data-checked]:hover': {
                                                                                    backgroundColor: '#cc3f3f',  // Darker checked background color on hover
                                                                                },
                                                                                '.chakra-checkbox__control:focus': {
                                                                                    boxShadow: '0 0 0 2px rgba(230, 78, 100, 0.4)',  // Focus ring for accessibility
                                                                                },
                                                                            }}
                                                                        >
                                                                            {i18n.language === 'fr' ? mode.name_fr : mode.name}
                                                                        </Checkbox>
                                                                    </div>
                                                                );
                                                            })}
                                                        </VStack>
                                                    </CheckboxGroup>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </Box>

                                    <Box flex={{ base: '1 1 100%', md: '1 1 5%' }} pr={{ base: 0, md: 2 }} mb={{ base: 4, md: 0 }}>
                                        <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" mb={2}>
                                            <FormControl>
                                                <Select
                                                    bg={'#EDF2F7'}
                                                    value={dayConfig.start_time || ''}
                                                    onChange={(e) => handleInputChange(day, weekType, 'start_time', e.target.value, dayConfig.id)}
                                                >
                                                    <option value="" disabled>{t("start_time")}</option>
                                                    {timeSlots.map((time) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Box display='flex' justifyContent={'center'} alignSelf='center' m={2}>
                                                <Text> - </Text>
                                            </Box>
                                            <FormControl>
                                                <Select
                                                    bg={'#EDF2F7'}
                                                    value={dayConfig.end_time || ''}
                                                    onChange={(e) => handleInputChange(day, weekType, 'end_time', e.target.value, dayConfig.id)}
                                                >
                                                    <option value="" disabled>{t("end_time")}</option>
                                                    {timeSlots.filter(time => time > dayConfig.start_time).map((time) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Flex>
                                    </Box>

                                    <Box flex="1" pr={2}>
                                        <Flex justifyContent='flex-start'>
                                            <IconButton
                                                icon={<FaPlus />}
                                                aria-label="Add slot"
                                                onClick={() => addNewDayConfig(day, weekType)}
                                            />
                                            <IconButton
                                                icon={<FaTimes />}
                                                aria-label="Remove slot"
                                                onClick={() => handleDeleteClick(day, weekType, dayConfig.id)}
                                                ml={{ base: 0, md: 2 }}
                                            />
                                            {/* <button onClick={() => applyToAllDays(availabilityWeekStructure, dayConfig, weekType)}>Apply to All</button> */}
                                        </Flex>
                                        {isOpen ?
                                            <AlertDialog
                                                isOpen={isOpen}
                                                leastDestructiveRef={cancelRef}
                                                onClose={onClose}
                                            >
                                                <AlertDialogOverlay style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                    <AlertDialogContent>
                                                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                                            {t("confirm_deletion")}
                                                        </AlertDialogHeader>

                                                        <AlertDialogBody>
                                                            {t("delete_all_availability_confirmation")}
                                                        </AlertDialogBody>

                                                        <AlertDialogFooter>
                                                            <Button ref={cancelRef} onClick={onClose}>
                                                                {t("cancel")}
                                                            </Button>
                                                            <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                                                {t("delete")}
                                                            </Button>
                                                        </AlertDialogFooter>
                                                    </AlertDialogContent>
                                                </AlertDialogOverlay>
                                            </AlertDialog>
                                            : ''}
                                        {handlePopupUncheck?.isOpen && (
                                            <AlertDialog
                                                isOpen={handlePopupUncheck?.isOpen}
                                                onClose={() => setHandlePopupUncheck(false)}
                                            >
                                                <AlertDialogOverlay style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                                    <AlertDialogContent>
                                                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                                            {t("confirm_deletion")}
                                                        </AlertDialogHeader>

                                                        <AlertDialogBody>
                                                            {t("delete_all_availability_confirmation")}
                                                        </AlertDialogBody>

                                                        <AlertDialogFooter>
                                                            <Button onClick={() => setHandlePopupUncheck(false)}>
                                                                {t("cancel")}
                                                            </Button>
                                                            <Button
                                                                colorScheme="red"
                                                                onClick={() => handleDeleteConfirm(handlePopupUncheck.day, handlePopupUncheck.type)}
                                                                ml={3}
                                                            >
                                                                {t("delete")}
                                                            </Button>
                                                        </AlertDialogFooter>
                                                    </AlertDialogContent>
                                                </AlertDialogOverlay>
                                            </AlertDialog>
                                        )}
                                    </Box>
                                </Flex>
                            ))}
                        </Box>
                    );
                })}
            </VStack>
        );
    };

    const handleSave = async () => {
        // console.log("all states", availabilityBlocks,
        //     "availabilityAllWeekStructure", availabilityAllWeekStructure,
        // );
        const postAvailabilityBlocks = async (blocks, weekType) => {
            if (blocks.length === 0) return; // Skip posting if there are no blocks
            const objectBlocks = blocks.map(block => ({
                start_time: block.start_time,
                end_time: block.end_time,
                applicable_days: block.applicable_days,
                service_provider: mediator.id,
                location: selectedLocation,
                service: block.service.map(ser => ser.id),
                consultation_mode: block.consultation_mode,
                applicable_week: weekType,
                cooldown_period: 0,
                timezone: displayTimezone,
            }));
            console.log("objectBlocks", objectBlocks);
            try {
                await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary/availability-seed/bulk/create/`,
                    objectBlocks,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                );
            } catch (error) {
                const errors = error.response?.data || [];

                // Iterate through each error block and handle English/French split
                const messages = errors.map(err => {
                    const errorMessage = err.non_field_errors?.[0] || "Start time and end time is overlapping with other block";
                    const [englishMessage, frenchMessage] = errorMessage.split('\n');
                    // Determine which message to show based on the current language
                    return i18n.language === 'en' ? englishMessage || "Start time and end time is overlapping with other block" :
                        i18n.language === 'fr' ? frenchMessage || "L'heure de début et l'heure de fin se chevauchent avec un autre bloc." :
                            "Start time and end time is overlapping with other block";
                });

                // Combine all messages into one string for the toast notification
                const combinedMessage = messages.join('\n');

                toast.error(<div style={{ display: 'flex', alignItems: 'center' }}>{combinedMessage}</div>, {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "#e74c3c",
                        margin: "auto",
                        color: "white",
                        minWidth: '600px'
                    },
                    progressBar: false,
                    icon: false
                });
                console.error(`Error processing ${weekType} blocks:`, error);
            }
        };

        const checkForChanges = (currentBlocks, initialBlocks) => {
            return currentBlocks.filter(block => {
                const initialBlock = initialBlocks.find(b => b.id === block.id);
                return initialBlock && JSON.stringify(block) !== JSON.stringify(initialBlock);
            });
        };

        const additionalFunction = async (blocks, initialBlocks, weekType) => {
            const changedBlocks = checkForChanges(blocks, initialBlocks);

            if (changedBlocks.length > 0) {
                const transformedBlocks = changedBlocks.map(block => ({
                    id: block.id,
                    start_time: block.start_time,
                    end_time: block.end_time,
                    applicable_days: block.applicable_days,
                    service_provider: mediator.id,
                    location: selectedLocation,
                    service: block.service.map(ser => ser.id),
                    consultation_mode: block.consultation_mode,
                    applicable_week: weekType,
                    cooldown_period: 0,
                    timezone: displayTimezone,
                }));

                try {
                    await axios.put(
                        `${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary/availability-seed/bulk/edit/`,
                        transformedBlocks,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    );
                    console.log(`Successfully processed ${weekType} blocks with changes:`, transformedBlocks);
                    fetchAvailabilityBlocks();
                } catch (error) {
                    const errorMessage = error.response?.data?.non_field_errors?.[0] || "Start time and end time is overlapping with other block";
                    const [englishMessage, frenchMessage] = errorMessage.split('\n');
                    const message = i18n.language === 'en' ? englishMessage || "Start time and end time is overlapping with other block" :
                        i18n.language === 'fr' ? frenchMessage || "L'heure de début et l'heure de fin se chevauchent avec un autre bloc." :
                            "Start time and end time is overlapping with other block";
                    toast.error(<div style={{ display: 'flex', alignItems: 'center' }}>{message}</div>, {
                        position: "bottom-center",
                        style: {
                            backgroundColor: "#e74c3c",
                            margin: "auto",
                            color: "white",
                            minWidth: '600px'
                        },
                        progressBar: false,
                        icon: false
                    });
                    console.error(`Error processing ${weekType} blocks:`, error);
                }
            }
        };

        setLoading(true);
        try {
            // Post new availability blocks if there are any
            await Promise.all([
                postAvailabilityBlocks(availabilityAllWeekStructure.filter(block => block.new_config), 'All'),
                postAvailabilityBlocks(availabilityEvenWeekStructure.filter(block => block.new_config), 'Even'),
                postAvailabilityBlocks(availabilityOddWeekStructure.filter(block => block.new_config), 'Odd')
            ]);

            // Process changes for each week type
            await Promise.all([
                additionalFunction(availabilityAllWeekStructure, availabilityBlocks.filter(block => block.applicable_week === 'All'), 'All'),
                additionalFunction(availabilityEvenWeekStructure, availabilityBlocks.filter(block => block.applicable_week === 'Even'), 'Even'),
                additionalFunction(availabilityOddWeekStructure, availabilityBlocks.filter(block => block.applicable_week === 'Odd'), 'Odd')
            ]);

        } catch (error) {
            console.log("There was an issue saving blocks", error);
        } finally {
            setLoading(false);
        }
        fetchAvailabilityBlocks();
    };



    return (
        <Box p={3}>
            <Flex justifyContent="space-between" mb={4}>
                <Text fontSize={{ base: '2xl', md: '3xl' }}>
                    {`${mediator.first_name} ${mediator.last_name}`}
                </Text>
                <Button colorScheme="red" onClick={handleSave} isDisabled={!hasChanges}>
                    {t("save_availability")}
                </Button>
            </Flex>

            <Flex direction={{ base: 'column', md: 'row' }} align="flex-start" spacing={4}>
                <Flex direction="column" w={{ base: '100%', sm: '100%', md: '20%' }} borderRight="1px solid #EDF2F7" p={2}>
                    {mediator?.locations?.length === 0 ? (
                        <Text>{t("no_location_found_error_availability")}</Text>
                    ) : (
                        mediator?.locations?.map((location) => (
                            <Tooltip key={location.id} label={location.name} aria-label="Location Name" hasArrow>
                                <Box
                                    border={selectedLocation === location.id ? "2px solid #E64E64" : "2px solid #EDF2F7"}
                                    borderRadius="lg"
                                    bg={selectedLocation === location.id ? "#EDF2F7" : "white"}
                                    cursor="pointer"
                                    p={3}
                                    onClick={() => handleLocationChange(location.id)}
                                    mb={2} // Space between items
                                >
                                    <Text fontSize="xl" textTransform="capitalize">
                                        {location.name.length > 25 ? location.name.slice(0, 25) + "..." : location.name}
                                    </Text>
                                    <Text>{location.zip_code}</Text>
                                </Box>
                            </Tooltip>
                        ))
                    )}
                </Flex>

                <Flex direction="column" w="80%" p={2} bg="white">
                    {mediator?.locations?.length === 0 ? (
                        // <Text>{t("no_location_found_error_availability")}</Text>
                        ''
                    ) : (
                        <>
                            {loading ? (
                                <Flex justifyContent="center" alignItems="center" h="100%">
                                    <Spinner size="xl" color="#E64E64" />
                                </Flex>
                            ) : (
                                <Tabs variant="enclosed" isLazy>
                                    <TabList mb={3}>
                                        <Tab
                                            _selected={{
                                                color: '#E64E64',
                                                borderBottom: '2px solid #E64E64',
                                            }}
                                            _focus={{ boxShadow: 'none' }}
                                        >
                                            {t("all_weeks")}
                                        </Tab>
                                        <Tab
                                            _selected={{
                                                color: '#E64E64',
                                                borderBottom: '2px solid #E64E64',
                                            }}
                                            _focus={{ boxShadow: 'none' }}
                                        >
                                            {t("even_weeks")}
                                        </Tab>
                                        <Tab
                                            _selected={{
                                                color: '#E64E64',
                                                borderBottom: '2px solid #E64E64',
                                            }}
                                            _focus={{ boxShadow: 'none' }}
                                        >
                                            {t("odd_weeks")}
                                        </Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            {renderAvailabilityForWeekType(availabilityAllWeekStructure, selectedDaysAll, 'all')}
                                        </TabPanel>
                                        <TabPanel>
                                            {renderAvailabilityForWeekType(availabilityEvenWeekStructure, selectedDaysEven, 'even')}
                                        </TabPanel>
                                        <TabPanel>
                                            {renderAvailabilityForWeekType(availabilityOddWeekStructure, selectedDaysOdd, 'odd')}
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            )}
                        </>)}
                </Flex>

            </Flex>
        </Box>
    );
};

export default Availability;