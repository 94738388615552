import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Flex,
  InputGroup,
  InputLeftAddon,
  Textarea,
  Divider,
  Image,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  Checkbox
} from '@chakra-ui/react';
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';
import { FaTimes, FaUpload } from 'react-icons/fa';
import axios from 'axios';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import UploadBox from '../components/FileUploader';
import rdvService from 'services/rdvService';
import collaboratorService from 'services/collaboratorService';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSelectedMediator } from 'actions/mediatorActions';
import { toast } from 'react-toastify';

const NewProfile = ({ onSubmit }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [formState, setFormState] = useState({
    first_name: '',
    first_name_fr: '',
    last_name: '',
    last_name_fr: '',
    email: '',
    gender: '',
    preferred_language: '',
    designation: '',
    designation_fr: '',
    about: '',
    about_fr: '',
    address: '',
    postal_code: '',
    city: '',
    country_code: '+33',
    phone_number: '',
    associations: [],
    locations: [],
    image: '',
    signature_image: '',
    consultation_specialities: [],
    is_active: true
  });
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesIds, setSelectedServicesIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCityFromPostalCode = async (postalCode) => {
    try {
      const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=postcode=${postalCode}&limit=1`);
      if (response.data.features.length > 0) {
        const city = response.data.features[0].properties.city;
        setFormState(prevForm => ({ ...prevForm, city }));
      } else {
        setFormState(prevForm => ({ ...prevForm, city: '' }));
      }
    } catch (error) {
      console.error("Error fetching city from postal code:", error);
    }
  };

  const handleImageUpload = (file, field) => {
    handleChange(field, file);
    // const reader = new FileReader();
    // reader.onload = () => handleChange(field, reader.result);
    // reader.readAsDataURL(file);
  };

  const handleChange = (field, value) => {
    setFormState({
      ...formState,
      [field]: value,
    });
    setErrors({
      ...errors,
      [field]: '',
    });
    // checkFormValidity();
  };

  // useEffect(() => {
  //   HandleGetServicesList();
  // }, [])



  // Step 1: Create local state
  const [associationData, setAssociationData] = useState(null);
  // Step 2: Get association data from Redux store
  const associationFromStore = useSelector((state) => state.auth.association);


  // Step 3: Use useEffect to set local state and log to console
  useEffect(() => {
    if (associationFromStore) {
      setAssociationData(associationFromStore);
      // console.log('Association Data:', associationFromStore); // Log to console
      HandleGetServicesList(associationFromStore.slug);
    }
  }, [associationFromStore]);  // Run this effect whenever associationFromStore changes



  const HandleGetServicesList = async (slug) => {
    try {
      const res = await rdvService.getServices(accessToken, slug);
      // console.log("services response", res.data.results);  

      // Set the selected services only if results exist
      if (Array.isArray(res.data.results)) {
        setSelectedServices(res.data.results);
      } else {
        console.error("No results found in the services response.");
      }
    } catch (error) {
      console.error("Error fetching services:", error);
      // Optionally, you can set an error state or show a notification to the user
      // setError("Failed to fetch services. Please try again later.");
    }
  };

  const handleBlur = () => {
    const validationErrors = validateForm();
    setErrors(validationErrors);
    checkFormValidity();
  };

  const checkFormValidity = () => {
    const validationErrors = validateForm();
    setIsFormValid(Object.keys(validationErrors).length === 0);
  };

  // new branch 


  const validateForm = () => {
    const newErrors = {};

    // Determine which fields to validate based on the current language
    if (i18n.language === 'fr') {
      if (!formState.first_name) {
        newErrors.first_name = t("mediationTab.validation_first_name_required");
      }
      if (!formState.last_name) {
        newErrors.last_name = t("mediationTab.validation_last_name_required");
      }
      if (!formState.designation_fr) {
        newErrors.designation_fr = t("mediationTab.validation_designation_required");
      }
      if (!formState.about_fr) {
        newErrors.about_fr = t("mediationTab.validation_about_required");
      }
    } else {
      // English validations
      if (!formState.first_name) {
        newErrors.first_name = t("mediationTab.validation_first_name_required");
      }
      if (!formState.last_name) {
        newErrors.last_name = t("mediationTab.validation_last_name_required");
      }
      if (!formState.designation) {
        newErrors.designation = t("mediationTab.validation_designation_required");
      }
      if (!formState.about) {
        newErrors.about = t("mediationTab.validation_about_required");
      }
    }

    // Common validations for both languages
    if (!formState.email) {
      newErrors.email = t("mediationTab.validation_email_required");
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      newErrors.email = t("mediationTab.validation_email_invalid");
    }

    if (!formState.preferred_language) {
      newErrors.preferred_language = t("mediationTab.validation_language_required");
    }

    // Gender Validation
    if (!formState.gender) {
      newErrors.gender = t("mediationTab.validation_gender_required");
    } else if (
      !["She/Her/Hers", "He/Him/His", "Ze/Hir/Hirs", "They/Them/Theirs", "No Pronoun", "No Preference", "Not Listed"].includes(formState.gender)
    ) {
      newErrors.gender = t("mediationTab.validation_gender_invalid");
    }

    // Services Validation: At least one service must be selected
    if (formState.consultation_specialities.length === 0) {
      newErrors.consultation_specialities = t("mediationTab.validation_services_required");
    }

    // Phone Number Validation (only if provided)
    if (formState.phone_number && !/^(?:\+?[1-9]\d{1,14}|\d{10})$/.test(formState.phone_number)) {
      newErrors.phone_number = t("mediationTab.validation_phone_number_invalid");
    }

    // Country Code Validation: should start with '+' and followed by digits
    if (!formState.country_code) {
      newErrors.country_code = t("mediationTab.validation_country_code_required");
    } else if (!/^\+\d+$/.test(formState.country_code)) {
      newErrors.country_code = t("mediationTab.validation_country_code_invalid");
    }

    // Postal Code Validation: exactly 5 digits
    if (formState.postal_code && !/^\d{5}$/.test(formState.postal_code)) {
      newErrors.postal_code = t("mediationTab.validation_postal_code_invalid");
    }

    // Address Validation
    if (!formState.address) {
      newErrors.address = t("mediationTab.validation_address_required");
    }

    return newErrors;
  };


  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleCheckboxChange = (serviceId) => {
    setFormState((prevState) => {
      const currentSpecialties = prevState.consultation_specialities || []; // Ensure array initialization

      let updatedSpecialties;

      // Check if the service ID is already in the selected array
      if (currentSpecialties.includes(serviceId)) {
        // Remove the service ID if it's already checked
        updatedSpecialties = currentSpecialties.filter(id => id !== serviceId);
      } else {
        // Add the service ID if it's not checked
        updatedSpecialties = [...currentSpecialties, serviceId];
      }

      console.log("Updated consultation_specialities:", updatedSpecialties);

      // Return the updated form state
      return {
        ...prevState,
        consultation_specialities: updatedSpecialties,
      };
    });
  };

  const handleSave = () => {

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // Clone the formState to avoid mutating the original object
      const submissionData = { ...formState };
      const date = new Date();
      const formattedDate = date.toString().split(' ').slice(0, 5).join(' ');
      // Append image file if it exists
      if (formState.image !== '') {
        submissionData.image = dataURLtoFile(formState.image, `image-${formattedDate}.png`);
      }

      if (formState.signature_image !== '') {
        submissionData.signature_image = dataURLtoFile(formState.signature_image, `signature-${formattedDate}.png`);
      }

      // Map consultation_specialities to indexed keys like consultation_specialities[0], consultation_specialities[1], etc.
      if (Array.isArray(formState.consultation_specialities)) {
        formState.consultation_specialities.forEach((id, index) => {
          submissionData[`consultation_specialities[${index}]`] = id;
        });

        // Remove the original consultation_specialities array to avoid conflicts
        delete submissionData.consultation_specialities;
      }

      console.log('submissionData:', submissionData);
      setLoading(true);
      // Submit the updated data
      HandleSubmitNewMediator(submissionData);
    } else {
      setErrors(validationErrors);
    }
  };


  const HandleSubmitNewMediator = (formState) => {

    collaboratorService
      .createMediator(formState, accessToken)
      .then((med) => {
        console.log("create med response", med)
        dispatch(setSelectedMediator(med));
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('mediationTab.mediator_added_success')}
          </div>,
          {
            position: "bottom-center",
            progressBar: false,
            theme: "colored",
            icon: false
          }
        );
        setTimeout(() => {
          // history.push(`/admin/service-provider/${med.id}`);
          setFormState([])
          history.push(`/admin/service-providers`)
          setLoading(false);
        }, 2000);
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.email?.[0]; // Safely access the error message

        // Check if the error message includes "email already exists"
        if (errorMessage && errorMessage.toLowerCase().includes("email already exists")) {
          setErrors((prevErrors) => ({
            ...prevErrors, // Preserve other errors
            email: t('email_already_exists')
          }));
          toast.error(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('email_already_exists')}
            </div>,
            {
              position: "bottom-center",
              style: {
                backgroundColor: "red",
                color: "white",
                width: 'auto',
                minWidth: '400px'
              },
              progressBar: false,
              icon: false
            }
          );

          // alert("Email is already registered. Please try a new one.");
        } else {
          console.error("Error creating mediator:", errorMessage);
          // Show other error messages or handle other errors
          // showToastError();
          toast.error(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t("mediationTab.mediator_add_failed")}
            </div>,
            {
              position: "bottom-center",
              style: {
                backgroundColor: "red",
                color: "white",
                width: 'auto',
                minWidth: '400px'
              },
              progressBar: false,
              icon: false
            }
          );
        }
        // showToastError();
        setLoading(false); // Ensure loading is turned off after an error

      });
  }

  return (
    <Box p={6}>
      <Flex justifyContent={'space-between'} mb={4}>
        <Box>
          <Text fontSize={'3xl'}>
            {t("mediationTab.mediator_details")} {`${formState.first_name} ${formState.last_name}`}
          </Text>
        </Box>
        <Button
          colorScheme={'red'}
          onClick={handleSave}
          isDisabled={!isFormValid || loading}
        >
          {t("mediationTab.add_mediator_button")}
        </Button>

      </Flex>
      <Box pb={5}>
        {/* First Name */}
        <FormControl isRequired paddingY={2} isInvalid={errors.first_name}>
          <FormLabel htmlFor="first-name">
            {t("mediationTab.first_name")}
          </FormLabel>
          <Input
            id="first-name"
            placeholder={t("enter_first_name")}
            value={formState.first_name}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{
              borderColor: '#E7E7E7',
              boxShadow: 'none',
            }}
            onChange={(e) => {
              handleChange('first_name', e.target.value);
            }}
            onBlur={handleBlur}
          />
          {errors.first_name && <Text color="red.500">{errors.first_name}</Text>}
        </FormControl>

        {/* Last Name */}
        <FormControl isRequired paddingY={2} isInvalid={errors.last_name}>
          <FormLabel htmlFor="last-name">
            {t("mediationTab.last_name")}
          </FormLabel>
          <Input
            id="last-name"
            placeholder={t("enter_last_name")}
            value={formState.last_name}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{
              borderColor: '#E7E7E7',
              boxShadow: 'none',
            }}
            onChange={(e) => {
              handleChange('last_name', e.target.value);
            }}
            onBlur={handleBlur}
          />
          {errors.last_name && <Text color="red.500">{errors.last_name}</Text>}
        </FormControl>

        {/* Email */}
        <FormControl isRequired paddingY={2} isInvalid={errors.email}>
          <FormLabel htmlFor="email">
            {t("mediationTab.email")}
          </FormLabel>
          <Input
            id="email"
            placeholder={t("enter_email")}
            value={formState.email}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{
              borderColor: '#E7E7E7',
              boxShadow: 'none',
            }}
            onChange={(e) => handleChange('email', e.target.value)}
            onBlur={handleBlur}
          />
          {errors.email && <Text color="red.500">{errors.email}</Text>}
        </FormControl>

        {/* Gender */}
        <FormControl isRequired width="100%" paddingY={2} isInvalid={errors.gender}>
          <FormLabel htmlFor="gender" width="100%">
            {t("admin__appointments_table__gender")}
          </FormLabel>
          <Select
            id="gender"
            placeholder={t("select_gender")}
            value={formState.gender}
            onChange={(e) => handleChange('gender', e.target.value)}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
            textTransform="capitalize"
          >
            <option value="She/Her/Hers">{t("pronouns.she_her_hers")}</option>
            <option value="He/Him/His">{t("pronouns.he_him_his")}</option>
            <option value="Ze/Hir/Hirs">{t("pronouns.ze_hir_hirs")}</option>
            <option value="They/Them/Theirs">{t("pronouns.they_them_theirs")}</option>
            <option value="No Pronoun">{t("pronouns.no_pronoun")}</option>
            <option value="No Preference">{t("pronouns.no_preference")}</option>
            <option value="Not Listed">{t("pronouns.not_listed")}</option>
          </Select>
          {errors.gender && <Text color="red.500">{errors.gender}</Text>}
        </FormControl>

        {/* Preferred Language */}
        <FormControl isRequired width="100%" paddingY={2} isInvalid={errors.preferred_language}>
          <FormLabel htmlFor="preferredLanguage" width="100%">
            {t("mediationTab.preferred_language_label")}
          </FormLabel>
          <Select
            id="preferredLanguage"
            placeholder={t("mediationTab.select_language")}
            value={formState.preferred_language}
            onChange={(e) => handleChange('preferred_language', e.target.value)}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{ borderColor: '#E7E7E7', boxShadow: 'none' }}
            textTransform="capitalize"
          >
            <option value="en">{t("languages.english")}</option>
            <option value="fr">{t("languages.french")}</option>
          </Select>
          {errors.preferred_language && <Text color="red.500">{errors.preferred_language}</Text>}
        </FormControl>

        {/* serviecs */}
        <FormControl isRequired width="100%" paddingY={2} isInvalid={errors.consultation_specialities}>
          <FormLabel htmlFor="services" width="100%">
            {t("mediationTab.services_profile")}
          </FormLabel>
          {selectedServices?.map((data, index) => (
            <Box key={index}>
              <Checkbox
                isChecked={formState.consultation_specialities.includes(data.id)}
                onChange={() => handleCheckboxChange(data.id)} // Call the handler here
                css={{
                  '.chakra-checkbox__control': {
                    borderColor: '#CBD5E0',  // Default border color (light grey)
                    transition: 'background-color 0.3s, border-color 0.3s',  // Smooth transition for background and border
                    padding: '0.25rem',  // Extra padding for better click area
                  },
                  '.chakra-checkbox__control:hover': {
                    backgroundColor: '#F7FAFC',  // Light background on hover
                    borderColor: '#FF6666',  // Border color changes to match the checked state
                  },
                  '.chakra-checkbox__control[data-checked]': {
                    backgroundColor: '#FF6666',  // Checked background color
                    borderColor: '#FF6666',  // Checked border color
                    color: 'white',
                  },
                  '.chakra-checkbox__control[data-checked]:hover': {
                    backgroundColor: '#cc3f3f',  // Darker background on hover when checked
                  },
                  '.chakra-checkbox__control[aria-disabled="true"]': {
                    backgroundColor: '#FF6666',
                    borderColor: '#FF6666',
                    opacity: 0.6,  // Slight transparency for disabled state
                    cursor: 'not-allowed',
                    color: 'white',
                  },
                  '.chakra-checkbox__control:focus': {
                    boxShadow: '0 0 0 2px rgba(230, 78, 100, 0.4)',  // Focus ring for better accessibility
                  },
                }}
              >
                {i18n.language === 'en' ? data.name : data.name_fr}
              </Checkbox>
            </Box>
          ))}
          {errors.consultation_specialities && <Text color="red.500">{errors.consultation_specialities}</Text>}
        </FormControl>


        {/* Designation */}
        <FormControl isRequired paddingY={2} isInvalid={errors.designation}>
          <FormLabel htmlFor="designation">
            {t("mediationTab.designation")}
          </FormLabel>
          <Input
            id="designation"
            placeholder={t("enter_your_designation")}
            value={i18n.language === 'fr' ? formState.designation_fr : formState.designation}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{
              borderColor: '#E7E7E7',
              boxShadow: 'none',
            }}
            onChange={(e) => {
              const updatedField = i18n.language === 'fr' ? 'designation_fr' : 'designation';
              handleChange(updatedField, e.target.value);
            }}
          />
          {errors.designation && <Text color="red.500">{errors.designation}</Text>}
        </FormControl>

        {/* About */}
        <FormControl isRequired paddingY={2} isInvalid={errors.about}>
          <FormLabel htmlFor="about">
            {t("mediationTab.about")}
          </FormLabel>
          <Textarea
            id="about"
            placeholder={t("tell_us_about_yourself")}
            value={i18n.language === 'fr' ? formState.about_fr : formState.about}
            variant="filled"
            bg="#FAFAFA"
            borderColor="#E7E7E7"
            _hover={{ borderColor: '#E7E7E7' }}
            _focus={{
              borderColor: '#E7E7E7',
              boxShadow: 'none',
            }}
            onChange={(e) => {
              const updatedField = i18n.language === 'fr' ? 'about_fr' : 'about';
              handleChange(updatedField, e.target.value);
            }}
          />
          {errors.about && <Text color="red.500">{errors.about}</Text>}
        </FormControl>

        {/* Address, Postal Code, Phone Number */}
        <Flex direction="row" wrap="wrap" gap={4} isInvalid={errors.address}>
          {/* Address */}
          <FormControl isRequired flex="1" minWidth="200px" >
            <FormLabel htmlFor="address">
              {t("mediationTab.address")}
            </FormLabel>
            <Input
              id="address"
              placeholder={t("enter_address")}
              value={formState.address}
              variant="filled"
              bg="#FAFAFA"
              borderColor="#E7E7E7"
              _hover={{ borderColor: '#E7E7E7' }}
              _focus={{
                borderColor: '#E7E7E7',
                boxShadow: 'none',
              }}
              onChange={(e) => handleChange('address', e.target.value)}
            />
            {errors.address && <Text color="red.500">{errors.address}</Text>}
          </FormControl>

          {/* Postal Code */}
          <FormControl isRequired flex="1" minWidth="200px" isInvalid={errors.postal_code}>
            <FormLabel htmlFor="postal-code">
              {t("mediationTab.postal_code")}
            </FormLabel>
            <Input
              id="postal-code"
              placeholder={t("enter_postal_code")}
              type={'number'}
              value={formState.postal_code}
              variant="filled"
              bg="#FAFAFA"
              borderColor="#E7E7E7"
              _hover={{ borderColor: '#E7E7E7' }}
              _focus={{
                borderColor: '#E7E7E7',
                boxShadow: 'none',
              }}
              onChange={(e) => {
                handleChange('postal_code', e.target.value);
                fetchCityFromPostalCode(e.target.value);
              }}
              onBlur={handleBlur}
            />
            {errors.postal_code && <Text color="red.500">{errors.postal_code}</Text>}
            {!errors.postal_code && formState.city && <Text fontSize="sm" color="gray.500">{formState.city}</Text>}
          </FormControl>

          {/* Country Code */}
          <FormControl isRequired flex="1" minWidth="200px" isInvalid={errors.country_code}>
            <FormLabel htmlFor="country-code">{t("admin__country_code")}</FormLabel>
            <Input
              id="country-code"
              placeholder={t("enter_country_code")}
              variant="filled"
              bg="#FAFAFA"
              borderColor="#E7E7E7"
              _hover={{ borderColor: '#E7E7E7' }}
              _focus={{
                borderColor: '#E7E7E7',
                boxShadow: 'none',
              }}
              value={formState.country_code}
              onChange={(e) => handleChange('country_code', e.target.value)}
            />
            {errors.country_code && <Text color="red.500">{errors.country_code}</Text>}
          </FormControl>

          {/* Phone Number */}
          <FormControl isRequired flex="1" minWidth="200px">
            <FormLabel htmlFor="phone-number">
              {t("mediationTab.phone_number")}
            </FormLabel>
            <Input
              id="phone-number"
              placeholder={t("enter_phone_number")}
              value={formState.phone_number}
              variant="filled"
              bg="#FAFAFA"
              borderColor="#E7E7E7"
              _hover={{ borderColor: '#E7E7E7' }}
              _focus={{
                borderColor: '#E7E7E7',
                boxShadow: 'none',
              }}
              onChange={(e) => handleChange('phone_number', e.target.value)}
              onBlur={handleBlur}
            />
            {errors.phone_number && <Text color="red.500">{errors.phone_number}</Text>}
          </FormControl>
        </Flex>

        {/* Image and Signature Upload Section */}
        <Box mt={8}>
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            {t("mediationTab.images")}
          </Text>
          <Flex gap={8} justify="space-evenly">
            {/* Mediator Image Section */}
            <Box
              textAlign="center"
              maxW="300px"
              minW="300px"
              mx="auto"
            >
              <UploadBox
                label={t("mediationTab.mediator_image")}
                image={formState.image}
                altText={t("mediationTab.mediator_image_alt")}
                noImageText={t("mediationTab.no_image")}
                onUpload={(file) => handleImageUpload(file, 'image')}
              />
              <Text mt={2} fontSize="lg" fontWeight="bold" color="gray.700">
                {t("mediationTab.profile_image_heading")}
              </Text>
            </Box>
            {/* Mediator Signature Section */}
            <Box
              textAlign="center"
              maxW="300px"
              minW="300px"
              mx="auto"
            >
              <UploadBox
                label={t("mediationTab.mediator_signature")}
                image={formState.signature_image}
                altText={t("mediationTab.mediator_signature_alt")}
                noImageText={t("mediationTab.no_signature")}
                onUpload={(file) => handleImageUpload(file, 'signature_image')}
                isSignature
              />
              <Text mt={2} fontSize="lg" fontWeight="bold" color="gray.700">
                {t("mediationTab.signature_image_heading")}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};


export default NewProfile;
