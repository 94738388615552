import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Text,
  Flex,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  Skeleton,
  Select,
  IconButton,
  Image,
} from "@chakra-ui/react";
import collaboratorService from "services/collaboratorService";
import Card from "components/card/Card";
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { connect } from "react-redux";
import {
  selectUser,
  selectAccessToken,
  selectAssociation,
  selectLocations,
} from "selectors/authSelector";
import {
  getData,
  getError,
  getLoading,
} from "../../../selectors/collaboratorSelector";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../actions/collaboratorActions.js";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import BrandHeader from "components/menu/BrandHeader";
import ToFirstPage from "../../../assets/img/dashboards/first.svg";
import ToLastPage from "../../../assets/img/dashboards/last.svg";
import { setSelectedMediator, clearMediator } from "../../../actions/mediatorActions";
import SearchAndFilterMediators from "./components/SearchAndFilterMediators";
import TableSkeleton from "components/TableSkeleton";
import i18n from "i18n";
const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};
const serviceTranslations = {
  "Family Mediation": "Médiation Familiale",
  "Therapy": "Thérapie",
};
function Primer({ text }) {
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text fontSize="lg" fontFamily="heading" fontWeight="500">
          {text}
        </Text>
      </Flex>
    </Card>
  );
}

const CUserReports = ({ user, accessToken, association }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const collaboratorData = useSelector(getData);
  const loading = useSelector(getLoading);
  const error = useSelector(getError);
  const locationsList = useSelector(selectLocations);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [serviceProviderListIsLoading, setServiceProviderListIsLoading] =
    useState(true);
  const [
    serviceProviderListFetchHasError,
    setServiceProviderListFetchHasError,
  ] = useState(null);
  const [serviceProviderListData, setServiceProviderListData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);

  const assoc = useSelector(selectAssociation);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = () => {
    fetchServiceProviders();
  };

  const fetchServiceProviders = useCallback(async () => {
    setServiceProviderListIsLoading(true);
    setIsFiltering(false);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary/mediator`, {
        params: { limit, offset, name: searchQuery, },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setServiceProviderListData(res.data.results);
      setTotalResults(res.data.count);
      setServiceProviderListIsLoading(false);
      // console.log("res.data.results", res.data.results)
    } catch (err) {

      setServiceProviderListFetchHasError(err);
      setServiceProviderListIsLoading(false);
    }
  }, [accessToken, limit, offset, history, searchQuery]);

  const handleNextPage = useCallback(() => {
    setOffset((prevOffset) => prevOffset + limit);
  }, [limit]);

  const handlePreviousPage = useCallback(() => {
    setOffset((prevOffset) => Math.max(prevOffset - limit, 0));
  }, [limit]);

  const handleFirstPage = useCallback(() => {
    setOffset(0);
  }, []);

  const handleLastPage = useCallback(() => {
    const lastPageOffset = Math.floor(totalResults / limit) * limit;
    setOffset(lastPageOffset);
  }, [totalResults, limit]);

  useEffect(() => {
    fetchServiceProviders();
  }, [fetchServiceProviders]);

  // Clear any saved mediator on component mount
  useEffect(() => {
    localStorage.removeItem('selectedServiceProvider');
  }, []);

  const handleRowClick = (mediator) => {
    dispatch(setSelectedMediator(mediator));
    history.push(`/admin/service-provider/${mediator.id}`);
  };

  const renderContent = () => {
    if (serviceProviderListFetchHasError) {
      return <Primer text={t("mediationTab.fetch_error", { error })} />;
    }

    return (
      <>
        <SearchAndFilterMediators
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
        />
        {serviceProviderListIsLoading ? (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.first_name")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.last_name")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.email")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.about")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.designation")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.services")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                <TableSkeleton numCols={5} />
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.first_name")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.last_name")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.email")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.about")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.designation")}</Th>
                  <Th sx={tableHeaderStyles}>{t("mediationTab.services")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {isFiltering ? (
                  Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <Tr key={index}>
                        {Array(10)
                          .fill(0)
                          .map((_, cellIndex) => (
                            <Td key={cellIndex}>
                              <Skeleton height="20px" />
                            </Td>
                          ))}
                      </Tr>
                    ))
                ) : serviceProviderListData?.length === 0 ? (
                  <Tr>
                    <Td colSpan={10}>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="100px"
                      >
                        <Text
                          fontSize="lg"
                          fontWeight="medium"
                          color={"red.500"}
                        >
                          {t("mediationTab.no_results_found")}
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                ) : (
                  serviceProviderListData.map((serviceProvider, index) => (
                    <Tr
                      key={index}
                      onClick={() => handleRowClick(serviceProvider)}
                      cursor={"pointer"}
                      _hover={{
                        bg: "gray.200",
                      }}
                    >
                      <Td sx={tableContentStyles}>
                        {
                          i18n.language === 'fr'
                            ? serviceProvider?.first_name // If French, use about_fr
                            : serviceProvider?.first_name   // Otherwise, use about
                        }
                      </Td>
                      <Td sx={tableContentStyles}>
                        {
                          i18n.language === 'fr'
                            ? serviceProvider?.last_name // If French, use about_fr
                            : serviceProvider?.last_name    // Otherwise, use about
                        }

                      </Td>
                      <Td sx={tableContentStyles}>{serviceProvider.email}</Td>
                      <Td
                        sx={{
                          ...tableContentStyles,
                          whiteSpace: "nowrap",          // Prevent wrapping to a new line
                          overflow: "hidden",            // Hide overflowed content
                          textOverflow: "ellipsis",       // Show ellipsis for overflowed content
                          maxWidth: "300px",             // Adjust the width as needed
                        }}
                      >
                        {
                          i18n.language === 'fr'
                            ? (serviceProvider?.about_fr?.length > 45
                              ? `${serviceProvider.about_fr.slice(0, 50)}...`
                              : serviceProvider.about_fr)  // If French, use about_fr
                            : (serviceProvider?.about?.length > 45
                              ? `${serviceProvider.about.slice(0, 50)}...`
                              : serviceProvider.about)     // Otherwise, use about
                        }
                      </Td>




                      <Td sx={tableContentStyles}>
                        {
                          i18n.language === 'fr'
                            ? serviceProvider?.designation_fr // If French, use about_fr
                            : serviceProvider?.designation    // Otherwise, use about
                        }
                      </Td>
                      <Td sx={tableContentStyles}>
                        {serviceProvider.consultation_specialities
                          .map((serviceObj) => {
                            // Check for French language
                            const serviceName = i18n.language === "fr" ? serviceObj?.name_fr : serviceObj?.name;

                            // Check if translation exists and use it if applicable
                            return serviceName;
                          })
                          .join(", ")}
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Flex justifyContent="flex-end" alignItems="center" mt={4}>
          <Text mr={4}>
            {t("mediationTab.rows_per_page")}:
            <Select
              value={limit}
              onChange={(e) => setLimit(Number(e.target.value))}
              display="inline-block"
              width="auto"
              ml={2}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Select>
          </Text>
          <Text mr={4}>
            {`${Math.min(offset + 1, totalResults)}-${Math.min(
              offset + limit,
              totalResults
            )} ${t("mediationTab.of")} ${totalResults}`}
          </Text>
          <Flex>
            <IconButton
              onClick={handleFirstPage}
              disabled={offset === 0}
              icon={<Image src={ToFirstPage} alt="First Page" />}
              aria-label={t("mediationTab.first_page")}
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handlePreviousPage}
              disabled={offset === 0}
              icon={<ChevronLeftIcon boxSize={6} />}
              aria-label={t("mediationTab.previous_page")}
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleNextPage}
              disabled={offset + limit >= totalResults}
              icon={<ChevronRightIcon boxSize={6} />}
              aria-label={t("mediationTab.next_page")}
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleLastPage}
              disabled={offset + limit >= totalResults}
              icon={<Image src={ToLastPage} alt="Last Page" />}
              aria-label={t("mediationTab.last_page")}
              border={"none"}
              bg={"none"}
            />
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <BrandHeader />
      <ToastContainer />
      <Flex
        direction={"column"}
        margin={3}
        border="1px"
        borderColor="gray.300"
        borderRadius={"8px"}
      >
        <Flex justifyContent={"space-between"}>
          <Box
            fontSize={"24px"}
            fontWeight={"500"}
            marginLeft={3}
            marginY={"12px"}
          >
            {t("mediationTab.service_providers")}
          </Box>

          <Button
            onClick={() => {
              dispatch(clearMediator());
              history.push('/admin/service-provider/new')
            }}
            background={"none"}
            fontSize={"18px"}
            fontWeight={"500"}
            marginY={"12px"}
            color={"rgb(255, 102, 102)"}
          >
            {t("mediationTab.invite_new_service_provider")}
          </Button>
        </Flex>
        {renderContent()}
      </Flex>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
  association: selectAssociation(state),
});

const ConnectedUserReports = connect(mapStateToProps)(CUserReports);

export default function ServiceProvidersList() {
  return <ConnectedUserReports />;
}
