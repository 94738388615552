const initialState = {
    selectedMediator: null,
  };
  
  const mediatorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SELECTED_MEDIATOR':
        return {
          ...state,
          selectedMediator: action.payload,
        };
        case "CLEAR_MEDIATOR":
          return {
            ...state,
            selectedMediator: null,
          };
      default:
        return state;
    }
  };
  
  export default mediatorReducer;