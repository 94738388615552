export const loginSuccess = ({
  accessToken,
  refreshToken,
  user,
  professional_profile,
  association,
}) => ({
  type: "LOGIN_SUCCESS",
  payload: {
    accessToken,
    refreshToken,
    user,
    professional_profile,
    association,
  },
});

export const loginFailure = (error) => ({
  type: "LOGIN_FAILURE",
  payload: { error },
});

export const refreshToken = (accessToken) => (
  {
    type: 'REFRESH_TOKEN',
    payload: accessToken,
  }
)

export const logout = () => ({
  type: 'LOGOUT'
});
