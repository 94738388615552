import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  Box,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

import RequestDetails from "./RequestDetails";
import Sessions from "./Session";
import RdvCommentsTab from "./RdvCommentsTab";
import CreateFirstSessionDialog from "./CreateFirstSessionDialog";

// Language Management
import { useTranslation } from "react-i18next";

// Dialogs
import CompleteRequest from "./CompleteRequest.dialog";
import locationService from "services/locationService";

const activeTabStyle = {
  color: "#FF6666",
  fontSize: "18px",
  fontWeight: "600",
};

const inactiveTabStyle = {
  color: "#565656",
  fontSize: "18px",
  fontWeight: "600",
};

const CAppointmentDetails = ({
  data,
  collaboratorsData,
  accessToken,
  onStateChange,
  association
}) => {
  console.log(data);
  // Init i18n
  const { t } = useTranslation();

  // State Management
  const [active, setActive] = useState("details");

  // Complete Request State
  const [sessionClosureDialogOpen, setSessionClosureDialogOpen] = useState(false);

  const [isFirstSessionDialogOpen, setIsFirstSessionDialogOpen] = useState(false);

  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(data.location);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    locationService.getAll(data.association).then((response) => {
      setAvailableLocations(response.data);
    });
  }, [data.association]);

  const lastCreatedSession = data?.rdv_sessions?.length > 0
    ? data.rdv_sessions.reduce((latest, session) => {
      return new Date(session.calendar_block.created_at) > new Date(latest.calendar_block.created_at) ? session : latest;
    })
    : "No session found"; // or any default value you want to return when there are no sessions



  return (
    <Box padding={4}>
      <Flex direction={"column"}>
        <Flex justify={"space-between"} alignItems={"center"} marginX={4} width={'98%'}>
          <Box color={"#252525"} fontSize={"22px"} fontWeight={"500"} >
            {t("admin__appointments__req_file")} &lt;
            {data.file_number}&gt;
          </Box>
          <Flex>
            {/* Dont show the button if the mediator is null */}
            {true && (
              <Tooltip label={t("action_can_only_done_by_mediator")} aria-label="Mediator Only Tooltip">
                <Box display="inline-block">
                  <Button
                    fontSize={"16px"}
                    backgroundColor={"gray.300"}
                    padding={2}
                    disabled={true}
                    sx={{
                      transition: 'all 0.2s ease-in-out',
                      _hover: { transform: 'scale(1.03)' },
                      _click: { bg: 'inherit' }
                    }}
                  >
                    {t("admin__appointments__complete_req")}
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Flex>
        <Flex
          height={1}
          width={"100%"}
          borderBottom={"solid 1px #E7E7E7"}
        ></Flex>
        <Flex justify={"space-between"} alignItems={"center"} marginX={4}>
          <Tabs
            onChange={(index) => setActive(index === 0 ? "details" : index === 1 ? "comments" : "sessions")}
            width="100%"
          >
            <Flex justifyContent="space-between" alignItems="center" width="100%">
              <TabList>
                <Tab
                  ml={5}
                  sx={active === "details" ? activeTabStyle : inactiveTabStyle}
                  _selected={{
                    color: '#FF6666',
                    borderBottom: '2px solid #FF6666',
                  }}
                  _focus={{ boxShadow: 'none' }}
                >
                  {t("admin__appointments__req_details")}
                </Tab>
                <Tab sx={active === "comments" ? activeTabStyle : inactiveTabStyle}
                  _selected={{
                    color: '#FF6666',
                    borderBottom: '2px solid #FF6666',
                  }}
                  _focus={{ boxShadow: 'none' }}
                >
                  {t("admin__appointments__comments")} ({data.thread.length})
                </Tab>
                <Tab sx={active === "sessions" ? activeTabStyle : inactiveTabStyle}
                  _selected={{
                    color: '#FF6666',
                    borderBottom: '2px solid #FF6666',
                  }}
                  _focus={{ boxShadow: 'none' }}
                >
                  {t("admin__appointments__sessions")} ({data.rdv_sessions.length})
                </Tab>
              </TabList>
              <Button
                background={"none"}
                fontSize={"18px"}
                fontWeight={"500"}
                marginY={"0px"}
                color={"rgb(255, 102, 102)"}
                isDisabled={data.is_closed}
                onClick={() => setIsFirstSessionDialogOpen(true)}
              >
                {t("add_new_session")}
              </Button>
            </Flex>

            <TabPanels>
              <TabPanel>
                <RequestDetails
                  data={data}
                  collaboratorsData={collaboratorsData}
                  accessToken={accessToken}
                  refresh={onStateChange}
                />
              </TabPanel>
              <TabPanel>
                <RdvCommentsTab
                  data={data}
                  accessToken={accessToken}
                  onStateChange={onStateChange}
                />
              </TabPanel>
              <TabPanel>
                <Sessions
                  collaboratorsData={collaboratorsData}
                  appointmentData={data}
                  accessToken={accessToken}
                  onStateChange={onStateChange}
                  association={association}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>

      {isFirstSessionDialogOpen && (
        <CreateFirstSessionDialog
          isOpen={isFirstSessionDialogOpen}
          onClose={() => setIsFirstSessionDialogOpen(false)}
          accessToken={accessToken}
          originalRdvId={data.id}
          allMediators={collaboratorsData}
          liftStateUp={() => onStateChange()}
          availableLocations={availableLocations}
          preferredLocation={lastCreatedSession?.location}
          proposedDate={data.rdv_sessions.length === 0 ? data.date : new Date()}
          proposedTime={data.rdv_sessions.length === 0 ? data.time : ""}
          serviceId={data.service.id}
          isFirstSession={data.rdv_sessions.length === 0}
          principalParticipant={data.participants[0].name}
          association={association}
        />
      )}

      <CompleteRequest
        isOpen={sessionClosureDialogOpen}
        onClose={() => setSessionClosureDialogOpen(false)}
        accessToken={accessToken}
        originalRdvId={data.id}
      />
    </Box>
  );
};

export default function AppointmentDetails({
  data,
  collaboratorsData,
  accessToken,
  onStateChange,
  association
}) {
  return (
    <CAppointmentDetails
      data={data}
      collaboratorsData={collaboratorsData}
      accessToken={accessToken}
      onStateChange={onStateChange}
      association={association}
    />
  );
}