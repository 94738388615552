import React, { useEffect, useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  
  const getBaseLanguage = (lang) => {
    return lang.split('-')[0];
  };

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.options.fallbackLng[0] || getBaseLanguage(i18n.language));

  useEffect(() => {
    setSelectedLanguage(getBaseLanguage(i18n.language));
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  return (
    <Box>
      <Button
        backgroundColor={selectedLanguage === 'fr' ? '#FF6666' : 'white'}
        color={selectedLanguage === 'fr' ? 'white' : '#FF6666'}
        border={selectedLanguage === 'fr' ? 'none' : ''}
        mr={2}
        _hover={{ bg: '#FF4C4C', color: 'white' }}
        width="33px"
        height="28px"
        padding="0"
        outline="none"
        _focus={{ outline: 'none', boxShadow: 'none', }} 
        onClick={() => changeLanguage('fr')}
      >
        Fr
      </Button>
      <Button
        backgroundColor={selectedLanguage === 'en' ? '#FF6666' : 'white'}
        color={selectedLanguage === 'en' ? 'white' : '#FF6666'}
        border={selectedLanguage === 'en' ? 'none' : ''}
        _hover={{ bg: '#FF4C4C', color: 'white' }}
        width="33px"
        height="28px"
        padding="0"
        outline="none"
        _focus={{ outline: 'none', boxShadow: 'none', }} 
        onClick={() => changeLanguage('en')}
      >
        En
      </Button>
    </Box>
  );
};

export default LanguageSelector;
