import React, { useState, useEffect } from 'react';
import {
  Box, SimpleGrid, FormControl, FormLabel, Input,
  Button, Flex, FormErrorMessage
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const NewLocationForm = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    department_code: '',
  });
  const [errors, setErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({
    name: false,
    address: false,
    department_code: false,
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouchedFields(prev => ({
      ...prev,
      [name]: true,
    }));
  };

  const validate = () => {
    let newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = t("locationTab.name_error");
    }
    if (!formData.address.trim()) {
      newErrors.address = t("locationTab.address_error");
    }
    if (!formData.department_code.trim()) {
      newErrors.department_code = t("locationTab.department_code_error");
    } else if (!/^\d{2}$/.test(formData.department_code)) {
      newErrors.department_code = t("locationTab.department_code_format_error");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    setIsFormValid(validate());
  }, [formData]);

  const handleSubmit = () => {
    // Mark all fields as touched
    setTouchedFields({
      name: true,
      address: true,
      department_code: true,
    });

    if (validate()) {
      onSubmit(formData);
    }
  };

  const isAnyFieldTouched = Object.values(touchedFields).some(Boolean);

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 1 }} spacing={6}>
        <FormControl isRequired isInvalid={touchedFields.name && !!errors.name}>
          <FormLabel>{t("locationTab.name")}</FormLabel>
          <Input
            name="name"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("locationTab.name_placeholder")}
          />
          {touchedFields.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
        </FormControl>

        <FormControl isRequired isInvalid={touchedFields.address && !!errors.address}>
          <FormLabel>{t("locationTab.address")}</FormLabel>
          <Input
            name="address"
            value={formData.address}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("locationTab.address_placeholder")}
          />
          {touchedFields.address && <FormErrorMessage>{errors.address}</FormErrorMessage>}
        </FormControl>

        <FormControl isRequired isInvalid={touchedFields.department_code && !!errors.department_code}>
          <FormLabel>{t("locationTab.department_code")}</FormLabel>
          <Input
            name="department_code"
            value={formData.department_code}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t("locationTab.department_code_placeholder")}
          />
          {touchedFields.department_code && <FormErrorMessage>{errors.department_code}</FormErrorMessage>}
        </FormControl>
      </SimpleGrid>

      <Flex justifyContent="flex-end" mt={6}>
        <Button
          colorScheme="red"
          onClick={handleSubmit}
          isDisabled={!isAnyFieldTouched || !isFormValid}

        >
          {t("locationTab.add_location")}
        </Button>
        <Button variant="outline" ml={3} onClick={onClose}>
          {t("locationTab.cancel")}
        </Button>
      </Flex>
    </Box>
  );
};

export default NewLocationForm;