export class ServiceProvider {
  constructor({
    id,
    association,
    email,
    is_active,
    image,
    first_name,
    first_name_fr,
    last_name,
    last_name_fr,
    designation,
    designation_fr,
    about,
    about_fr,
    address,
    country_code,
    phone_number,
    linkedin_url,
    facebook_url,
    instagram_url,
    consultation_duration,
    consultation_fees,
    consultant_aggregator_id,
    is_consultation_possible,
    created_at,
    updated_at,
    user,
    locations,
    services,
  }) {
    this.id = id;
    this.association = association;
    this.email = email;
    this.is_active = is_active;
    this.image = image;
    this.first_name = first_name;
    this.first_name_fr = first_name_fr;
    this.last_name = last_name;
    this.last_name_fr = last_name_fr;
    this.designation = designation;
    this.designation_fr = designation_fr;
    this.about = about;
    this.about_fr = about_fr;
    this.address = address;
    this.country_code = country_code;
    this.phone_number = phone_number;
    this.linkedin_url = linkedin_url;
    this.facebook_url = facebook_url;
    this.instagram_url = instagram_url;
    this.consultation_duration = consultation_duration;
    this.consultation_fees = consultation_fees;
    this.consultant_aggregator_id = consultant_aggregator_id;
    this.is_consultation_possible = is_consultation_possible;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.user = user;
    this.locations = locations;
    // Services is an array of Service objects [{"name":"", "description":"}, ...]
    // Convert the arrat of objects to a comma-separated string of names
    this.services = services.map((service) => service.name).join(", ");
  }

  static fromJSON(json) {
    return new ServiceProvider(json);
  }

  static fromJSONList(jsonList) {
    return jsonList.map((json) => ServiceProvider.fromJSON(json));
  }
}
