import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Text,
  Flex,
  Textarea,
  Input,
  VStack,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  HStack,
  Tag,
  TagLabel,
  Checkbox
} from "@chakra-ui/react";
import { FaEye, FaCloudUploadAlt, FaCheck, FaTimes, FaShareAlt } from "react-icons/fa";

import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import documentService from "services/documentService";
import rdvSessionService from "services/rdvSessionService";
import GenericAlertDialog from "./GenericAlertDialog";
import CustomModal from "./CustomModal";
import Utils from "services/Utils";

const DocumentsTab = ({ collaboratorsData, sessionId, accessToken, documents: initialDocuments, liftStateUp }) => {
  const { t } = useTranslation();
  console.log({ collaboratorsData });
  const [documents, setDocuments] = useState(initialDocuments);
  const [newDocument, setNewDocument] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [comment, setComment] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [errors, setErrors] = useState({ displayName: "", comment: "" });
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [filteredDocuments, setFilteredDocuments] = useState(initialDocuments);


  // Alert Dialog states
  const { isOpen: isAlertDialogOpen, onOpen: onAlertDialogOpen, onClose: onAlertDialogClose } = useDisclosure();
  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [alertDialogBody, setAlertDialogBody] = useState("");
  const [alertDialogConfirm, setAlertDialogConfirm] = useState(null);
  const [alertDialogOnClose, setAlertDialogOnClose] = useState(null);


  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);


  const initialDocumentsRef = useRef(null);
  const finalDocumentsRef = useRef(null);

  useEffect(() => {
    setDocuments(initialDocuments);
  }, [initialDocuments]);

  useEffect(() => {
    applyFilter();
  }, [selectedFilter, documents]);


  const applyFilter = () => {
    let filtered;
    switch (selectedFilter) {
      case "Attestation":
        filtered = documents.filter(doc => doc.comment === "Generated Attestation");
        break;
      case "Payment":
        filtered = documents.filter(doc => doc.comment === "Generated Payment Ticket");
        break;
      case "Divers":
        filtered = documents.filter(
          doc => doc.comment !== "Generated Attestation" && doc.comment !== "Generated Payment Ticket"
        );
        break;
      default:
        filtered = documents;
    }
    setFilteredDocuments(filtered);
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };


  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setNewDocument(acceptedFiles[0]);
      setDisplayName(acceptedFiles[0].name);
      setError(null);
      setIsFileUploaded(true);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
  });

  const validateForm = () => {
    const newErrors = { displayName: "", comment: "" };

    if (!displayName.trim()) {
      newErrors.displayName = t("display_name_required");
    }
    if (!comment.trim()) {
      newErrors.comment = t("comment_required");
    }

    setErrors(newErrors);

    // Return true if no errors, false otherwise
    return !newErrors.displayName && !newErrors.comment;
  };


  const handleUpload = async () => {
    if (!newDocument) {
      setError(t("file_required"));
      return;
    }
    if (!displayName) {
      setError(t("display_name_required"));
      return;
    }

    try {
      const res = await documentService.startUpload(newDocument.name, newDocument.size, newDocument.type, displayName, comment, accessToken);
      const url = res.url;
      const idfile = res.id;

      await documentService.uploadToS3(url, newDocument);
      await documentService.finishUpload(idfile, accessToken);
      await rdvSessionService.update({
        pk: sessionId,
        accessToken: accessToken,
        payload: {
          documents: [...documents.map(doc => doc.id), idfile],
        },
      });

      toast.success(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("document_uploaded")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );

      resetForm();
      setIsDocumentsModalOpen(false); // Close the modal after upload
      liftStateUp(); // Refresh state
    } catch (error) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("document_upload_failed")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
      console.error(error);
    }
  };

  const resetForm = () => {
    setNewDocument(null);
    setDisplayName("");
    setComment("");
    setIsFileUploaded(false);
  };

  const handlePreview = (document) => {
    window.open(document.file, "_blank");
  };

  const handleEditNote = (document) => {
    setSelectedDocument(document);
    setDisplayName(document.display_name);
    setComment(document.comment || "");
    setIsModalOpen(true);
  };

  const handleSaveNote = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      await rdvSessionService.updateDoc({
        pk: sessionId,
        payload: {
          display_name: displayName,
          comment: comment,
        },
        docId: selectedDocument.id,
      });
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("note_saved")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
      handleCloseModal();
      liftStateUp();
    } catch (error) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("note_save_failed")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedDocument(null);
    resetForm();
  };

  const handleRemoveDoc = (docId, docDisplayName) => {
    openGenericAlertDialog(
      t("messages.removeDocConfirmation", { docDisplayName }),
      t("messages.removeDocWarning", { docDisplayName }),
      async () => {
        await rdvSessionService.deleteDoc(sessionId, docId);
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaCheck style={{ marginRight: '8px' }} />
            {t("messages.changesSaved")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "#FF6666",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            icon: false
          }
        );
        liftStateUp();
      },
      () => {
        console.log("Closed without confirming.");
      }
    );
  };

  const handleCollaboratorSelect = (e, collaboratorId) => {
    if (e.target.checked) {
      setSelectedCollaborators((prev) => [...prev, collaboratorId]);
    } else {
      setSelectedCollaborators((prev) =>
        prev.filter((id) => id !== collaboratorId)
      );
    }
  };

  const handleShareDocument = async () => {
    try {
      console.log(selectedDocument.id);
      console.log(selectedCollaborators);
      await documentService.share(
        sessionId,
        selectedDocument.id,
        selectedCollaborators,
        accessToken
      );

      toast.success(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("messages.document_shared_successfully")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );

      setIsShareModalOpen(false);
      setSelectedCollaborators([]);
    } catch (error) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.document_share_failed")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
      console.error(error);
    }
  };


  const openGenericAlertDialog = (title, body, confirm, close) => {
    setAlertDialogTitle(title);
    setAlertDialogBody(body);
    setAlertDialogConfirm(() => confirm);
    setAlertDialogOnClose(() => close);
    onAlertDialogOpen();
  };

  const handleConfirm = () => {
    alertDialogConfirm();
    onAlertDialogClose();
  };

  const handleCloseAlertDialog = () => {
    alertDialogOnClose();
    onAlertDialogClose();
  };

  const tableHeaderStyles = {
    textAlign: "start",
    fontSize: "16px",
    fontWeight: "500",
    color: "#252525",
  };

  const tableContentStyles = {
    fontSize: "16px",
    fontWeight: "400",
    color: "#565656",
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" m={3}>
      <Flex justify="space-between" p={4} borderBottomWidth="1px">
        <Text fontSize="2xl" fontWeight="semibold">
          {t("documents")}
        </Text>
        <Button
          fontSize={"16px"}
          fontWeight={"500"}
          marginLeft={3}
          marginY={"auto"}
          color={"#FF6666"}
          bg={"none"}
          onClick={() => setIsDocumentsModalOpen(true)}
          isDisabled={false}
        >
          {t("admin__appointments_table__add_document")}
        </Button>
      </Flex>
      <HStack spacing={4} p={4}>
        {["All", "Attestation", "Payment", "Divers"].map((filter) => (
          <Tag
            key={filter}
            size="md"
            borderRadius="full"
            variant={selectedFilter === filter ? "solid" : "outline"}
            bg={selectedFilter === filter ? "#FF6666" : "transparent"}
            color={selectedFilter === filter ? "white" : "gray.500"}
            borderColor={selectedFilter === filter ? "#FF6666" : "gray.200"}
            onClick={() => handleFilterChange(filter)}
            cursor="pointer"
          >
            <TagLabel>{t(`filters.${filter.toLowerCase()}`)}</TagLabel>
          </Tag>
        ))}
      </HStack>

      <TableContainer overflow={'scroll'} width={'auto'}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th sx={tableHeaderStyles}>{t("document_name")}</Th>
              <Th sx={tableHeaderStyles}>{t("document_size")}</Th>
              <Th sx={tableHeaderStyles}>{t("document_type")}</Th>
              <Th sx={tableHeaderStyles}>{t("document_uploaded_by")}</Th>
              <Th sx={tableHeaderStyles}>{t("document_uploaded_on")}</Th>
              <Th sx={tableHeaderStyles}>{t("document_note")}</Th>
              <Th sx={tableHeaderStyles}>{t("actions")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredDocuments?.length === 0 ? (
              <Tr>
                <Td colSpan={7} textAlign="center">
                  <Text fontSize="18px" color="gray.500">
                    {t("no_documents_found")}
                  </Text>
                </Td>
              </Tr>
            ) : (
              filteredDocuments?.map((document, index) => (
                <Tr key={`session-document-${document.id}`}>
                  <Td sx={tableContentStyles}>
                    {document.display_name.length > 15
                      ? `${document.display_name.substring(0, 20)}...`
                      : document.display_name}
                  </Td>
                  <Td sx={tableContentStyles}>{Utils.formatFileSize(document.size)}</Td>
                  <Td sx={tableContentStyles}>{document.file_type}</Td>
                  <Td sx={tableContentStyles}>{document.association__uploaded_by?.name}</Td>
                  <Td sx={tableContentStyles}>{`${Utils.convertUTCToLocalDate(document.upload_finished_at)}, ${Utils.convertUTCToLocalTimeZone(new Date(document.upload_finished_at).toISOString().split("T")[1].slice(0, 5))}h`}</Td>
                  <Td sx={tableContentStyles}>
                    {document.comment.length > 15
                      ? `${document.comment.substring(0, 20)}...`
                      : document.comment}
                  </Td>
                  <Td sx={tableContentStyles}>
                    <IconButton
                      icon={<FaEye />}
                      aria-label={t("preview")}
                      mr={2}
                      size="sm"
                      onClick={() => handlePreview(document)}
                    />
                    <IconButton
                      icon={<EditIcon />}
                      size="sm"
                      mr={2}
                      disabled={document.comment === 'Generated Attestation' || document.comment === 'Generated Payment Ticket'}
                      onClick={() => handleEditNote(document)}
                    />
                    <IconButton
                      icon={<FaShareAlt />}
                      size="sm"
                      mr={2}
                      onClick={() => {
                        setSelectedDocument(document);
                        setIsShareModalOpen(true);
                      }}
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label={t("delete")}
                      mr={2}
                      size="sm"
                      colorScheme="red"
                      disabled={document.comment === 'Generated Attestation' || document.comment === 'Generated Payment Ticket'}
                      onClick={() => handleRemoveDoc(document.id, document.display_name)}
                    />
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>

        </Table>
      </TableContainer>

      {isDocumentsModalOpen && (
        <Modal
          initialFocusRef={initialDocumentsRef}
          finalFocusRef={finalDocumentsRef}
          isOpen={isDocumentsModalOpen}
          onClose={() => setIsDocumentsModalOpen(false)}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={"36px"} color={"#252525"} fontWeight={400} mx={"auto"}>
              {t("admin__appointments_table__document_details")}
            </ModalHeader>
            <ModalBody>
              <Text mb={2}>{t("select_file")}:</Text>
              <Box
                {...getRootProps()}
                p={6}
                border="2px dashed"
                borderColor={isDragActive ? "blue.400" : isFileUploaded ? "green.400" : "gray.200"}
                borderRadius="md"
                bg={isDragActive ? "blue.50" : isFileUploaded ? "green.50" : "gray.50"}
                transition="all 0.3s"
                cursor="pointer"
                _hover={{
                  borderColor: "blue.300",
                  bg: "blue.50",
                }}
              >
                <input {...getInputProps()} />
                <VStack spacing={3}>
                  <Icon as={FaCloudUploadAlt} w={12} h={12} color={isDragActive ? "blue.400" : isFileUploaded ? "green.400" : "gray.400"} />
                  <Text textAlign="center" fontWeight="medium">
                    {isDragActive
                      ? t("drop_files_here")
                      : isFileUploaded
                        ? t("file_uploaded")
                        : t("drag_drop_files_here")}
                  </Text>
                  {isFileUploaded && (
                    <Text fontSize="sm" color="green.600">
                      {displayName}
                    </Text>
                  )}
                </VStack>
              </Box>
              <Text mt={2} mb={2}>{t("display_name")}<Text as="span" color="red.500">*</Text>:</Text>
              <Input placeholder={t("enter_display_name")} value={displayName} onChange={(e) => setDisplayName(e.target.value)} mb={2} />
              <Text mb={2}>{t("comment")}<Text as="span" color="red.500">*</Text>:</Text>
              <Input placeholder={t("enter_comment")} value={comment} onChange={(e) => setComment(e.target.value)} mb={2} />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4} justify="center" width="100%">
                <Button
                  fontSize={"18px"}
                  colorScheme="red"
                  onClick={handleUpload}
                  isDisabled={!newDocument || !displayName || !comment}
                >
                  {t("admin__appointments_table__add_document")}
                </Button>
                <Button onClick={() => {
                  resetForm();
                  setIsDocumentsModalOpen(false);
                }}>{t("cancel")}</Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <Text fontSize={"36px"} color={"#252525"} fontWeight={400} mx={"auto"} mb={4}>
          {t("edit_note")}
        </Text>
        <Text mb={2}>{t("display_name")}<Text as="span" color="red.500">*</Text>:</Text>
        <Input
          placeholder={t("enter_display_name")}
          value={displayName}
          onChange={(e) => {
            setDisplayName(e.target.value);
            setErrors({ ...errors, displayName: "" });  // Clear the error when the user types
          }}
          mb={2}
          isInvalid={!!errors.displayName}
        />
        {errors.displayName && (
          <Text color="red.500" mb={2}>
            {errors.displayName}
          </Text>
        )}
        <Text mb={2}>{t("comment")}<Text as="span" color="red.500">*</Text>:</Text>
        <Textarea
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
            setErrors({ ...errors, comment: "" });
          }}
          placeholder={t("enter_comment")}
          mb={4}
          isInvalid={!!errors.comment}
        />
        {errors.comment && (
          <Text color="red.500" mb={4}>
            {errors.comment}
          </Text>
        )}
        <Button
          colorScheme="red"
          onClick={handleSaveNote}
          mr={2}
        >
          {t("save_note")}
        </Button>
      </CustomModal>


      <Modal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="36px" color="#252525" fontWeight={400} mx="auto">
            {t("share_document")}
          </ModalHeader>
          <ModalBody>
            <Text mb={2} fontWeight='bold'>{t("select_collaborators")}</Text>
            <Box mt={'4'}>
              {collaboratorsData?.map((collaborator) => (
                <Box display='flex' justifyContent='flex-start' m='2'>
                  <Checkbox
                    key={collaborator.id}
                    value={collaborator.id}
                    isChecked={selectedCollaborators.includes(collaborator.id)}
                    onChange={(e) => handleCollaboratorSelect(e, collaborator.id)}
                    colorScheme='red'
                    size='md'
                  >
                    <Text fontSize={'xl'}>{`${collaborator.first_name} ${collaborator.last_name}`}</Text>
                  </Checkbox>
                </Box>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleShareDocument} mr={2}>
              {t("share")}
            </Button>
            <Button onClick={() => setIsShareModalOpen(false)}>{t("cancel")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <GenericAlertDialog
        isOpen={isAlertDialogOpen}
        title={alertDialogTitle}
        body={alertDialogBody}
        onClose={handleCloseAlertDialog}
        confirm={handleConfirm}
      />
    </Box>
  );
};

export default DocumentsTab;
