import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import DocumentSection from "./DocumentSection";
import ChildSection from "./ChildSection";
import ParticipantSection from "./ParticipantSection";
import { useTranslation } from "react-i18next";
import rdvService from "services/rdvService";
import Utils from "services/Utils";
import { toast } from "react-toastify";
import { FaCheck, FaTimes } from 'react-icons/fa';

const RequestDetails = ({ data, collaboratorsData, accessToken, refresh }) => {
  const { t, i18n } = useTranslation();
  const [legalMode, setLegalMode] = useState(data.nature);
  const [contextJurid, setContextJurid] = useState(data.legal_context);
  const [subject, setSubject] = useState(data.subject);
  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [alertDialogBody, setAlertDialogBody] = useState("");
  const [alertDialogOnClose, setAlertDialogOnClose] = useState(() => () => { });
  const [alertDialogConfirm, setAlertDialogConfirm] = useState(() => () => { });
  const finalParticipantRef = useRef(null);
  const finalChildrenRef = useRef(null);
  const initialDocumentsRef = useRef(null);
  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose,
  } = useDisclosure();

  const openGenericAlertDialog = (title, body, confirm, close) => {
    setAlertDialogTitle(title);
    setAlertDialogBody(body);
    setAlertDialogConfirm(() => confirm);
    setAlertDialogOnClose(() => close);
    onAlertDialogOpen();
  };

  const handleConfirm = () => {
    alertDialogConfirm();
    onAlertDialogClose();
  };

  const handleClose = () => {
    alertDialogOnClose();
    onAlertDialogClose();
  };

  return (
    <Box height="100%" overflowY="auto" padding={4}>
      <Button ref={finalParticipantRef} hidden>
        Focus anchor for participant modal
      </Button>
      <Button ref={finalChildrenRef} hidden>
        Focus anchor for children modal
      </Button>
      <Flex direction={"column"} width={"100%"} paddingBottom={5}>
        <FormControl
          ml={4}
          marginY={5}
          width={"100%"}
          isDisabled={data.is_closed}
        >
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments__status")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>

          <Box ml={1} mb={5}>
            <AppointmentStatusLabel {...data} />
          </Box>

          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__file_number")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="2445524"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.file_number}
            contentEditable={false}
            readOnly
          />

          <Flex direction="row" width="100%" alignItems="flex-start" justifyContent="space-between">
            <Box width="30%" mb={5}>
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__location")}
                <Text ml={1} color={"#EE0000"}>
                  *
                </Text>
              </FormLabel>
              <Input
                isRequired={true}
                bg={"gray.100"}
                width={"100%"}
                type="title"
                placeholder="Location"
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                value={data?.location?.name ? data?.location?.name : 'N/A'}
                contentEditable={false}
                readOnly
              />
            </Box>
            <Box width="30%" mb={5}>
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__address")}
                <Text ml={1} color={"#EE0000"}>
                  *
                </Text>
              </FormLabel>
              <Input
                isRequired={true}
                bg={"gray.100"}
                width={"100%"}
                type="title"
                placeholder="Location"
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                value={data?.location?.address ? data?.location?.address : "N/A"}
                contentEditable={false}
                readOnly
              />
            </Box>
            <Box width="30%" mb={5}>
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__department")}
                <Text ml={1} color={"#EE0000"}>
                  *
                </Text>
              </FormLabel>
              <Input
                isRequired={true}
                bg={"gray.100"}
                width={"100%"}
                type="title"
                placeholder="Location"
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                value={data?.location?.department ? data?.location?.department : "N/A"}
                contentEditable={false}
                readOnly
              />
            </Box>
          </Flex>

          {/* <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__service")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="Mediation"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            value={i18n.language == 'fr' ? data.rdv_sessions[0]?.consultation_mode?.name_fr : data.rdv_sessions[0]?.consultation_mode?.name}
            mb={5}
            contentEditable={false}
            readOnly
          /> */}

          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__service")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="Mediation"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            value={i18n.language == 'fr' ? data.service.name_fr : data.service.name}
            mb={5}
            contentEditable={false}
            readOnly
          />

          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__proposed_date")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="14 April 2024"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={Utils.intoDDMMYYYY(data.date)}
            contentEditable={false}
            readOnly
          />
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__proposed_time")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="11:30"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.time ? `${Utils.convertUTCToLocalTimeZone(data.time)} h` : "N/A"}
            contentEditable={false}
            readOnly
            color={"#000000"}
          />

          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__addional_info")}
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="11:30"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.additional_info ? data.additional_info : t("no_addional_info_provided")}
            contentEditable={false}
            readOnly
            color={"#000000"}
          />

          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__principle_mediator")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder=""
            fontSize={"14px"}
            fontWeight={"400"}
            color="black"
            borderRadius="4px"
            mb={5}
            value={data.mediator !== null ? `${data.mediator.first_name} ${data.mediator.last_name}` : t("unassigned")}
            contentEditable={false}
            readOnly
          />

          <Flex>
            <Box width="48%">
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__legal_mode")}
                <Text ml={1} color={"#EE0000"}>
                  *
                </Text>
              </FormLabel>
              <Select
                bg={"gray.100"}
                isRequired={true}
                width={"100%"}
                placeholder={t("select_option")}
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                mb={5}
                value={data.nature}
                onChange={(e) => {
                  setLegalMode(e.target.value);
                  const patchPayload = {
                    nature: e.target.value,
                  };
                  if (patchPayload.nature === "Conventionnel") {
                    patchPayload.legal_context = "None";
                  }
                  rdvService
                    .update({
                      pk: data.id,
                      accessToken: accessToken,
                      obj: patchPayload,
                    })
                    .then((res) => {
                      refresh();
                      toast.success(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <FaCheck style={{ marginRight: '8px' }} />
                          {t("messages.changesSaved")}
                        </div>,
                        {
                          position: "bottom-center",
                          style: {
                            backgroundColor: "#FF6666",
                            color: "white",
                            width: 'auto',
                            minWidth: '400px'
                          },
                          icon: false
                        }
                      );
                    });
                }}
              >
                <option value="Conventionnel">{t("legal_mode__conventionnel")}</option>
                <option value="Judiciaire">{t("legal_mode__judiciaire")}</option>
                <option value="TMFPO">{t("legal_mode__tmfpo")}</option>
              </Select>
            </Box>

            <Box ml={5} width="48%">
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__context_jurid")}
              </FormLabel>
              <Select
                isRequired={true}
                bg={"gray.100"}
                width={"100%"}
                placeholder={t("select_option")}
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                mb={5}
                disabled={data.nature === "Conventionnel"}
                value={data.legal_context}
                onChange={(e) => {
                  setContextJurid(e.target.value);
                  rdvService
                    .update({
                      pk: data.id,
                      accessToken: accessToken,
                      obj: {
                        legal_context: e.target.value,
                      },
                    })
                    .then((res) => {
                      refresh();
                      toast.success(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <FaCheck style={{ marginRight: '8px' }} />
                          {t("messages.changesSaved")}
                        </div>,
                        {
                          position: "bottom-center",
                          style: {
                            backgroundColor: "#FF6666",
                            color: "white",
                            width: 'auto',
                            minWidth: '400px'
                          },
                          icon: false
                        }
                      );
                    });
                }}
              >
                <option value="Double convocation">
                  {t("legal_context__double_convocation")}
                </option>
                <option value="Injonction">
                  {t("legal_context__injonction")}
                </option>
                <option value="Ordonnance">
                  {t("legal_context__ordonnance")}
                </option>
                <option value="Cadre judiciaire sur invitation écrite de la Juridiction">
                  {t("legal_context__cadre_judiciaire")}
                </option>
                <option value="TMFPO">
                  {t("legal_context__tmfpo")}
                </option>
              </Select>
            </Box>

          </Flex>
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__subject")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Select
            bg={"gray.100"}
            isRequired={true}
            width={"100%"}
            placeholder={t("select_option")}
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.subject}
            onChange={(e) => {
              setSubject(e.target.value);
              rdvService
                .update({
                  pk: data.id,
                  accessToken: accessToken,
                  obj: {
                    subject: e.target.value,
                  },
                })
                .then((res) => {
                  refresh();
                  toast.success(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FaCheck style={{ marginRight: '8px' }} />
                      {t("messages.changesSaved")}
                    </div>,
                    {
                      position: "bottom-center",
                      style: {
                        backgroundColor: "#FF6666",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                      },
                      icon: false
                    }
                  );
                });
            }}
          >
            <option value={t("family_topic__decision_support")}>
              {t("family_topic__decision_support")}
            </option>
            <option value={t("family_topic__custody_arrangement_coparenting")}>
              {t("family_topic__custody_arrangement_coparenting")}
            </option>
            <option value={t("family_topic__education_maintenance_contribution")}>
              {t("family_topic__education_maintenance_contribution")}
            </option>
            <option value={t("family_topic__other_miscellaneous_situations")}>
              {t("family_topic__other_miscellaneous_situations")}
            </option>
            <option value={t("family_topic__same_sex_family")}>
              {t("family_topic__same_sex_family")}
            </option>
            <option value={t("family_topic__blended_family")}>
              {t("family_topic__blended_family")}
            </option>
            <option value={t("family_topic__siblings")}>
              {t("family_topic__siblings")}
            </option>
            <option value={t("family_topic__grandparents_children_grandchildren")}>
              {t("family_topic__grandparents_children_grandchildren")}
            </option>
            <option value={t("family_topic__parents_teenagers")}>
              {t("family_topic__parents_teenagers")}
            </option>
            <option value={t("family_topic__parents_young_adults")}>
              {t("family_topic__parents_young_adults")}
            </option>
            <option value={t("family_topic__separation_divorce")}>
              {t("family_topic__separation_divorce")}
            </option>
          </Select>
        </FormControl>
        <ParticipantSection data={data} accessToken={accessToken} refresh={refresh} />
        <ChildSection data={data} accessToken={accessToken} refresh={refresh} t={t} />
        <DocumentSection data={data} accessToken={accessToken} refresh={refresh} t={t} />
      </Flex>
    </Box>
  );
};

function AppointmentStatusLabel(appointment) {
  const { t } = useTranslation();
  let status = t("admin__appointments__in_progress");
  let statusColor = "#199CD6";
  let statusBgColor = "#E4F5FC";

  if (appointment.mediator === null) {
    status = t("admin__appointments__new");
    statusColor = "#FF6666";
    statusBgColor = "#FFEEEE";
  }

  if (appointment.is_closed) {
    status = t("admin__appointments__complete");
    statusColor = "#9FB80A";
    statusBgColor = "#F7F9ED";
  }

  return (
    <Box
      color={statusColor}
      bgColor={statusBgColor}
      borderRadius={"8px"}
      padding={"4px 8px"}
      textAlign={"center"}
      width={"fit-content"}
    >
      {status}
    </Box>
  );
}

export default RequestDetails;
