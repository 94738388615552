import axiosInstance from '../axiosConfig'; // Import the configured Axios instance
import axios from 'axios'
const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const documentService = {
  getAll: async (sessionId) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/collaboration-api/sessions/${sessionId}/documents`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch documents.');
    }
  },

  startUpload: async (filename, fizesize, filetype, display_name, comment) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL}/collaboration-api/upload/direct/start/`,
        {
          file_name: filename,
          file_type: filetype,
          metadata: {
            uploaded_from: 'vault',
          },
          category: 'PS',
          display_name: display_name,
          household: null,
          shared_with: [],
          size: fizesize,
          comment: comment
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error('Failed to start upload.');
    }
  },

  uploadToS3: async (url, file) => {
    try {
      await axios.put(url, file);
    } catch (error) {
      throw new Error('Failed to upload to S3.');
    }
  },
  
  finishUpload: async (fileId) => {
    try {
      await axiosInstance.post(
        `${API_URL}/collaboration-api/upload/direct/finish/`,
        {
          file_id: fileId,
        }
      );
    } catch (error) {
      console.log(error);
      throw new Error('Failed to finish upload.');
    }
  },

  rename: async (documentId, data) => {
    try {
      const response = await axiosInstance.patch(`${API_URL}/documents/${documentId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Failed to rename document.');
    }
  },

  delete: async (documentId) => {
    try {
      const response = await axiosInstance.delete(`${API_URL}/documents/${documentId}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to delete document.');
    }
  },

  share: async(sessionId, documentId, payload, accessToken) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/collaboration-api/secretary/rdv/session/${sessionId}/document/${documentId}/email/`
      ,
      {
        'mediator_ids': payload
      }
      ,
      {
        headers:`Bearer ${accessToken}`
      }
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch documents.');
    }
  }
};

export default documentService;
