import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useDisclosure,
  IconButton,
  Tooltip,
  Input,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import ParticipantModal from "./ParticipantModal";
import { toast } from "react-toastify";
import rdvService from "services/rdvService";
import GenericAlertDialog from "./GenericAlertDialog";
import { useTranslation } from "react-i18next";
import { FaCheck, FaTimes } from 'react-icons/fa';

const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  fontWeight: "500",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^(?:\+?[1-9]\d{1,14}|\d{10})$/;
const postalCodeRegex = /^\d{5}$/; // Basic 5-digit postal code

const validateEmail = (email) => emailRegex.test(email);
const validatePhone = (phone) => phoneRegex.test(phone);
const validatePostalCode = (postalCode) => postalCodeRegex.test(postalCode);

const ParticipantSection = ({ data, accessToken, refresh }) => {
  const { t } = useTranslation();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertBody, setAlertBody] = useState("");
  const [alertConfirmCallback, setAlertConfirmCallback] = useState(() => () => { });
  const initialParticipantRef = useRef(null);
  const finalParticipantRef = useRef(null);
  
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredParticipants, setFilteredParticipants] = useState(data.participants);

  useEffect(() => {
    setFilteredParticipants(data.participants);
  }, [data.participants]);

  const handleAddParticipant = useCallback(async (participantForm) => {
    try {
      await rdvService.addParticipant({
        pk: data.id,
        participant: participantForm,
        accessToken: accessToken,
      });
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("messages.participantAdded")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
      refresh();
    } catch (error) {
      console.error("Failed to add participant:", error);
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.failedToAddParticipant")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  }, [data?.id, accessToken, refresh, t]);

  const handleEditParticipant = useCallback(async (participantForm) => {
    try {
      await rdvService.updateParticipantInfo({
        rdvId: data.id,
        participantId: currentParticipant.id,
        obj: participantForm,
        accessToken,
      });
      toast.success(
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("messages.changesSaved")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
      refresh();
    } catch (error) {
      console.error("Failed to update participant:", error);
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <FaTimes style={{ marginRight: '8px', color: 'white' }} />
          {t("messages.failedToUpdateParticipant")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  }, [data?.id, currentParticipant, accessToken, refresh, t]);

  const validateParticipantForm = (form) => {
    const errors = {};

    if (!form.name.trim()) {
      errors.name = t('errors.nameRequired');
    } else if (form.name.length < 2 || form.name.length > 50) {
      errors.name = t('errors.nameLengthInvalid');
    }

    if (!form.status) {
      errors.status = t('errors.statusRequired');
    }

    if (!form.address.trim()) {
      errors.address = t('errors.addressRequired');
    } else if (form.address.length < 5 || form.address.length > 100) {
      errors.address = t('errors.addressLengthInvalid');
    }

    if (!form.cp.trim()) {
      errors.cp = t('errors.postalCodeRequired');
    } else if (!validatePostalCode(form.cp)) {
      errors.cp = t('errors.invalidPostalCode');
    }

    if (!form.phone.trim()) {
      errors.phone = t('errors.phoneRequired');
    } else if (!validatePhone(form.phone)) {
      errors.phone = t('errors.invalidPhoneNumber');
    }

    if (!form.email.trim()) {
      errors.email = t('errors.emailRequired');
    } else if (!validateEmail(form.email)) {
      errors.email = t('errors.invalidEmailFormat');
    }

    if (form.legal_aid && typeof form.legal_aid !== 'boolean') {
      errors.legal_aid = t('errors.invalidLegalAidValue');
    }

    return errors;
  };

  const handleRemoveParticipant = useCallback((participant) => {
    setAlertTitle(t("messages.removeParticipantConfirmation"));
    setAlertBody(t("messages.removeParticipantWarning", { name: participant.name }));
    setAlertConfirmCallback(() => async () => {
      try {
        const participantIds = data.participants.map(
          (participant) => participant.id
        );
        const updatedParticipants = participantIds.filter(
          (id) => id !== participant.id
        );

        await rdvService.deleteParticipant({
          pk: data.id,
          updatedParticipants: {
            participants: updatedParticipants,
          },
          accessToken,
        });

        toast.success(
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <FaCheck style={{ marginRight: '8px' }} />
            {t("messages.changesSaved")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "#FF6666",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            icon: false
          }
        );
        refresh();
      } catch (error) {
        console.error("Failed to remove participant:", error);
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <FaTimes style={{ marginRight: '8px', color: 'white' }} />
            {t("messages.failedToRemoveParticipant")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "red",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            progressBar: false,
            icon: false
          }
        );
      }
      onAlertClose();
    });
    onAlertOpen();
  }, [data?.id, accessToken, refresh, t, onAlertClose, onAlertOpen]);

  const openModal = useCallback((participant = null) => {
    setCurrentParticipant(participant);
    onModalOpen();
  }, [onModalOpen]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query) {
      const lowercasedQuery = query.toLowerCase();
      const filtered = data.participants.filter(participant =>
        participant?.name?.toLowerCase().includes(lowercasedQuery) ||
        participant?.email?.toLowerCase().includes(lowercasedQuery) ||
        participant?.phone?.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredParticipants(filtered);
    } else {
      setFilteredParticipants(data.participants);
    }
  };

  // Ensure data is available before rendering
  if (!data) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" m={3}>
      <Flex justify="space-between" p={4} borderBottomWidth="1px">
        <Text fontSize="2xl" fontWeight="semibold">{t("admin__appointments_table__participants")}</Text>
        <Button fontSize={"16px"}
          fontWeight={"500"}
          marginLeft={3}
          marginY={"auto"}
          color={"#FF6666"}
          bg={"none"} onClick={() => openModal()} isDisabled={data.is_closed}>
          {t("admin__appointments_table__add_participant")}
        </Button>
      </Flex>
        {filteredParticipants.length > 0 && (
          <Box p={4}>
            <Input
              placeholder={t("search_participants")}
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>
        )}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__name")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__status")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__email")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__telephone")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__city")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__average_monthly_income")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__amount_per_session")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__regime")}</Th>
            <Th sx={tableHeaderStyles}>{t("actions")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredParticipants.length === 0 ? (
            <Tr>
              <Td colSpan={5}>
                <Text textAlign="center" color="gray.500">{t("no_participant_added")}</Text>
              </Td>
            </Tr>
          ) : (
            filteredParticipants.map((participant) => (
              <Tr key={participant.id}>
                <Td sx={tableContentStyles}>{participant.name}</Td>
                <Td sx={tableContentStyles}>
                  {participant.status && t(`family_relation__${participant.status.charAt(0).toLowerCase() + participant.status.slice(1)}`)}
                </Td>
                <Td sx={tableContentStyles}>{participant.email}</Td>
                <Td sx={tableContentStyles}>{participant.phone}</Td>
                <Td sx={tableContentStyles}>{participant.city}</Td>
                <Td sx={tableContentStyles}>{participant?.average_monthly_income ? `${participant.average_monthly_income}Є` : ""}</Td>
                <Td sx={tableContentStyles}>{participant?.amount_per_session ? `${participant.amount_per_session}Є` : ""}</Td>
                <Td sx={tableContentStyles}>{participant.regime === 'AUTRE' ? t('autre') : participant.regime}</Td>
                <Td sx={tableContentStyles}>
                  <IconButton
                    aria-label="Edit participant"
                    icon={<EditIcon />}
                    size="sm"
                    mr={2}
                    bg={'none'}
                    onClick={() => openModal(participant)}
                  />
                  <IconButton
                    aria-label="Remove participant"
                    icon={<DeleteIcon />}
                    size="sm"
                    colorScheme="red"
                    onClick={() => handleRemoveParticipant(participant)}
                  />
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>

      <ParticipantModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        initialRef={initialParticipantRef}
        finalRef={finalParticipantRef}
        addParticipant={handleAddParticipant}
        editParticipant={handleEditParticipant}
        participant={currentParticipant}
        validateForm={validateParticipantForm}
      />

      <GenericAlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        title={alertTitle}
        body={alertBody}
        confirm={alertConfirmCallback}
      />
    </Box>
  );
};

export default ParticipantSection;
