import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Spinner,
  Skeleton,
  Select,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import axios from 'axios';
import Card from "components/card/Card";
import BrandHeader from "components/menu/BrandHeader";
import { connect } from "react-redux";
import {
  selectUser,
  selectAccessToken,
  selectAssociation,
} from "selectors/authSelector";
import { FaCheckCircle, FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { ChevronLeftIcon, ChevronRightIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import ToFirstPage from "../../../assets/img/dashboards/first.svg";
import ToLastPage from "../../../assets/img/dashboards/last.svg";
import NewLocationForm from './components/NewLocationForm';
import EditLocationForm from './components/EditLocationForm';
import SearchAndFilterLocations from "./components/SearchAndFilterLocations";
import locationService from "services/locationService";
import TableSkeleton from "components/TableSkeleton";
const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};

function Primer({ text }) {
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text fontSize="lg" fontFamily="heading" fontWeight="500">
          {text}
        </Text>
      </Flex>
    </Card>
  );
}

const LocationList = ({ user, accessToken, association }) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [locationsListIsLoading, setLocationsListIsLoading] = useState(true);
  const [locationListFetchHasError, setLocationListFetchHasError] = useState(null);
  const [locationListData, setLocationListData] = useState([]);
  const { isOpen: isNewLocationOpen, onOpen: onNewLocationOpen, onClose: onNewLocationClose } = useDisclosure();
  const { isOpen: isEditLocationOpen, onOpen: onEditLocationOpen, onClose: onEditLocationClose } = useDisclosure();
  const [selectedLocation, setSelectedLocation] = useState(null);

  // Search and filter states
  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState({ departments: "" });

  // Ref to track the previous values of searchQuery and filterQuery
  const prevSearchQuery = useRef(searchQuery);
  const prevFilterQuery = useRef(filterQuery);

  useEffect(() => {
    if (searchQuery !== prevSearchQuery.current || filterQuery !== prevFilterQuery.current) {
      setOffset(0);
    }
    prevSearchQuery.current = searchQuery;
    prevFilterQuery.current = filterQuery;
  }, [searchQuery, filterQuery]);

  const fetchLocations = useCallback(async () => {
    // console.log("selectAssociation", association)
    setLocationsListIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}collaboration-api/${association.slug}/location`, {
        params: { limit, offset, search: searchQuery, ...filterQuery }
      });
      setLocationListData(response.data.results);
      setTotalResults(response.data.count);
      setLocationsListIsLoading(false);
    } catch (err) {
      setLocationListFetchHasError(err);
      setLocationsListIsLoading(false);
    }
  }, [accessToken, limit, offset, searchQuery, filterQuery]);

  const handleSearch = () => {
    fetchLocations();
  };

  const handleResetSearchAndFilter = () => {
    setSearchQuery("");
    setFilterQuery({ departments: "" });
    fetchLocations();
  };

  const handleNextPage = useCallback(() => {
    setOffset((prevOffset) => prevOffset + limit);
  }, [limit]);

  const handlePreviousPage = useCallback(() => {
    setOffset((prevOffset) => Math.max(prevOffset - limit, 0));
  }, [limit]);

  const handleFirstPage = useCallback(() => {
    setOffset(0);
  }, []);

  const handleLastPage = useCallback(() => {
    const lastPageOffset = Math.floor(totalResults / limit) * limit;
    setOffset(lastPageOffset);
  }, [totalResults, limit]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const handleSubmitNewLocation = async (formData) => {
    try {
      await locationService.new(
        {
          name: formData.name,
          address: formData.address,
          department: formData.department_code
        }, accessToken);

      toast.success(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t("messages.new_location_added")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );

      fetchLocations();
      onNewLocationClose();
    } catch (err) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {t("messages.failed_to_add_new_location")}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            margin: "auto",
            color: "white",
            minWidth: '600px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  };

  const handleEditLocation = async (formData) => {
    try {
      await locationService.update(selectedLocation.id, formData, accessToken);
      // return;
      toast.success(`${t("messages.location_updated")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
      fetchLocations(); // Refresh the list after editing a location
      onEditLocationClose();
    } catch (err) {
      toast.error(`${t("messages.failed_to_update_location")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
    }
  };



  const renderContent = () => {
    if (locationListFetchHasError) {
      return <Primer text={`Error: ${locationListFetchHasError.message}`} />;
    }

    return (
      <>
        <SearchAndFilterLocations
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          handleSearch={handleSearch}
          handleResetSearchAndFilter={handleResetSearchAndFilter}
        />
        {locationsListIsLoading ? (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__name")}</Th>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__address")}</Th>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__city")}</Th>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__department")}</Th>
                  <Th sx={tableHeaderStyles}>{t("Actions")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                <TableSkeleton numCols={5} />
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__name")}</Th>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__address")}</Th>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__city")}</Th>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__department")}</Th>
                  <Th sx={tableHeaderStyles}>{t("Actions")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {locationListData.length === 0 ? (
                  <Tr>
                    <Td colSpan={6}>
                      <Flex justifyContent="center" alignItems="center" height="100px">
                        <Text fontSize="lg" fontWeight="medium" color={"red.500"}>
                          {t("admin__main__no_results_found")}
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                ) : (
                  locationListData.map((location, index) => (
                    <Tr key={index}>
                      <Td>{location.name}</Td>
                      <Td>{location.address}</Td>
                      <Td>{location.city}</Td>
                      <Td>{location.department}</Td>
                      <Td>
                        <IconButton
                          aria-label="Edit location"
                          icon={<EditIcon />}
                          onClick={() => {
                            setSelectedLocation(location);
                            onEditLocationOpen();
                          }}
                          mr={2}
                        />
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Flex justifyContent="flex-end" alignItems="center" mt={4}>
          <Text mr={4}>
            Rows per page:
            <Select
              value={limit}
              onChange={(e) => setLimit(Number(e.target.value))}
              display="inline-block"
              width="auto"
              ml={2}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Select>
          </Text>
          <Text mr={4}>
            {`${Math.min(offset + 1, totalResults)}-${Math.min(
              offset + limit,
              totalResults
            )} of ${totalResults}`}
          </Text>
          <Flex>
            <IconButton
              onClick={handleFirstPage}
              disabled={offset === 0}
              icon={<Image src={ToFirstPage} alt="First Page" />}
              aria-label="First Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handlePreviousPage}
              disabled={offset === 0}
              icon={<ChevronLeftIcon boxSize={6} />}
              aria-label="Previous Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleNextPage}
              disabled={offset + limit >= totalResults}
              icon={<ChevronRightIcon boxSize={6} />}
              aria-label="Next Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleLastPage}
              disabled={offset + limit >= totalResults}
              icon={<Image src={ToLastPage} alt="Last Page" />}
              aria-label="Last Page"
              border={"none"}
              bg={"none"}
            />
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <BrandHeader />
      <ToastContainer />
      <Flex
        direction={"column"}
        margin={3}
        border="1px"
        borderColor="gray.300"
        borderRadius={"8px"}
      >
        <Flex justifyContent={"space-between"}>
          <Box
            fontSize={"24px"}
            fontWeight={"500"}
            marginLeft={3}
            marginY={"12px"}
          >
            {t("admin__header__locations")}
          </Box>

          <Button
            background={"none"}
            fontSize={"18px"}
            fontWeight={"500"}
            marginY={"12px"}
            color={"rgb(255, 102, 102)"}
            onClick={onNewLocationOpen} // Open the modal when this button is clicked
          >
            {t(`locationTab.admin_add_new_location`)}
          </Button>
        </Flex>
        {renderContent()}
        {isNewLocationOpen ?
          <Modal isOpen={isNewLocationOpen} onClose={onNewLocationClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t(`locationTab.admin_add_new_location`)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <NewLocationForm onSubmit={handleSubmitNewLocation} onClose={onNewLocationClose} />
              </ModalBody>
            </ModalContent>
          </Modal>
          : ''}
        {isEditLocationOpen && (
          <Modal isOpen={isEditLocationOpen} onClose={onEditLocationClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t(`locationTab.admin_edit_location`)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <EditLocationForm
                  initialData={selectedLocation}
                  onSubmit={handleEditLocation}
                  onClose={onEditLocationClose}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </Flex>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
  association: selectAssociation(state),
});

const ConnectedLocationList = connect(mapStateToProps)(LocationList);

export default function LocationsPage() {
  return <ConnectedLocationList />;
}
