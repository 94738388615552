import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
  Icon,
  useDisclosure,
  IconButton,
  Textarea,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  HStack,
  Tag,
  TagLabel
} from "@chakra-ui/react";
import { FaCloudUploadAlt, FaEye, FaCheck, FaTimes } from "react-icons/fa";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useDropzone } from 'react-dropzone';
import { toast } from "react-toastify";
import rdvService from "services/rdvService";
import rdvSessionService from "services/rdvSessionService";
import documentService from "services/documentService";
import GenericAlertDialog from "./GenericAlertDialog";
import CustomModal from "./CustomModal";
import { useTranslation } from "react-i18next";
import Utils from "services/Utils";
import session from "redux-persist/lib/storage/session";

const DocumentSection = ({ data, accessToken, refresh }) => {
  const { t } = useTranslation();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [docsError, setDocsError] = useState(null);
  const [newDocument, setNewDocument] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [comment, setComment] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [errors, setErrors] = useState({ displayName: "", comment: "" });
  const initialDocumentsRef = useRef(null);
  const finalDocumentsRef = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState("All");


  const { isOpen: isAlertDialogOpen, onOpen: onAlertDialogOpen, onClose: onAlertDialogClose } = useDisclosure();
  const { isOpen: isDocumentsModalOpen, onOpen: onDocumentsModalOpen, onClose: onDocumentsModalClose } = useDisclosure();

  useEffect(() => {
    // Get the documents from the session and main documents list
    const sessionDocuments = data.rdv_sessions
      ? data.rdv_sessions.flatMap((session, index) =>
        (session.documents || []).map(doc => ({ ...doc, origin: "rdv_session", session_identifier: session.id, session_number: index + 1 }))
      )
      : [];

    console.log(sessionDocuments);
    console.log(data.documents);

    // Combine documents
    const allDocuments = [
      ...data.documents.map(doc => ({ ...doc, origin: "rdv" })),
      ...sessionDocuments
    ];

    // Remove duplicate documents based on a unique identifier
    const uniqueDocuments = allDocuments.filter((doc, index, self) =>
      index === self.findIndex((t) => (
        t.id === doc.id
      ))
    );

    // Set unique documents to state
    setDocuments(uniqueDocuments);
    setFilteredDocuments(uniqueDocuments);
    console.log(uniqueDocuments);
  }, [data.documents, data.rdv_sessions]);

  const noDocumentsAdded = documents.length === 0;
  const noDocumentsFound = filteredDocuments.length === 0 && !noDocumentsAdded;


  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setNewDocument(acceptedFiles[0]);
      setDisplayName(acceptedFiles[0].name);
      setDocsError(null);
      setIsFileUploaded(true);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
  });

  const handleFileChange = (event) => {
    setNewDocument(event.target.files[0]);
    setDisplayName(event.target.files[0]?.name || "");
  };

  const resetForm = () => {
    setNewDocument(null);
    setDisplayName("");
    setComment("");
    setIsFileUploaded(false);
  };

  const handleUpload = async () => {
    if (!newDocument) {
      setDocsError(t("file_required"));
      return;
    }
    if (!displayName) {
      setDocsError(t("display_name_required"));
      return;
    }
    if (newDocument) {
      try {
        const res = await documentService.startUpload(newDocument.name, newDocument.size, newDocument.type, displayName, comment, accessToken);
        const url = res.url;
        const idfile = res.id;

        await documentService.uploadToS3(url, newDocument);
        await documentService.finishUpload(idfile, accessToken);
        await rdvService.update({
          pk: data.id,
          accessToken: accessToken,
          obj: {
            documents: [...documents.map(doc => doc.id), idfile]
          }
        });

        toast.success(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaCheck style={{ marginRight: '8px' }} />
            {t("document_uploaded")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "#FF6666",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            icon: false
          }
        );
        // Reset search and filter states
        setSearchQuery("");  // Reset search query
        setSelectedFilter("All");  // Reset filter to "All"
        applyFilter("", "All");  // Reapply filter to show all documents

        resetForm();
        onDocumentsModalClose();
        refresh(); // Refresh state


      } catch (error) {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaTimes style={{ marginRight: '8px', color: 'white' }} />
            {t("document_upload_failed")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "red",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            progressBar: false,
            icon: false
          }
        );
        console.error(error);
      }
    }
  };

  const handlePreview = (document) => {
    window.open(document.file, '_blank');
  };

  const handleDownload = (document) => {
    const link = document.createElement('a');
    link.href = document.file;
    link.download = document.original_file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEditNote = (document) => {
    setSelectedDocument(document);
    setDisplayName(document.display_name);
    setComment(document.comment || "");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedDocument(null);
    resetForm();
  };

  const validateForm = () => {
    const newErrors = { displayName: "", comment: "" };

    if (!displayName.trim()) {
      newErrors.displayName = t("display_name_required");
    }
    if (!comment.trim()) {
      newErrors.comment = t("comment_required");
    }

    setErrors(newErrors);

    // Return true if no errors, false otherwise
    return !newErrors.displayName && !newErrors.comment;
  };


  const handleSaveNote = async () => {

    if (selectedDocument.origin !== 'rdv_session') {
      try {
        await rdvService.updateDoc({
          pk: data.id,
          docId: selectedDocument.id,
          payload: {
            display_name: displayName,
            comment: comment,
          },
        });

        toast.success(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaCheck style={{ marginRight: '8px' }} />
            {t("note_saved")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "#FF6666",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            icon: false
          }
        );
        handleCloseModal();
        refresh(); // Refresh state
      } catch (error) {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaTimes style={{ marginRight: '8px', color: 'white' }} />
            {t("note_save_failed")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "red",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            progressBar: false,
            icon: false
          }
        );
        console.error(error);
      }
    } else {
      try {
        await rdvSessionService.updateDoc({
          pk: selectedDocument.session_identifier,
          payload: {
            display_name: displayName,
            comment: comment,
          },
          docId: selectedDocument.id,
        });
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaCheck style={{ marginRight: '8px' }} />
            {t("note_saved")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "#FF6666",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            icon: false
          }
        );
        handleCloseModal();
        refresh();
      } catch (error) {
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaTimes style={{ marginRight: '8px', color: 'white' }} />
            {t("note_save_failed")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "red",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            progressBar: false,
            icon: false
          }
        );
        console.error(error);
      }
    }
  };

  const handleRemoveDoc = (document) => {
    if (document.origin !== 'rdv_session') {
      openGenericAlertDialog(
        t('messages.removeDocConfirmation'),
        t('messages.removeDocWarning'),
        async () => {
          await rdvService.deleteDoc(data.id, document.id);
          toast.success(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaCheck style={{ marginRight: '8px' }} />
              {t('messages.changesSaved')}
            </div>,
            {
              position: "bottom-center",
              style: {
                backgroundColor: "#FF6666",
                color: "white",
                width: 'auto',
                minWidth: '400px'
              },
              icon: false
            }
          );
          refresh();
        },
        () => {
          console.log("Closed without confirming.");
        }
      );
    }
    else {
      openGenericAlertDialog(
        t("messages.removeDocConfirmation"),
        t("messages.removeDocWarning"),
        async () => {
          await rdvSessionService.deleteDoc(document.session_identifier, document.id);
          toast.success(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaCheck style={{ marginRight: '8px' }} />
              {t("messages.changesSaved")}
            </div>,
            {
              position: "bottom-center",
              style: {
                backgroundColor: "#FF6666",
                color: "white",
                width: 'auto',
                minWidth: '400px'
              },
              icon: false
            }
          );
          refresh();
        },
        () => {
          console.log("Closed without confirming.");
        }
      );
    }
  };

  const openGenericAlertDialog = (title, body, confirm, close) => {
    setAlertDialogTitle(title);
    setAlertDialogBody(body);
    setAlertDialogConfirm(() => confirm);
    setAlertDialogOnClose(() => close);
    onAlertDialogOpen();
  };

  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [alertDialogBody, setAlertDialogBody] = useState("");
  const [alertDialogConfirm, setAlertDialogConfirm] = useState(() => () => { });
  const [alertDialogOnClose, setAlertDialogOnClose] = useState(() => () => { });

  const handleConfirm = () => {
    alertDialogConfirm();
    onAlertDialogClose();
  };

  const handleClose = () => {
    resetForm();
    alertDialogOnClose();
    onAlertDialogClose();
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    applyFilter(query, selectedFilter);
  };

  const applyFilter = (query, filter) => {
    const lowercasedQuery = query.toLowerCase();
    let filteredDocs = documents;

    if (filter !== "All") {
      filteredDocs = filteredDocs.filter(doc => {
        if (filter === "Attestation") {
          return doc.comment === "Generated Attestation";
        } else if (filter === "Payment") {
          return doc.comment === "Generated Payment Ticket";
        } else if (filter === "Divers") {
          return doc.comment !== "Generated Attestation" && doc.comment !== "Generated Payment Ticket";
        } else if (filter.startsWith("Session")) {
          const sessionNumber = parseInt(filter.split(" ")[1], 10);
          return doc.session_number === sessionNumber;
        }
        return true;
      });
    }

    if (query) {
      filteredDocs = filteredDocs.filter(doc =>
        doc.display_name.toLowerCase().includes(lowercasedQuery) ||
        (doc.comment && doc.comment.toLowerCase().includes(lowercasedQuery))
      );
    }

    setFilteredDocuments(filteredDocs);
  };


  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    applyFilter(searchQuery, filter);
  };


  const tableHeaderStyles = {
    textAlign: "start",
    fontSize: "16px",
    fontWeight: "500",
    color: "#252525",
  };

  const tableContentStyles = {
    fontSize: "16px",
    fontWeight: "400",
    color: "#565656",
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" m={3}>
      <Flex justify="space-between" p={4} borderBottomWidth="1px">
        <Text fontSize="2xl" fontWeight="semibold">
          {t("admin__appointments_table__documents")}
        </Text>
        <Button
          fontSize={"16px"}
          fontWeight={"500"}
          marginLeft={3}
          marginY={"auto"}
          color={"#FF6666"}
          bg={"none"}
          onClick={onDocumentsModalOpen}
          isDisabled={data.is_closed}
        >
          {t("admin__appointments_table__add_document")}
        </Button>
      </Flex>

      {!noDocumentsAdded && (
        <>
          <Box p={4}>
            <Input
              placeholder={t("search_documents")}
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>

          {["All", "Attestation", "Payment", "Divers"].map((filter) => (
            <Tag
              key={filter}
              size="md"
              borderRadius="full"
              m={2}
              variant={selectedFilter === filter ? "solid" : "outline"}
              bg={selectedFilter === filter ? "#FF6666" : "transparent"}
              color={selectedFilter === filter ? "white" : "gray.500"}
              borderColor={selectedFilter === filter ? "#FF6666" : "gray.200"}
              onClick={() => handleFilterChange(filter)}
              cursor="pointer"
            >
              <TagLabel>{t(`filters.${filter.toLowerCase()}`)}</TagLabel>
            </Tag>
          ))}

          {/* {Array.from(new Set(documents.map(doc => doc.session_number).filter(Boolean))).map((sessionNumber) => (
            <Tag
              key={sessionNumber}
              size="md"
              borderRadius="full"
              m={2}
              variant={selectedFilter === `Session ${sessionNumber}` ? "solid" : "outline"}
              colorScheme={selectedFilter === `Session ${sessionNumber}` ? "green" : "gray"}
              onClick={() => handleFilterChange(`Session ${sessionNumber}`)}
              cursor="pointer"
            >
              <TagLabel>{`Session ${sessionNumber}`}</TagLabel>
            </Tag>
          ))} */}
        </>
      )}



      {noDocumentsAdded ? (
        <Text textAlign="center" fontSize="18px" color="gray.500" p={4}>
          {t("no_documents_found")}
        </Text>
      ) : noDocumentsFound ? (
        <Text textAlign="center" fontSize="18px" color="gray.500" p={4}>
          {t("no_documents_found")}
        </Text>
      ) : (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th sx={tableHeaderStyles}>{t("document_name")}</Th>
                <Th sx={tableHeaderStyles}>{t("document_size")}</Th>
                <Th sx={tableHeaderStyles}>{t("document_type")}</Th>
                <Th sx={tableHeaderStyles}>{t("document_uploaded_by")}</Th>
                <Th sx={tableHeaderStyles}>{t("document_uploaded_on")}</Th>
                <Th sx={tableHeaderStyles}>{t("document_note")}</Th>
                <Th sx={tableHeaderStyles}>{t("actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredDocuments.map((document) => (
                <Tr key={`request-document-${document.id}`} bg={document.origin === "rdv_session" ? "gray.100" : "transparent"}>
                  <Td sx={tableContentStyles}>
                    {document.display_name.length > 15
                      ? `${document.display_name.substring(0, 20)}...`
                      : document.display_name}
                  </Td>
                  <Td sx={tableContentStyles}>{Utils.formatFileSize(document.size)}</Td>
                  <Td sx={tableContentStyles}>{document.file_type}</Td>
                  <Td sx={tableContentStyles}>{document.association__uploaded_by?.name}</Td>
                  <Td sx={tableContentStyles}>{`${Utils.convertUTCToLocalDate(document.upload_finished_at)}, ${Utils.convertUTCToLocalTimeZone(new Date(document.upload_finished_at).toISOString().split("T")[1].slice(0, 5))}h`}</Td>
                  <Td sx={tableContentStyles}>
                    {document.comment.length > 15
                      ? `${document.comment.substring(0, 20)}...`
                      : document.comment}
                  </Td>

                  <Td sx={tableContentStyles}>
                    <IconButton
                      icon={<FaEye />}
                      aria-label={t("preview")}
                      size="sm"
                      mr={2}
                      onClick={() => handlePreview(document)}
                    />
                    <IconButton
                      icon={<EditIcon />}
                      size="sm"
                      mr={2}
                      disabled={document.comment === 'Generated Attestation' || document.comment === 'Generated Payment Ticket'}
                      onClick={() => handleEditNote(document)}
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label={t("delete")}
                      mr={2}
                      size="sm"
                      colorScheme="red"
                      disabled={document.comment === 'Generated Attestation' || document.comment === 'Generated Payment Ticket'}
                      onClick={() => handleRemoveDoc(document)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}


      {isDocumentsModalOpen && (
        <Modal
          initialFocusRef={initialDocumentsRef}
          finalFocusRef={finalDocumentsRef}
          isOpen={isDocumentsModalOpen}
          onClose={onDocumentsModalClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={"36px"} color={"#252525"} fontWeight={400} mx={"auto"}>
              {t("admin__appointments_table__document_details")}
            </ModalHeader>
            <ModalBody>
              <Text mb={2}>{t("select_file")}:</Text>
              <Box
                {...getRootProps()}
                p={6}
                border="2px dashed"
                borderColor={isDragActive ? "blue.400" : isFileUploaded ? "green.400" : "gray.200"}
                borderRadius="md"
                bg={isDragActive ? "blue.50" : isFileUploaded ? "green.50" : "gray.50"}
                transition="all 0.3s"
                cursor="pointer"
                _hover={{
                  borderColor: "blue.300",
                  bg: "blue.50",
                }}
              >
                <input {...getInputProps()} />
                <VStack spacing={3}>
                  <Icon as={FaCloudUploadAlt} w={12} h={12} color={isDragActive ? "blue.400" : isFileUploaded ? "green.400" : "gray.400"} />
                  <Text textAlign="center" fontWeight="medium">
                    {isDragActive
                      ? t("drop_files_here")
                      : isFileUploaded
                        ? t("file_uploaded")
                        : t("drag_drop_files_here")}
                  </Text>
                  {isFileUploaded && (
                    <Text fontSize="sm" color="green.600">
                      {displayName}
                    </Text>
                  )}
                </VStack>
              </Box>
              <Text mt={2} mb={2}>{t("display_name")}<Text as="span" color="red.500">*</Text>:</Text>
              <Input placeholder={t("enter_display_name")} value={displayName} onChange={(e) => setDisplayName(e.target.value)} mb={2} />
              <Text mb={2}>{t("comment")}<Text as="span" color="red.500">*</Text>:</Text>
              <Input placeholder={t("enter_comment")} value={comment} onChange={(e) => setComment(e.target.value)} mb={2} />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4} justify="flex-end" width="100%">
                <Button
                  fontSize={"18px"}
                  colorScheme="red"
                  borderRadius={"4px"}
                  onClick={handleUpload}
                  isDisabled={!newDocument || !displayName || !comment}
                >
                  {t("admin__appointments_table__add_document")}
                </Button>
                <Button onClick={() => {
                  resetForm();
                  onDocumentsModalClose();
                }}>{t("cancel")}</Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <Text fontSize={"36px"} color={"#252525"} fontWeight={400} mx={"auto"} mb={4}>
          {t("edit_note")}
        </Text>
        <Text mb={2}>{t("display_name")}<Text as="span" color="red.500">*</Text>:</Text>
        <Input
          placeholder={t("enter_display_name")}
          value={displayName}
          onChange={(e) => {
            setDisplayName(e.target.value);
            setErrors({ ...errors, displayName: "" });  // Clear the error when the user types
          }}
          mb={2}
          isInvalid={!!errors.displayName}
        />
        {errors.displayName && (
          <Text color="red.500" mb={2}>
            {errors.displayName}
          </Text>
        )}
        <Text mb={2}>{t("comment")}<Text as="span" color="red.500">*</Text>:</Text>
        <Textarea
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
            setErrors({ ...errors, comment: "" });
          }}
          placeholder={t("enter_comment")}
          mb={4}
          isInvalid={!!errors.comment}
        />
        {errors.comment && (
          <Text color="red.500" mb={4}>
            {errors.comment}
          </Text>
        )}

        <Button
          colorScheme="red"
          onClick={handleSaveNote}
          mr={2}
        >
          {t("save_note")}
        </Button>
      </CustomModal>

      <GenericAlertDialog
        isOpen={isAlertDialogOpen}
        title={alertDialogTitle}
        body={alertDialogBody}
        onClose={handleClose}
        confirm={handleConfirm}
      />
    </Box>
  );
};

export default DocumentSection;
