import React, { useEffect } from 'react';
import BrandHeader from '../../../components/menu/BrandHeader';
import CalendarMain from './components/CalendarMain';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Index = () => {
  return (
    <>
      <ToastContainer position="bottom-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      <div>
        <BrandHeader />
        <CalendarMain />
      </div>
    </>
  );
}

export default Index;
