import { extendTheme } from "@chakra-ui/react";
import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { inputStyles } from "./components/input";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { switchStyles } from "./components/switch";
import { linkStyles } from "./components/link";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";

export default extendTheme({
  breakpoints, // Breakpoints
  ...globalStyles,
  components: {
    Badge: badgeStyles, // Badge styles
    Button: buttonStyles, // Button styles
    Link: linkStyles, // Link styles
    Progress: progressStyles, // Progress styles
    Slider: sliderStyles, // Slider styles
    Input: inputStyles, // Input styles
    Textarea: textareaStyles, // Textarea styles
    Switch: switchStyles, // Switch styles
    Card: CardComponent, // Card component
  },
  colors: {
    brandScheme: {
      50: "#FFE5E5",
      100: "#FFB8B8",
      200: "#FF8A8A",
      300: "#FF5C5C",
      400: "#FF2E2E",
      500: "#FF6666", // Base color
      600: "#CC5252",
      700: "#993D3D",
      800: "#662929",
      900: "#331414",
    },
  },
});
