import axiosInstance from '../axiosConfig';

const API_URL =
  `${process.env.REACT_APP_API_BASE_URL}collaboration-api/secretary/mediator`;

const collaboratorService = {
  getAll: async (limit, offset) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/?limit=${limit}&offset=${offset}`
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Failed to fetch mediators. Please try again.");
    }
  },

  getMediatorById: async (id, accessToken) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Failed to fetch mediators. Please try again.");
    }
  },
  createMediator: async (formState, accessToken) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/`, formState, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      return response.data;
    } catch (err) {
      throw err;
    }
  },

  updateMediator: async (medId, obj, accessToken) => {
    try {
      const response = await axiosInstance.patch(`${API_URL}/${medId}/`, obj, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (err) {
      throw err;

    }
  },
};

export default collaboratorService;
