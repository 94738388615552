import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import DefaultAuth from "layouts/auth/Default";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, loginFailure } from "actions/authActions";
import authService from "services/authService";
import routes from "routes";
import AuthLayout from "layouts/auth";

// Language Management
import { useTranslation } from "react-i18next";
import LanguageSelector from "components/menu/LanguageSelector";

// Assets
import illustration from "assets/img/auth/auth.png";

import { Redirect } from "react-router-dom";

function CSignIn() {
  // Init i18n
  const { t, i18n } = useTranslation();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    const rememberedUsername = localStorage.getItem('username');
    const isRemembered = localStorage.getItem('rememberMe') === 'true';
    if (isRemembered && rememberedUsername) {
      setUsername(rememberedUsername);
      setRememberMe(true);
    }
  }, []);

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const handleRememberMeChange = () => setRememberMe(!rememberMe);

  const dispatch = useDispatch();

  const handleLogin = async () => {
    if (!username || !password) {
      setError("Both fields are required.");
      return;
    }

    try {
      const { accessToken, refreshToken, user, professional_profile, association } = await authService.login(username, password);
      dispatch(
        loginSuccess({
          accessToken,
          refreshToken,
          user,
          professional_profile,
          association,
        })
      );
      if (rememberMe) {
        localStorage.setItem('username', username);
        localStorage.setItem('rememberMe', 'true');
        // localStorage.setItem('accessToken', accessToken); // Example token storage
      } else {
        sessionStorage.setItem('accessToken', accessToken); // Temporary storage
        localStorage.setItem('rememberMe', 'false');
      }
      // Redirect to next view upon successful login
      // Find the default admin view route
      const defaultAdminRoute = routes.find(
        (route) => route.layout === "/admin" && route.path === "/default"
      );
      // Redirect to the default admin view upon successful login
      history.push(`${defaultAdminRoute.layout}${defaultAdminRoute.path}`);

    } catch (error) {
      dispatch(loginFailure(error.message));
      setError(t("common_login_failed")); // Set error message
    }
  };

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  if (isAuthenticated) {
    return <Redirect to="/admin/default" />;
  }
  return (
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "15px", md: "1vh" }}
          flexDirection="column"
        >
          <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="start"
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "12vh" }}
            flexDirection="column"
          >
            <LanguageSelector />
          </Flex>
          <Box me="auto">
            {/* Language Selector */}
            <Heading
              color={"#252525"}
              fontSize="56px"
              mb="10px"
              fontWeight={"500"}
            >
              {/* Welcome Header */}
              {t("auth__signin__header")}
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color="#565656"
              fontWeight="400"
              fontSize="28px"
            >
              {t("auth__signin__description")}
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="16px"
                fontWeight="300"
                color={textColor}
                mb="8px"
              >
                {t("common__username")}
                <Text color={"#EE0000"} ml={"1px"}>
                  *
                </Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="username"
                placeholder="Username"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={username}
                onChange={handleUsernameChange}
                borderRadius="4px"
                bg={'gray.200'}
              />
              <FormLabel
                ms="4px"
                fontSize="16px"
                fontWeight="300"
                color={textColor}
                display="flex"
              >
                {t("common__password")}
                <Text color={"#EE0000"} ml={"1px"}>
                  *
                </Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder={t("common__password__hint")}
                  mb="24px"
                  size="lg"
                  type={showPassword ? "text" : "password"}
                  onChange={handlePasswordChange}
                  variant="auth"
                  borderRadius={"4px"}
                  bg={'gray.200'}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={togglePasswordVisibility}
                  />
                </InputRightElement>
              </InputGroup>
              {error && (
                <Text color="red.500" mb="24px">
                  {error}
                </Text>
              )}
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center" w="80%">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brandScheme"
                    me="10px"
                    _hover={{ bg: "#FF8080" }}
                    isChecked={rememberMe}
                    onChange={handleRememberMeChange}
                    sx={{
                      "& .chakra-checkbox__control": {
                        borderColor: "#FF6666",
                        _checked: {
                          bg: "#FF6666",
                          borderColor: "#FF6666",
                        },
                      },
                    }}
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="14px"
                  >
                    {t("save_my_username")}
                  </FormLabel>
                </FormControl>
                <Button
                  variant="link"
                  color="#FF6666"
                  fontSize="14px"
                  w="auto"
                  me="2px"
                  onClick={() => history.push("/auth/reset-password")}
                >
                  {t("common_forgot_password")}
                </Button>
              </Flex>
              <Button
                color={"white"}
                fontSize="20px"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                py={"7"}
                mb="24px"
                onClick={handleLogin}
                bgColor={"#FF6666"}
                borderRadius={0}
                _hover={{ bg: "#FF8080" }}
                isDisabled={!username || !password} // Disable button if either field is empty
              >
                {t("auth__signin__btn_signin")}
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </DefaultAuth>
  );
}

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function SignInCentered() {
  return (
    <AuthLayout>
      <CSignIn />
    </AuthLayout>
  );
}
