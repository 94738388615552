import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Text,
  HStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

const ChildModal = ({
  isOpen,
  onClose,
  initialRef,
  finalRef,
  childrenForm,
  setChildrenForm,
  addChild,
  editChild,
  child,
  addingChildren,
  validateChildForm,
  errors,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (child) {
      setChildrenForm(child);
    } else {
      setChildrenForm({
        name: "",
        date_of_birth: "",
        email: "",
        gender: "",
        adopted: "No",
      });
    }
  }, [child, setChildrenForm]);

  const handleSubmit = async () => {
    const formErrors = validateChildForm(childrenForm);
    if (!formErrors) return;

    if (child) {
      await editChild(childrenForm);
    } else {
      await addChild(childrenForm);
    }
    onClose();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize="36px"
          color="#252525"
          fontWeight={400}
          mx="auto"
        >
          {child
            ? t("edit_child_info")
            : t("admin__appointments_table__add_child")}
        </ModalHeader>
        <ModalBody>
          <FormControl isInvalid={errors.name}>
            <FormLabel display="flex">
              {t("admin__appointments_table__name")}
              <Text ml={1} color="#EE0000">*</Text>
            </FormLabel>
            <Input
              isRequired="true"
              fontWeight={300}
              placeholder="Name"
              value={childrenForm.name}
              onChange={(e) =>
                setChildrenForm({
                  ...childrenForm,
                  name: e.target.value,
                })
              }
            />
            {errors.name && (
              <Text color="crimson" mt={1}>
                {errors.name}
              </Text>
            )}
          </FormControl>
          <FormControl mt={4} isInvalid={errors.date_of_birth}>
            <FormLabel display="flex">
              {t("admin__appointments_table__dob")}
              <Text ml={1} color="#EE0000">*</Text>
            </FormLabel>
            <DatePicker
            showMonthDropdown
            showYearDropdown
            maxDate={new Date()}
              selected={
                childrenForm.date_of_birth
                  ? new Date(childrenForm.date_of_birth)
                  : null
              }
              onChange={(date) =>
                setChildrenForm({
                  ...childrenForm,
                  date_of_birth: date.toLocaleDateString("fr-FR").split("/").reverse().join("-"),
                })
              }
              dateFormat="dd/MM/yyyy"
              locale="fr"

              placeholderText="JJ/MM/AAAA"
              customInput={<Input />}
            />
            {errors.date_of_birth && (
              <Text color="crimson" mt={1}>
                {errors.date_of_birth}
              </Text>
            )}
          </FormControl>
          <FormControl mt={4} isInvalid={errors.gender}>
            <FormLabel display="flex">
              {t("admin__appointments_table__gender")}
              <Text ml={1} color="#EE0000">*</Text>
            </FormLabel>
            <Select
              id="gender"
              name="gender"
              className="form-control"
              isRequired={true}
              placeholder="Select option"
              fontSize="14px"
              fontWeight="400"
              borderRadius="4px"
              value={childrenForm.gender}
              onChange={(e) => {
                setChildrenForm({
                  ...childrenForm,
                  gender: e.target.value,
                });
              }}
              mb={5}
            >
              <option value="Male">{t("new_request_form.male")}</option>
              <option value="Female">{t("new_request_form.female")}</option>
            </Select>
            {errors.gender && (
              <Text color="crimson" mt={1}>
                {errors.gender}
              </Text>
            )}
          </FormControl>
          <FormControl mt={4}>
            <FormLabel display="flex">
              {t("admin__appointments_table__adopted")}
              <Text ml={1} color="#EE0000">*</Text>
            </FormLabel>
            <Select
              id="adopted"
              name="adopted"
              className="form-control"
              isRequired={true}
              placeholder="Select option"
              fontSize="14px"
              fontWeight="400"
              borderRadius="4px"
              value={childrenForm.adopted}
              onChange={(e) => {
                setChildrenForm({
                  ...childrenForm,
                  adopted: e.target.value,
                });
              }}
              mb={5}
            >
              <option value="Yes">{t("Yes")}</option>
              <option value="No">{t("No")}</option>
            </Select>
          </FormControl>
          <FormControl mt={4} isInvalid={errors.email}>
            <FormLabel display="flex">
              {t("admin__appointments_table__email")}
            </FormLabel>
            <Input
              fontWeight={300}
              placeholder="Email"
              value={childrenForm.email}
              onChange={(e) =>
                setChildrenForm({
                  ...childrenForm,
                  email: e.target.value,
                })
              }
            />
            {errors.email && (
              <Text color="crimson" mt={1}>
                {errors.email}
              </Text>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
        <HStack spacing={4} justify="flex-end" width="100%">
          <Button
            fontSize="18px"
            colorScheme="red"
            borderRadius="4px"
            onClick={handleSubmit}
            isLoading={addingChildren}
          >
            {child
              ? t("update")
              : t("admin__appointments_table__add_child")}
          </Button>
          <Button onClick={onClose}>{t("cancel")}</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChildModal;
