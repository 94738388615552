import axiosInstance from '../axiosConfig';


const API_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-auth/`;

const authService = {
  login: async (username, password) => {
    try {
      const response = await axiosInstance.post(`${API_URL}login/`, {
        username: username,
        password: password,
      });
      // Return access token, refresh token, and user data
      return {
        accessToken: response.data.tokens.access_token,
        refreshToken: response.data.tokens.refresh_token,
        user: response.data.user,
        professional_profile: response.data.professional_profile,
        association: response.data.association,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  logout: async () => {
    try {
      console.log('calling logout');
      // Implement server-side logout if required
      await axiosInstance.post(`${API_URL}logout/`);
      // Clear local storage or perform any client-side cleanup
      localStorage.removeItem('user');
      return true;
    } catch (error) {
      console.error("Logout failed", error);
      return false;
    }
  },

  refreshToken: async (refreshToken) => {
    try {
      console.log('calling refresh token');
      const response = await axiosInstance.post(`${API_URL}token/refresh/`, {
        refresh: refreshToken,
      });
      return response.data.access;
    } catch (error) {
      throw new Error("Token refresh failed");
    }
  },

  getCurrentUser: () => {
    return JSON.parse(localStorage.getItem('user'));
  },

  updateUser: async (userData) => {
    try {
      const response = await axiosInstance.put(`${API_URL}user/update/`, userData);
      return response.data;
    } catch (error) {
      throw new Error("Failed to update user information");
    }
  },

  // Add any other auth-related methods here, such as:
  // - Password reset
  // - Email verification
  // - Get user profile
  // etc.

  passwordReset: async (username) => {
    try {
      await axiosInstance.post(`${API_URL}password-reset/`, { username });
      return true;
    } catch (error) {
      throw new Error("Password reset request failed");
    }
  },

  passwordResetConfirm: async (uid, token, newPassword, confirmPassword) => {
    try {
      await axiosInstance.post(`${API_URL}password-reset-confirm/`, {
        uidb64: uid,
        token,
        new_password: newPassword,
        confirm_password: confirmPassword
      });
      return true;
    } catch (error) {
      throw new Error("Password reset confirmation failed");
    }
  },

  verifyEmail: async (key) => {
    try {
      await axiosInstance.post(`${API_URL}verify-email/`, { key });
      return true;
    } catch (error) {
      throw new Error("Email verification failed");
    }
  },

  getUserProfile: async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}user-profile/`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch user profile");
    }
  },
};

export default authService;
