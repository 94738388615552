import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Inject, Day, Week, Month, Agenda } from '@syncfusion/ej2-react-schedule';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  VStack,
  Flex,
  Spinner,
  Select,
  Button,
  Text,
  IconButton,
  HStack,
  Tag,
  TagLabel
} from '@chakra-ui/react';
import { CloseIcon, RepeatIcon } from '@chakra-ui/icons';
import { registerLicense } from '@syncfusion/ej2-base';
import MonthCalendar from './MonthCalendar';
import calendarService from 'services/calendarService';
import collaboratorService from 'services/collaboratorService';
import { connect, useSelector } from 'react-redux';
import { selectAccessToken, selectLocations } from 'selectors/authSelector';
import { setAction, ActionTypes } from "../../../../reducers/calendarReducer"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from 'react-i18next';
import { loadCldr, L10n } from '@syncfusion/ej2-base';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/fr/ca-gregorian.json';
import * as numbers from 'cldr-data/main/fr/numbers.json';
import * as timeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaExchangeAlt } from 'react-icons/fa';
import { debounce } from 'lodash';
import Utils from 'services/Utils';


registerLicense("Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXZeeXRQRmlfWEJ0Xko=");

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);


L10n.load({
  "en": {
    "schedule": {
      "day": "Day",
      "week": "Week",
      "workWeek": "Work Week",
      "month": "Month",
      "year": "Year",
      "agenda": "Agenda",
      "weekAgenda": "Week Agenda",
      "workWeekAgenda": "Work Week Agenda",
      "monthAgenda": "Month Agenda",
      "today": "Today",
      "noEvents": "No events",
      "emptyContainer": "There are no events scheduled on this day.",
      "allDay": "All day",
      "start": "Start",
      "end": "End",
      "more": "more",
      "close": "Close",
      "cancel": "Cancel",
      "noTitle": "(No Title)",
      "delete": "Delete",
      "deleteEvent": "Delete Event",
      "deleteMultipleEvent": "Delete Multiple Events",
      "selectedItems": "Items selected",
      "deleteSeries": "Delete Series",
      "edit": "Edit",
      "editSeries": "Edit Series",
      "editEvent": "Edit Event",
      "createEvent": "Create",
      "subject": "Subject",
      "addTitle": "Add title",
      "moreDetails": "More Details",
      "moreEvents": "More Events",
      "save": "Save",
      "editContent": "Do you want to edit only this event or entire series?",
      "deleteRecurrenceContent": "Do you want to delete only this event or entire series?",
      "deleteContent": "Are you sure you want to delete this event?",
      "deleteMultipleContent": "Are you sure you want to delete the selected events?",
      "newEvent": "New Event",
      "title": "Title",
      "location": "Location",
      "description": "Description",
      "timezone": "Timezone",
      "startTimezone": "Start Timezone",
      "endTimezone": "End Timezone",
      "repeat": "Repeat",
      "saveButton": "Save",
      "cancelButton": "Cancel",
      "deleteButton": "Delete",
      "recurrence": "Recurrence",
      "wrongPattern": "The recurrence pattern is not valid.",
      "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
      "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
      "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
      "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
      "occurenceAlert": "Cannot reschedule an occurrence of the recurring appointment if it skips over a later occurrence of the same appointment.",
      "editRecurrence": "Edit Recurrence",
      "repeats": "Repeats",
      "alert": "Alert",
      "startEndError": "The selected end date occurs before the start date.",
      "invalidDateError": "The entered date value is invalid.",
      "blockAlert": "Events cannot be scheduled within the blocked time range.",
      "ok": "Ok",
      "yes": "Yes",
      "no": "No",
      "occurrence": "Occurrence",
      "series": "Series",
      "previous": "Previous",
      "next": "Next",
      "timelineDay": "Timeline Day",
      "timelineWeek": "Timeline Week",
      "timelineWorkWeek": "Timeline Work Week",
      "timelineMonth": "Timeline Month",
      "expandAllDaySection": "Expand",
      "collapseAllDaySection": "Collapse",
      "timelineYear": "Timeline Year",
      "editFollowingEvent": "Following Events",
      "deleteTitle": "Delete Event",
      "editTitle": "Edit Event",
      "beginFrom": "Begin From",
      "endAt": "End At",
      "searchTimezone": "Search Timezone",
      "noRecords": "No records found",
    },
    "recurrenceeditor": {
      "none": "None",
      "daily": "Daily",
      "weekly": "Weekly",
      "monthly": "Monthly",
      "month": "Month",
      "yearly": "Yearly",
      "never": "Never",
      "until": "Until",
      "count": "Count",
      "first": "First",
      "second": "Second",
      "third": "Third",
      "fourth": "Fourth",
      "last": "Last",
      "repeat": "Repeat",
      "repeatEvery": "Repeat Every",
      "on": "Repeat On",
      "end": "End",
      "onDay": "Day",
      "days": "Day(s)",
      "weeks": "Week(s)",
      "months": "Month(s)",
      "years": "Year(s)",
      "every": "every",
      "summaryTimes": "time(s)",
      "summaryOn": "on",
      "summaryUntil": "until",
      "summaryRepeat": "Repeats",
      "summaryDay": "day(s)",
      "summaryWeek": "week(s)",
      "summaryMonth": "month(s)",
      "summaryYear": "year(s)"
    }
  },
  "fr": {
    "schedule": {
      "day": "Jour",
      "week": "Semaine",
      "workWeek": "Semaine de travail",
      "month": "Mois",
      "year": "Année",
      "agenda": "Agenda",
      "weekAgenda": "Agenda de la semaine",
      "workWeekAgenda": "Agenda de la semaine de travail",
      "monthAgenda": "Agenda du mois",
      "today": "Aujourd'hui",
      "noEvents": "Aucun événement",
      "emptyContainer": "Il n'y a aucun événement prévu ce jour-là.",
      "allDay": "Toute la journée",
      "start": "Début",
      "end": "Fin",
      "more": "plus",
      "close": "Fermer",
      "cancel": "Annuler",
      "noTitle": "(Sans titre)",
      "delete": "Supprimer",
      "deleteEvent": "Supprimer l'événement",
      "deleteMultipleEvent": "Supprimer plusieurs événements",
      "selectedItems": "Éléments sélectionnés",
      "deleteSeries": "Supprimer la série",
      "edit": "Modifier",
      "editSeries": "Modifier la série",
      "editEvent": "Modifier l'événement",
      "createEvent": "Créer",
      "subject": "Sujet",
      "addTitle": "Ajouter un titre",
      "moreDetails": "Plus de détails",
      "moreEvents": "Plus d'événements",
      "save": "Enregistrer",
      "editContent": "Voulez-vous modifier uniquement cet événement ou toute la série?",
      "deleteRecurrenceContent": "Voulez-vous supprimer uniquement cet événement ou toute la série?",
      "deleteContent": "Êtes-vous sûr de vouloir supprimer cet événement?",
      "deleteMultipleContent": "Êtes-vous sûr de vouloir supprimer les événements sélectionnés?",
      "newEvent": "Nouvel événement",
      "title": "Titre",
      "location": "Emplacement",
      "description": "Description",
      "timezone": "Fuseau horaire",
      "startTimezone": "Fuseau horaire de début",
      "endTimezone": "Fuseau horaire de fin",
      "repeat": "Répéter",
      "saveButton": "Enregistrer",
      "cancelButton": "Annuler",
      "deleteButton": "Supprimer",
      "recurrence": "Récurrence",
      "wrongPattern": "Le modèle de récurrence n'est pas valide.",
      "seriesChangeAlert": "Les modifications apportées à des instances spécifiques de cette série seront annulées et ces événements correspondront à nouveau à la série.",
      "createError": "La durée de l'événement doit être inférieure à la fréquence de récurrence. Réduisez la durée ou modifiez le modèle de récurrence dans l'éditeur d'événements récurrents.",
      "recurrenceDateValidation": "Certains mois ont moins de jours que la date sélectionnée. Pour ces mois, l'occurrence aura lieu le dernier jour du mois.",
      "sameDayAlert": "Deux occurrences du même événement ne peuvent pas avoir lieu le même jour.",
      "occurenceAlert": "Impossible de reprogrammer une occurrence de l'événement récurrent si elle passe à côté d'une occurrence ultérieure du même événement.",
      "editRecurrence": "Modifier la récurrence",
      "repeats": "Répétitions",
      "alert": "Alerte",
      "startEndError": "La date de fin sélectionnée se produit avant la date de début.",
      "invalidDateError": "La valeur de date entrée est invalide.",
      "blockAlert": "Les événements ne peuvent pas être programmés pendant la période bloquée.",
      "ok": "D'accord",
      "yes": "Oui",
      "no": "Non",
      "occurrence": "Occurrence",
      "series": "Série",
      "previous": "Précédent",
      "next": "Suivant",
      "timelineDay": "Jour de la chronologie",
      "timelineWeek": "Semaine de la chronologie",
      "timelineWorkWeek": "Semaine de travail de la chronologie",
      "timelineMonth": "Mois de la chronologie",
      "expandAllDaySection": "Développer",
      "collapseAllDaySection": "Réduire",
      "timelineYear": "Année de la chronologie",
      "editFollowingEvent": "Événements suivants",
      "deleteTitle": "Supprimer l'événement",
      "editTitle": "Modifier l'événement",
      "beginFrom": "Commence à partir de",
      "endAt": "Fin à",
      "searchTimezone": "Rechercher un fuseau horaire",
      "noRecords": "Aucun enregistrement trouvé",
    },
    "recurrenceeditor": {
      "none": "Aucune",
      "daily": "Quotidien",
      "weekly": "Hebdomadaire",
      "monthly": "Mensuel",
      "month": "Mois",
      "yearly": "Annuel",
      "never": "Jamais",
      "until": "Jusqu'à",
      "count": "Nombre",
      "first": "Premier",
      "second": "Deuxième",
      "third": "Troisième",
      "fourth": "Quatrième",
      "last": "Dernier",
      "repeat": "Répéter",
      "repeatEvery": "Répéter chaque",
      "on": "Répéter sur",
      "end": "Fin",
      "onDay": "Jour",
      "days": "Jour(s)",
      "weeks": "Semaine(s)",
      "months": "Mois",
      "years": "Année(s)",
      "every": "chaque",
      "summaryTimes": "fois",
      "summaryOn": "sur",
      "summaryUntil": "jusqu'à",
      "summaryRepeat": "Répétitions",
      "summaryDay": "jour(s)",
      "summaryWeek": "semaine(s)",
      "summaryMonth": "mois",
      "summaryYear": "année(s)"
    }
  }
});


const CalendarMain = ({ accessToken }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const OriginallocationsList = useSelector(selectLocations);
  const {
    selectedDate,
    accordionIndex,
    sessionTypeFilter,
    mediationStatusFilter,
    currentView,
    selectedLocations,
    selectedService,
    selectedMediator,
    selectedMeds,
    locationsList,
    mediatorsList,
    filteredMedsList,
    mediatorsListIsLoading,
    sessionsList,
    timeSlotsList,
    isLoadingSessions,
    isLoadingTimeSlots,
    error,
    isMediatorFirst
  } = useSelector(state => state.calendar);

  const currentLocale = i18n.language === 'fr' ? 'fr' : 'en';
  const [toastShown, setToastShown] = useState(false);

  const getDayAbbreviation = (dayIndex) => {
    const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
    return days[dayIndex];
  };

  const getWeekOfYear = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  const handleReverseOrder = () => {
    dispatch(setAction(ActionTypes.TOGGLE_IS_MEDIATOR_FIRST));
    dispatch(setAction(ActionTypes.RESET_FILTERS, OriginallocationsList));
  };

  useEffect(() => {
    resetFiltersAndCloseAccordion();
    console.log("one");
  }, [])

  useEffect(() => {
    if (locationsList.length === 0) {
      dispatch(setAction(ActionTypes.SET_LOCATIONS_LIST, OriginallocationsList));
    }
  }, [OriginallocationsList, dispatch]);

  useEffect(() => {
    console.log("*******************");
    console.log({ selectedDate }, { accordionIndex }, { sessionTypeFilter }, { mediationStatusFilter }, { currentView }, { selectedLocations }, { selectedService }, { selectedMediator }, { selectedMeds }, { locationsList }, { mediatorsList }, { filteredMedsList }, { mediatorsListIsLoading }, { sessionsList }, { timeSlotsList }, { isLoadingSessions }, { isLoadingTimeSlots }, { error }, { isMediatorFirst });
    console.log("*******************");
  }, []);

  const showToast = debounce((content, options) => {
    toast(content, options);
  }, 200);


  const fetchMediators = useCallback(() => {
    dispatch(setAction(ActionTypes.SET_MEDIATORS_LIST_LOADING, true));
    collaboratorService.getAll('', '')
      .then((res) => {
        dispatch(setAction(ActionTypes.SET_MEDIATORS_LIST, res.data));
        dispatch(setAction(ActionTypes.SET_MEDIATORS_LIST_LOADING, false));
        if (res.data.length === 0) {
          showToast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t("calendarTab.error_no_meds")}
            </div>,
            {
              position: "bottom-center",
              style: {
                backgroundColor: "red",
                color: "white",
                width: 'auto',
                minWidth: '400px'
              },
              progressBar: false,
              icon: false
            }
          );
        }
      })
      .catch((err) => {
        dispatch(setAction(ActionTypes.SET_MEDIATORS_LIST_LOADING, false));
        console.error('Error fetching mediators:', err);
        showToast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t("calendarTab.error_fetching_meds")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "red",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            progressBar: false,
            icon: false
          }
        );
      });
  }, [dispatch]);

  useEffect(() => {
    fetchMediators();
  }, [fetchMediators]);

  const handleLocationChange = (id, isChecked) => {
    if (isMediatorFirst) {
      if (!isChecked) {
        dispatch(setAction(ActionTypes.SET_SELECTED_LOCATIONS, [...selectedLocations, id]));
      } else {
        dispatch(setAction(ActionTypes.SET_SELECTED_LOCATIONS, selectedLocations.filter(locId => locId !== id)));
      }
    } else {
      dispatch(setAction(ActionTypes.RESET_FILTERS, OriginallocationsList));
      dispatch(setAction(ActionTypes.SET_SELECTED_LOCATIONS, [id]));
      const temp = mediatorsList.filter(med => med.locations.some(loc => loc.id === id));

      dispatch(setAction(ActionTypes.SET_FILTERED_MEDS_LIST, temp));

      console.log({ temp });
      if (temp.length === 0) {
        setToastShown(true);
        showToast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t("calendarTab.error_no_meds_for_location")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "red",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            progressBar: false,
            icon: false
          }
        );
        setToastShown(false);
      }
    }
  };

  const handleMediatorChange = (id, checked) => {
    if (isMediatorFirst) {
      dispatch(setAction(ActionTypes.RESET_FILTERS, OriginallocationsList));
      const med = mediatorsList.find(med => med.id === id);
      dispatch(setAction(ActionTypes.SET_SELECTED_MEDIATOR, med));
      dispatch(setAction(ActionTypes.SET_LOCATIONS_LIST, med?.locations || []));

      if (med?.locations.length === 0) {
        setToastShown(true);
        showToast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t("calendarTab.error_no_locations_for_med")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "red",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            progressBar: false,
            icon: false
          }
        );
        setToastShown(false);
      }

    } else {
      if (!checked) {
        dispatch(setAction(ActionTypes.SET_SELECTED_MEDS, [...selectedMeds, id]));
      } else {
        dispatch(setAction(ActionTypes.SET_SELECTED_MEDS, selectedMeds.filter(medId => medId !== id)));
      }
      if (filteredMedsList.length === 0) {
        setToastShown(true);
        showToast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t("calendarTab.error_no_meds_for_location")}
          </div>,
          {
            position: "bottom-center",
            style: {
              backgroundColor: "red",
              color: "white",
              width: 'auto',
              minWidth: '400px'
            },
            progressBar: false,
            icon: false
          }
        );
        setToastShown(false);
      }
    }
  };

  const handleDateSelect = (date) => {
    // setToastShown(false);
    dispatch(setAction(ActionTypes.SET_SELECTED_DATE, date));
  };

  const handleServiceChange = (service) => {
    dispatch(setAction(ActionTypes.SET_SELECTED_SERVICE, service === selectedService ? null : service));
  };

  const fetchSessionsAndSlots = useCallback(async () => {
    setToastShown(false);
    if (isMediatorFirst) {
      if (selectedLocations.length > 0 && selectedMediator.id) {
        dispatch(setAction(ActionTypes.SET_LOADING_SESSIONS, true));
        dispatch(setAction(ActionTypes.SET_LOADING_TIME_SLOTS, true));
        dispatch(setAction(ActionTypes.SET_ERROR, null));

        try {
          const filters = {
            location: selectedLocations.join(','),
            service_provider: selectedMediator.id,
          };

          const [sessions, timeSlots] = await Promise.all([
            calendarService.fetchSessions(filters),
            calendarService.freeTimeSlots(selectedLocations.join(','), selectedMediator.id, selectedService, 30)
          ]);

          dispatch(setAction(ActionTypes.SET_SESSIONS_LIST, sessions.data));
          dispatch(setAction(ActionTypes.SET_TIME_SLOTS_LIST, timeSlots.data));

          if (sessions.data.length === 0 && !toastShown) {
            showToast(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {t("calendarTab.error_no_sessions_for_filters")}
              </div>,
              {
                position: "bottom-center",
                style: {
                  backgroundColor: "red",
                  color: "white",
                  width: 'auto',
                  minWidth: '400px'
                },
                progressBar: false,
                icon: false
              }
            );
          }
          if (timeSlots.data.length === 0 && !toastShown) {
            showToast(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {t("calendarTab.error_no_slots_for_filters")}
              </div>,
              {
                position: "bottom-center",
                style: {
                  backgroundColor: "red",
                  color: "white",
                  width: 'auto',
                  minWidth: '400px'
                },
                progressBar: false,
                icon: false
              }
            );
          }
        } catch (err) {
          dispatch(setAction(ActionTypes.SET_ERROR, err));
          console.error('Error fetching data:', err);
          showToast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t("calendarTab.error_fetching_data")}
            </div>,
            {
              position: "bottom-center",
              style: {
                backgroundColor: "red",
                color: "white",
                width: 'auto',
                minWidth: '400px'
              },
              progressBar: false,
              icon: false
            }
          );
        } finally {
          dispatch(setAction(ActionTypes.SET_LOADING_SESSIONS, false));
          dispatch(setAction(ActionTypes.SET_LOADING_TIME_SLOTS, false));
        }
      }
    } else {
      if (selectedLocations.length > 0 && selectedMeds.length > 0) {
        dispatch(setAction(ActionTypes.SET_LOADING_SESSIONS, true));
        dispatch(setAction(ActionTypes.SET_LOADING_TIME_SLOTS, true));
        dispatch(setAction(ActionTypes.SET_ERROR, null));

        try {
          const filters = {
            location: selectedLocations[0],
            service_provider: selectedMeds.join(','),
          };

          const [sessions, timeSlots] = await Promise.all([
            calendarService.fetchSessions(filters),
            calendarService.freeTimeSlots(selectedLocations[0], selectedMeds.join(','), selectedService, 30)
          ]);

          dispatch(setAction(ActionTypes.SET_SESSIONS_LIST, sessions.data));
          dispatch(setAction(ActionTypes.SET_TIME_SLOTS_LIST, timeSlots.data));

          if (sessions.data.length === 0) {
            showToast(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {t("calendarTab.error_no_sessions_for_filters")}
              </div>,
              {
                position: "bottom-center",
                style: {
                  backgroundColor: "red",
                  color: "white",
                  width: 'auto',
                  minWidth: '400px'
                },
                progressBar: false,
                icon: false
              }
            );
          }
          if (timeSlots.data.length === 0) {
            showToast(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {t("calendarTab.error_no_slots_for_filters")}
              </div>,
              {
                position: "bottom-center",
                style: {
                  backgroundColor: "red",
                  color: "white",
                  width: 'auto',
                  minWidth: '400px'
                },
                progressBar: false,
                icon: false
              }
            );
          }
        } catch (err) {
          dispatch(setAction(ActionTypes.SET_ERROR, err));
          console.error('Error fetching data:', err);
          showToast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t("calendarTab.error_fetching_data")}
            </div>,
            {
              position: "bottom-center",
              style: {
                backgroundColor: "red",
                color: "white",
                width: 'auto',
                minWidth: '400px'
              },
              progressBar: false,
              icon: false
            }
          );
        } finally {
          dispatch(setAction(ActionTypes.SET_LOADING_SESSIONS, false));
          dispatch(setAction(ActionTypes.SET_LOADING_TIME_SLOTS, false));
        }
      }
    }
  }, [dispatch, isMediatorFirst, selectedLocations, selectedMediator, selectedMeds, selectedService]);

  const mapSessionsToCalendarEvents = (sessions) => {
    console.log("mapSessionsToCalendarEvents sessions", sessions)
    return sessions.map(session => {
      const utcStartDate = new Date(session.calendar_block.start_date);
      const utcEndDate = new Date(session.calendar_block.end_date);

      return {
        Id: session.id,
        Subject: session.calendar_block.title,
        Status: session.status,
        StartTime: utcStartDate,
        EndTime: utcEndDate,
        IsAllDay: false,
        Location: session?.location?.name || '',
        Category: session.type,
        OriginalRdv: session.original_rdv,
        BackgroundColor: session.calendar_block.color,
        // StartTimezone: 'UTC',
        // EndTimezone: 'UTC',
        rankId: 0
      };
    });
  };

  const mapTimeSlotsToRecurringEvents = (timeSlots) => {
    return timeSlots?.results?.map(slot => {
      const startDate = new Date();
      const utctolocalconvertStart = Utils.convertUTCToLocalTimeZone(slot.start_time)
      startDate.setHours(parseInt(utctolocalconvertStart.split(":")[0]), parseInt(utctolocalconvertStart.split(":")[1]));

      const endDate = new Date();
      const utctolocalconvertEnd = Utils.convertUTCToLocalTimeZone(slot.end_time)
      endDate.setHours(parseInt(utctolocalconvertEnd.split(":")[0]), parseInt(utctolocalconvertEnd.split(":")[1]));

      const weekOfYear = getWeekOfYear(startDate);

      let recurrenceRule = `FREQ=WEEKLY;BYDAY=${slot.applicable_days.map(day => getDayAbbreviation(day)).join(",")}`;

      if (slot.applicable_week === 'Odd') {
        if (weekOfYear % 2 === 0) {
          startDate.setDate(startDate.getDate() + 7);
          endDate.setDate(endDate.getDate() + 7);
        }
        recurrenceRule += `;INTERVAL=2`;
      } else if (slot.applicable_week === 'Even') {
        if (weekOfYear % 2 !== 0) {
          startDate.setDate(startDate.getDate() + 7);
          endDate.setDate(endDate.getDate() + 7);
        }
        recurrenceRule += `;INTERVAL=2`;
      } else {
        recurrenceRule += `;INTERVAL=1`;
      }

      return {
        Id: slot.id,
        Subject: t("calendarTab.availability"),
        abv: 'fts',
        StartTime: startDate,
        EndTime: endDate,
        IsAllDay: false,
        Location: slot.location.name,
        BackgroundColor: slot.location.color,
        RecurrenceRule: recurrenceRule,
        rankId: 1
      };
    });
  };

  const mergeEventsWithPriority = (sessionEvents, timeSlotEvents) => {
    const mergedEvents = [...sessionEvents];

    timeSlotEvents?.forEach(timeSlotEvent => {
      const hasOverlap = sessionEvents.some(sessionEvent => (
        timeSlotEvent.StartTime < sessionEvent.EndTime &&
        timeSlotEvent.EndTime > sessionEvent.StartTime
      ));

      if (!hasOverlap) {
        mergedEvents.push(timeSlotEvent);
      } else {
        timeSlotEvent.BackgroundColor = '#d3d3d3';
        timeSlotEvent.Subject = `Unavailable: ${timeSlotEvent.Subject}`;
      }
    });

    return mergedEvents;
  };

  const calendarBlock = useMemo(() => {
    const sessionEvents = mapSessionsToCalendarEvents(sessionsList);
    const timeSlotEvents = mapTimeSlotsToRecurringEvents(timeSlotsList);
    console.log("sessionEvents, timeSlotEvents", sessionEvents, timeSlotEvents)
    return mergeEventsWithPriority(sessionEvents, timeSlotEvents);
  }, [sessionsList, timeSlotsList]);

  const filteredCalendarBlock = useMemo(() => {
    let events = calendarBlock;
    switch (sessionTypeFilter) {
      case "Availability":
        events = events.filter(event => event.abv === 'fts');
        break;
      case "Sessions":
        events = events.filter(event => event.abv !== 'fts');
        break;
      case "Info":
        events = events.filter(event => event.Category === 'Info');
        break;
      case "Mediation":
        events = events.filter(event => event.Category === 'Mediation');
        if (mediationStatusFilter) {
          events = events.filter(event => event.Status === mediationStatusFilter);
        }
        break;
      default:
        break;
    }

    // if (events.length === 0 && !toastShown && (selectedLocations.length > 0) && selectedMediator.id) {
    //   setToastShown(true);
    //   showToast(
    //     <div style={{ display: 'flex', alignItems: 'center' }}>
    //       {t("calendarTab.error_no_events_for_filters")}
    //     </div>,
    //     {
    //       position: "bottom-center",
    //       style: {
    //         backgroundColor: "red",
    //         color: "white",
    //         width: 'auto',
    //         minWidth: '400px'
    //       },
    //       progressBar: false,
    //       icon: false
    //     }
    //   );
    //   setToastShown(false);
    // }
    return events;
  }, [calendarBlock, sessionTypeFilter, mediationStatusFilter]);

  const handleEventRendered = (args) => {
    function hexToRgba(hex, opacity) {
      hex = hex.replace('#', '');

      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    let bg;
    if (args.data.abv === 'fts') {
      args.element.style.backgroundColor = hexToRgba(args.data.BackgroundColor, 0.5);
      bg = args.element.style.backgroundColor;
    } else {
      args.element.style.backgroundColor = args.data.BackgroundColor;
      bg = args.element.style.backgroundColor;
    }
  };

  const handlePopupOpen = (args) => {
    console.log("args data", args)
    if (args.type === 'Editor') args.cancel = true;
    if (args.type === 'QuickInfo') {
      const editButton = args.element.querySelector('button.e-edit');
      const closeButton = args.element.querySelector('button.e-close');
      const buttonContainer = editButton ? editButton.parentNode : null;

      if (editButton) editButton.remove();
      const deleteButton = args.element.querySelector('button.e-delete');
      if (deleteButton) deleteButton.remove();

      if (args.data.abv !== 'fts') {
        const externalLinkButton = document.createElement('a');
        externalLinkButton.href = `${window.location.origin}/#/admin/appointment-checkin/${args.data.OriginalRdv.id}`;
        externalLinkButton.target = '_blank';
        externalLinkButton.textContent = t("calendarTab.show_details");
        externalLinkButton.style.backgroundColor = 'transparent';
        externalLinkButton.style.border = 'none';
        externalLinkButton.style.boxShadow = 'none';
        externalLinkButton.style.color = 'white';
        externalLinkButton.style.marginTop = '3px';
        externalLinkButton.className = 'e-btn';

        if (buttonContainer) {
          if (closeButton) buttonContainer.removeChild(closeButton);
          buttonContainer.appendChild(externalLinkButton);
          if (closeButton) buttonContainer.appendChild(closeButton);
        }
      }
    }
  };

  const handleViewChange = (args) => {
    // setToastShown(false);
    if (args.currentDate) {
      dispatch(setAction(ActionTypes.SET_SELECTED_DATE, args.currentDate));
    }
    if (args.currentView) {
      dispatch(setAction(ActionTypes.SET_CURRENT_VIEW, args.currentView));
    }
  };

  const onCellClick = (args) => {
    args.cancel = true;
  };

  const resetFiltersAndCloseAccordion = () => {
    // setToastShown(false);
    dispatch(setAction(ActionTypes.RESET_FILTERS, OriginallocationsList));
    dispatch(setAction(ActionTypes.SET_ACCORDION_INDEX, []));
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Box flex="1">
        <MonthCalendar onDateSelect={handleDateSelect} />
        {isMediatorFirst ? (
          <Flex>
            <Flex direction="column" width="100%">
              <Accordion allowToggle index={accordionIndex} onChange={index => dispatch(setAction(ActionTypes.SET_ACCORDION_INDEX, index))}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {t("calendarTab.mediators")}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <VStack align="start" maxH="40vh" overflowY="scroll">
                      {mediatorsListIsLoading ? (
                        <Spinner size="xl" color="blue.500" />
                      ) : (
                        <Select
                          placeholder={t("calendarTab.selectMediator")}
                          onChange={(e) => handleMediatorChange(parseInt(e.target.value))}
                          value={selectedMediator?.id || ""}
                        >
                          {mediatorsList.map((mediator) => (
                            <option key={mediator.id} value={mediator.id}>
                              {mediator.first_name} {mediator.last_name}
                            </option>
                          ))}
                        </Select>
                      )}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Box display="flex" alignContent="center" justifyContent="center" alignSelf="center" p={0} m={0}>
                <IconButton
                  icon={<FaExchangeAlt />}
                  onClick={handleReverseOrder}
                  aria-label={t("calendarTab.reverseOrder")}
                  bg="none"
                  size={'lg'}
                  mx={1}
                  _hover={{ bg: 'none' }}          // Remove hover background effect
                  _active={{ bg: 'none' }}         // Remove active/click background effect
                  _focus={{ boxShadow: 'none' }}
                  css={{
                    transform: 'rotate(90deg)',  // Rotate the icon 90 degrees
                  }}
                />
              </Box>

              <Accordion allowToggle index={accordionIndex} onChange={index => dispatch(setAction(ActionTypes.SET_ACCORDION_INDEX, index))}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {t("calendarTab.locations")}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <VStack align="start" maxH="40vh" overflowY="scroll">
                      {locationsList.map((location) => (
                        <Checkbox
                          key={location.id}
                          isChecked={selectedLocations.includes(location.id)}
                          onChange={() => handleLocationChange(location.id, selectedLocations.includes(location.id))}
                        >
                          {location.name}
                        </Checkbox>
                      ))}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </Flex>
        ) : (
          <Flex>
            <Flex direction="column" width="100%">
              <Accordion allowToggle index={accordionIndex} onChange={index => dispatch(setAction(ActionTypes.SET_ACCORDION_INDEX, index))}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {t("calendarTab.locations")}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <VStack align="start" maxH="40vh" overflowY="scroll">
                      <Select
                        placeholder={t("calendarTab.selectLocation")}
                        onChange={(e) => handleLocationChange(parseInt(e.target.value), '')}
                        value={selectedLocations[0] || ""}
                      >
                        {locationsList.map((loc) => (
                          <option key={loc.id} value={loc.id}>
                            {loc.name}
                          </option>
                        ))}
                      </Select>
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Box display="flex" alignContent="center" justifyContent="center" alignSelf="center" p={0} m={0}>
                <IconButton
                  icon={<FaExchangeAlt />}
                  onClick={handleReverseOrder}
                  aria-label={t("calendarTab.reverseOrder")}
                  bg="none"
                  size={'lg'}
                  mx={1}
                  _hover={{ bg: 'none' }}          // Remove hover background effect
                  _active={{ bg: 'none' }}         // Remove active/click background effect
                  _focus={{ boxShadow: 'none' }}
                  css={{
                    transform: 'rotate(-90deg)',  // Rotate the icon 90 degrees
                  }}
                />
              </Box>

              <Accordion allowToggle index={accordionIndex} onChange={index => dispatch(setAction(ActionTypes.SET_ACCORDION_INDEX, index))}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {t("calendarTab.mediators")}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <VStack align="start" maxH="40vh" overflowY="scroll">
                      {mediatorsListIsLoading ? (
                        <Spinner size="xl" color="blue.500" />
                      ) : (
                        filteredMedsList.map((med) => (
                          <Checkbox
                            key={med.id}
                            isChecked={selectedMeds.includes(med.id)}
                            onChange={() => handleMediatorChange(med.id, selectedMeds.includes(med.id))}
                          >
                            {med.first_name} {med.last_name}
                          </Checkbox>
                        ))
                      )}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </Flex>
        )}

        <Box width="100%" borderBottom="1px solid" borderBottomColor="gray.300">
          <HStack spacing={4} p={4}>
            {["All", "Availability", "Sessions", "Info", "Mediation"].map((filter) => {
              let translationKey = "";
              if (filter === "All") {
                translationKey = "filters_all";
              } else if (filter === "Availability") {
                translationKey = "filters_availability";
              } else if (filter === "Sessions") {
                translationKey = "filters_sessions";
              } else if (filter === "Info") {
                translationKey = "filters_info";
              } else if (filter === "Mediation") {
                translationKey = "filters_mediation";
              }

              return (
                <Tag
                  key={filter}
                  onClick={() => dispatch(setAction(ActionTypes.SET_SESSION_TYPE_FILTER, filter))}
                  cursor="pointer"
                  colorScheme={sessionTypeFilter === filter ? "blue" : "gray"}
                >
                  <TagLabel>{t(`calendarTab.${translationKey}`)}</TagLabel>
                </Tag>
              );
            })}
          </HStack>
        </Box>

        {sessionTypeFilter === "something_impossible" && (  // later to be changed to Mediation
          <Box width="100%" borderBottom="1px solid" borderBottomColor="gray.300">
            <HStack spacing={4} p={4}>
              {["Occurred", "Scheduled", "Postponed"].map((status) => (
                <Tag
                  key={status}
                  onClick={() => dispatch(setAction(ActionTypes.SET_MEDIATION_STATUS_FILTER, status))}
                  cursor="pointer"
                  colorScheme={mediationStatusFilter === status ? "blue" : "gray"}
                >
                  <TagLabel>{status}</TagLabel>
                </Tag>
              ))}
            </HStack>
          </Box>
        )}

        <Box width="100%" display="flex" justifyContent="flex-end" mt={2} p={2}>
          <Button
            colorScheme="red"
            isDisabled={
              isMediatorFirst
                ? !selectedMediator || selectedLocations.length === 0
                : selectedLocations.length === 0 || selectedMeds.length === 0
            }
            onClick={fetchSessionsAndSlots}
          >
            {t("calendarTab.show_planning")}
          </Button>
          <IconButton
            icon={<CloseIcon />}
            onClick={resetFiltersAndCloseAccordion}
            aria-label={t("calendarTab.resetFilters")}
            bg="none"
            mx={2}
          />
        </Box>
      </Box>

      <div style={{ flex: '3' }}>
        {isLoadingSessions || isLoadingTimeSlots ? (
          <Flex alignItems="center" justifyContent="center" alignSelf="center" mt={12}>
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : error ? (
          <Flex alignItems="center" justifyContent="center" alignSelf="center" mt={12}>
            <Text color="red.500">{t("calendarTab.loadFailed")}</Text>
          </Flex>
        ) : calendarBlock.length > 0 ? (
          <ScheduleComponent
            width="100%"
            height="100%"
            eventSettings={{
              dataSource: filteredCalendarBlock,
            }}
            selectedDate={selectedDate}
            currentView={currentView}
            eventRendered={handleEventRendered}
            popupOpen={handlePopupOpen}
            locale={currentLocale}
            dateFormat="yyyy/MM/dd"
            timeFormat="HH:mm"
            navigating={handleViewChange}
            cellClick={onCellClick}
            firstDayOfWeek={1}
          >
            <ViewsDirective>
              <ViewDirective option="Day" />
              <ViewDirective option="Week" />
              <ViewDirective option="Month" />
              <ViewDirective option="Agenda" />
            </ViewsDirective>
            <Inject services={[Day, Week, Month, Agenda]} />
          </ScheduleComponent>
        ) : (
          <Flex alignItems="center" justifyContent="center" alignSelf="center" mt={12}>
            <ScheduleComponent
              width="100%"
              height="100%"
              selectedDate={new Date()}
              currentView={currentView}
              eventRendered={handleEventRendered}
              popupOpen={handlePopupOpen}
              eventDoubleClick={(args) => args.cancel = true}
              locale={currentLocale}
              dateFormat="yyyy/MM/dd"
              timeFormat="HH:mm"
              firstDayOfWeek={1}
            >
              <ViewsDirective>
                <ViewDirective option="Day" />
                <ViewDirective option="Week" />
                <ViewDirective option="Month" />
                <ViewDirective option="Agenda" />
              </ViewsDirective>
              <Inject services={[Day, Week, Month, Agenda]} />
            </ScheduleComponent>
          </Flex>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessToken: selectAccessToken(state),
});

export default connect(mapStateToProps)(CalendarMain);
