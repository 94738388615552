// MediationManagement Component
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "../../../actions/authActions"; // Ensure you have an action creator for logging out
import {
  Text,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import BrandHeader from "components/menu/BrandHeader";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../actions/collaboratorActions";
import { getData, getLoading, getError } from "../../../selectors/collaboratorSelector";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { selectUser, selectAccessToken, selectAssociation } from "../../../selectors/authSelector";
import rdvService from "../../../services/rdvService";
import AppointmentDetails from "./components/AppointmentDetails";
import { useTranslation } from "react-i18next";

const MediationManagement = ({ user, accessToken, association }) => {
  const { t } = useTranslation();
  const [mediatorOptions, setMediatorOptions] = useState([]);
  const dispatch = useDispatch();
  const collaboratorsData = useSelector(getData);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  useEffect(() => {
    setMediatorOptions(collaboratorsData);
  }, [collaboratorsData]);

  const { appointmentId } = useParams();
  const [notes, setNotes] = useState("You can add notes here. Notes are auto saved and cannot be edited later.");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const history = useHistory();

  const fetchRDVData = async () => {
    console.log('fetchRDVData ');
    try {
      const res = await rdvService.get({ accessToken, pk: appointmentId });
      if (!res.data) {
        throw new Error("Network response was not ok");
      }

      // Normalize the session status based on postpone_count
      const normalizedData = {
        ...res.data,
        rdv_sessions: res.data.rdv_sessions.map(session => {
          if (session.status === "Scheduled" && session.postpone_count > 0) {
            return { ...session, status: "Postponed" };
          }
          return session;
        }),
      };

      setData(normalizedData);
      console.log('done fetching');
    } catch (err) {
      console.log("apointment details page", err);

      if (err.response && err.status === 401) {
        const errorCode = err.response.data?.code;
        // alert("the code");
        if (errorCode === "token_not_valid") {
          console.error("Token expired or invalid, logging out");
          // toast.error(`${t('session_expired_error_msg')}`, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: false,
          //   draggable: true,
          //   theme: "colored",
          // });

          // Log out the user and redirect to the sign-in page
          setTimeout(() => {
            dispatch(logout());
            history.push("/auth/sign-in");
          }, 2000);

        }
      } else {
        console.error("Error fetching appointments", err);
      }

      // dispatch(logout());
      // history.push('/auth/sign-in');
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (!appointmentId) return;
    fetchRDVData();
  }, [appointmentId]);

  return (
    <>
      <BrandHeader />
      <ToastContainer position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {error && <Text>{error.message}</Text>}
      {data && (
        <AppointmentDetails
          data={data}
          collaboratorsData={collaboratorsData}
          accessToken={accessToken}
          onStateChange={() => fetchRDVData()}
          association={association}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
  association: selectAssociation(state),
});

export default connect(mapStateToProps)(MediationManagement);
